import React, { createRef, useState, useEffect } from "react";
import {
  Input,
  InputPrefix,
  TextBox,
  TextArea,
  Checkbox,
} from "@progress/kendo-react-inputs";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { chevronDownIcon, rowFreezeIcon } from "@progress/kendo-svg-icons";
import { Button } from "@progress/kendo-react-buttons";
import { useLocation, useNavigate } from "react-router-dom";
import "../css/Room.scss";
import "../css/EditInfo.scss";
import "../css/Common.scss";
import "../../pages/css/create.scss";
import {
  RadioButton,
  RadioButtonChangeEvent,
} from "@progress/kendo-react-inputs";
import Breadcrumbs from "../components/Breadcrumbs";
import { TimePicker } from "@progress/kendo-react-dateinputs";
import {
  ExternalDropZone,
  Upload,
  UploadFileInfo,
} from "@progress/kendo-react-upload";
import { Grid, GridCellProps, GridColumn } from "@progress/kendo-react-grid";
import axios, { AxiosError } from "axios";
import { Field } from "@progress/kendo-react-form";
import { min } from "moment";
import { Label } from "@progress/kendo-react-labels";
import { Loader } from "@progress/kendo-react-indicators";
import { commonBaseUrl, commonImageUrl } from "../../services/CommonUtil";

interface TaxType {
  ID: number;
  Name: string;
}
interface TableRow {
  tableId: number;
  duration: string;
  durationId: number;
  variant: number;
  variantDetails: string;
  deposit: string;
  price: string;
  tax: string;
  checked: boolean;
  isActive?: boolean;
}

interface ErrorResponseData {
  detail?: string;
}

export const CreateRoom = () => {
  const location = useLocation();
  console.log(location.state, "Datas<<");
  const [gabEnabler, setgapEnabler] = useState(false);
  const options = ["15", "30"];
  const [imgExtension, imgExtensionSet] = useState("");
  const baseUrl = commonBaseUrl();
  const imageURL = commonImageUrl();
  const extensionList = [".jpeg", ".png", ".jpg", ".pdf"];
  // Variables
  const [imageData, setImageData] = useState("");
  const [currentFile, setCurrentFile] = useState<UploadFileInfo>();
  const uploadRefs = createRef<Upload>();

  const hint = (
    <span>Click to select files or Drag and drop files here to upload.</span>
  );
  const note = <span>Only JPEG, JPG, PNG and PDF files are allowed.</span>;

  function onSaveRequest(
    files: UploadFileInfo[],
    options: { formData: FormData; requestOptions: any },
    onProgress: (uid: string, event: ProgressEvent<EventTarget>) => void
  ): Promise<{ uid: string }> {
    imgExtensionSet("");
    const currentFile = files[0] as UploadFileInfo;
    console.log("The current file -->", currentFile.extension);

    imgExtensionSet(currentFile.extension!);
    setCurrentFile(currentFile);
    const uid = currentFile.uid;
    console.log(currentFile, "121");
    return new Promise<{ uid: string }>((resolve, reject) => {
      if (
        currentFile.validationErrors &&
        currentFile.validationErrors.length > 0
      ) {
        reject({ uid: uid });
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.result && typeof reader.result === "string") {
            const base64Result = reader.result.split(",")[1];
            console.log(base64Result, "this< base64");
            setImageData(base64Result);
            resolve({ uid: uid });
          } else {
            reject({ uid: uid });
          }
        };
        reader.onprogress = (data) => {
          onProgress(uid, data);
        };
        reader.onabort = () => {
          reject({ uid: uid });
        };
        reader.onerror = () => {
          reject({ uid: uid });
        };

        reader.readAsDataURL(currentFile.getRawFile!());
      }
    });
  }

  function onRemoveRequest(
    files: UploadFileInfo[],
    options: { formData: FormData; requestOptions: any }
  ): Promise<{ uid: string }> {
    const currentFile = files[0] as UploadFileInfo;
    console.log("The current file  extension is -->", currentFile);
    const uid = currentFile.uid;
    setImageData("");
    return new Promise<{ uid: string }>((resolve) => {
      resolve({ uid: uid });
    });
  }

  const navigate = useNavigate();

  const defaultBookingDuration = "Select Booking Duration";
  const defaultPackages = "Select Packages";
  // console.log("Room Type:", datas);

  const bookingDurationList = ["30", "45", "60", "90", "120"];
  const packagesList = ["Weekly", "Monthly", "Quarterly", "Yearly"];
  const [selectedValue, setSelectedValue] = React.useState("yes");

  const [newBookingDuration, setBookingDuration] = useState(
    defaultBookingDuration
  );
  const [newPackages, setNewPackages] = useState(defaultPackages);
  // const [isOverBookingAllowed, setIsOverBookingAllowed] = useState(false);

  const radioHandleChange = React.useCallback(
    (e: RadioButtonChangeEvent) => {
      // console.log("Checkbox Value->", e);
      // const checkedValue = e.value;
      // setIsOverBookingAllowed(checkedValue === "yes" ? true : false);
      setSelectedValue(e.value);
    },
    [setSelectedValue]
  );

  const cancelRoom = () => {
    navigate("/masters/room");
  };

  const backScreen = () => {
    navigate("/masters/room");
    window.scrollTo(0, 0);
  };

  const breadCrumbsData = [
    {
      id: "1",
      text: "Masters",
      route: "",
    },
    {
      id: "2",
      text: "Space Master",
      route: "/masters/room",
    },
    {
      id: "3",
      text: `${
        location?.state.Id === undefined ? "Create Space" : "Edit Space"
      }`,
      route: "",
    },
  ];

  const [packageDetails, setPackageDetails] = useState([]);
  const [selectedpackage, setSelectedpackage] = useState(null);
  const [errorFormatMessage, errorFormatMessageSet] = useState("");
  const [spaceTypes, setSpaceTypes] = useState([]);
  const [SpaceTypevalue, setSpacetypevalue] = useState();
  const [selectedSpavceTypeId, setSelectedSpaceTypeId] = useState();
  const [taxTypes, setTaxTypes] = useState<TaxType[]>([]);
  const [selectedTaxType, setSelectedTaxType] = useState<TaxType | null>(null);
  useEffect(() => {
    if (check) {
      if (SpaceTypevalue?.["Id"] === undefined) {
        setspaceTypeError("Please select the Space Types");
        // formIsValid = false;
      } else {
        setspaceTypeError("");
      }
    }
  }, [SpaceTypevalue, taxTypes.length > 0]);
  //getpackages
  useEffect(() => {
    spaceTypeAPI();
    packageDetailsApi();
    SpacesApi();
    fetchTaxTypes();
  }, [taxTypes.length > 0, spaceTypes.length > 0, packageDetails.length > 0]);
  const fetchTaxTypes = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}odata/GetAllTaxType?$filter=IsActive%20eq%20true`
      );

      console.log(response.data, "<<resTax");

      setTaxTypes(response.data);
    } catch (error) {
      console.error("Error fetching tax types:", error);
    }
  };

  const packageDetailsApi = async () => {
    try {
      const response = await axios.get(`${baseUrl}odata/PackageDetails`);

      setPackageDetails(response.data.value);
      console.log("Package Details", response.data.value);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const spaceTypeAPI = () => {
    axios
      .get(`${baseUrl}odata/SpaceTypes`)
      .then((response) => {
        setSpaceTypes(response.data.value);
        console.log(response.data.value, "<<<- spaces"); // Adjust based on API response structure
      })
      .catch((error) => console.error("Error fetching space types:", error));
  };

  const createRoom = async (e: any) => {
    console.log(name, Description, Package, noofsetsavail, "<<");
    console.log(e, "<<");

    const Data = tableData.filter((row) => row.checked);
    console.log(Data, "<-checked");

    const sendListData = Data.map((dataItem: any) => {
      console.log(dataItem, "<id");
      return {
        durationId: dataItem.durationId, //Not Null Field
        packagaDetailsId: parseInt(dataItem.variant.Id),
        cancellationPolicyDetails: "", //Null Field
        depositAmount: parseInt(dataItem.deposit),
        // durationName: dataItem.duration,
        rentAmount: parseInt(dataItem.price),
        taxId: parseInt(dataItem.tax.id),
        isActive: true, //Not Null Field
        isDelete: true, //Null Field
        createdBy: 1, //Not Null Field
        createdOn: new Date(), //Not Null Field
        modifiedBy: 1, //Null Field
        modifiedOn: new Date(), //Null Field
      };
    });
    sendDataAPi(sendListData);
    console.log(sendListData, "<-sendData");
  };

  const sendDataAPi = async (sendListData: any) => {
    const createData = {
      spaceName: name, //Not Null Field
      spaceTypeId: selectedSpavceTypeId,
      noOfSeats: parseInt(noofsetsavail),
      gapBetweenNetBooking: false, //Not Null Field
      gapTiming: parseInt(anotherBookinggap), //Null Field
      overBookingAllowed: false, //Not Null Field
      overBookingAllowedPercentage: 0, //Null Field
      sascCode: SASC,
      description: Description,
      isActive: active == 1 ? true : false, //Not Null Field
      isDelete: true, //Null Field
      spaceOpenTime: convertToISOFormat(openTime), //Not Null Field
      spaceCloseTime: convertToISOFormat(closeTime), //Not Null Field
      createdBy: 1, //Not Null Field  -- change login user Id temp 1
      createdOn: new Date(), //Not Null Field
      modifiedBy: 1, //Null Field
      modifiedOn: new Date(),
      spaceDetails: sendListData,
    };
    console.log(createData, "<< api send");
    try {
      const response = await axios.post(
        `${baseUrl}odata/SpaceHeaders`,
        createData
      );
      console.log(response.data.Id, "<-responceData create");
      uploadDocumentFiles(response.data.Id, true);
    } catch (err) {
      const axiosError = err as AxiosError<ErrorResponseData>; // assert error as AxiosError
      if (axiosError.response && axiosError.response.data) {
        // Accessing the detail message from error response
        const errorDetail =
          axiosError.response.data.detail || "An unexpected error occurred.";
        alertSet(errorDetail);
        setLoader(false);
        alertStateSet(true);
        statusDialogSet(true);
      } else {
        alertSet("Network error or unexpected error.");
        setLoader(false);
        alertStateSet(true);
        statusDialogSet(true);
      }
      // statusDialogSet(true);
      // alertStateSet(false);
    }
  };

  const UpdateRoom = async (e: any) => {
    console.log(e, "<<2345678923456789");
    console.log(tableData, "<<2345678923456789");

    const Data = tableData.filter(
      (row) => row.checked || row.isActive !== undefined
    );
    console.log(Data, "rowchecked");

    const sendListData = Data.map((dataItem: any) => {
      // console.log(dataItem.tax.id, "<id");
      // console.log(dataItem.variant.Id, "<< Taxxxxxxxx");
      // console.log(dataItem.tax.id, "<< Taxxxxxxxx");
      console.log(dataItem, "<id2");
      return {
        id: dataItem.tableId === undefined ? 0 : dataItem.tableId,
        durationId: dataItem.durationId, //Not Null Field
        packagaDetailsId: dataItem.variant.Id, //Not Null Field
        cancellationPolicyDetails: "", //Null Field
        depositAmount: parseInt(dataItem.deposit),
        // durationName: dataItem.duration,
        rentAmount: parseInt(dataItem.price),
        // taxPercentage: parseInt(dataItem.tax.id),
        taxId: parseInt(dataItem.tax.id),
        isActive: dataItem.isActive, //Not Null Field
        isDelete: true, //Null Field
        createdBy: 1, //Not Null Field
        createdOn: new Date(), //Not Null Field
        modifiedBy: 1, //Null Field
        modifiedOn: new Date(), //Null Field
        spaceHeaderId: parseInt(updateId),
        // spaceHeader:
      };
    });
    sendUpdateAPi(sendListData);
    console.log(sendListData, "<-sendData");
  };

  const sendUpdateAPi = async (sendListData: any) => {
    console.log(updateId, "<< id");
    console.log("Description -->", Description);
    const updateData = {
      id: parseInt(updateId),
      spaceName: name, //Not Null Field
      spaceTypeId: selectedSpavceTypeId,
      noOfSeats: parseInt(noofsetsavail),
      gapBetweenNetBooking: false, //Not Null Field
      gapTiming: parseInt(anotherBookinggap), //Null Field
      overBookingAllowed: false, //Not Null Field
      overBookingAllowedPercentage: 0, //Null Field
      sascCode: SASC,
      description: Description,
      isActive: active === 1 ? true : false, //Not Null Field
      isDelete: true, //Null Field
      spaceOpenTime: convertToISOFormat(openTime), //Not Null Field
      spaceCloseTime: convertToISOFormat(closeTime), //Not Null Field
      createdBy: 1, //Not Null Field  -- change login user Id temp 1
      createdOn: new Date(), //Not Null Field
      modifiedBy: parseInt(updateId), //Null Field
      modifiedOn: new Date(),
      spaceDetails: sendListData,
    };
    console.log(updateData, "<< api send");
    try {
      const response = await axios.post(
        `${baseUrl}odata/UpdateSpaceHeader`,
        updateData
      );

      console.log(response.data.id, "<-responceData update");
      uploadDocumentFiles(response.data.id, false);
    } catch (err) {
      const axiosError = err as AxiosError<ErrorResponseData>; // assert error as AxiosError
      if (axiosError.response && axiosError.response.data) {
        // Accessing the detail message from error response
        const errorDetail =
          axiosError.response.data.detail || "An unexpected error occurred.";
        alertSet(errorDetail);
        setLoader(false);
        alertStateSet(true);
        statusDialogSet(true);
      } else {
        alertSet("Network error or unexpected error.");
        setLoader(false);
        alertStateSet(true);
        statusDialogSet(true);
      }
    }
  };

  //upload Image
  const uploadDocumentFiles = async (spaceId: String, flow: boolean) => {
    let header = {
      "Content-Type":
        "application/json;odata.metadata=minimal;odata.streaming=true",
      accept: "*/*",
    };
    let uploadData = [
      {
        docUploadGuid: currentFile?.uid,
        docUploadRefType: "Space",
        docUploadRefNo: spaceId,
        docUploadRefSubType: "SpaceHeaders",
        image: [
          {
            value: imageData,
            fileName: currentFile?.name,
            type: currentFile?.extension?.split(".").pop(),
          },
        ],
      },
    ];
    try {
      const response = await axios.post(
        `${baseUrl}odata/JsonUpload`,
        uploadData,
        {
          headers: header,
        }
      );
      if (response.status === 200) {
        if (flow === true) {
          alertSet("Space created successfully");
        } else {
          alertSet("Space updated successfully");
        }
        statusDialogSet(true);
        alertStateSet(false);
        setLoader(false);
        // setVisibleDialog(true);
        // setVisibleDetails(false);
      } else {
        statusDialogSet(true);
        alertStateSet(true);
        setLoader(false);
        // setVisible(true);
        // setAlertContent("Server not rechable. Please try again later!");
        // setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setLoader(false);
      alertStateSet(true);
      statusDialogSet(true);
      alertSet("Error please try again");
      // setLoading(false);
    }
  };

  ///update or create api
  const formatDate = (isoDate: Date) => {
    const date = new Date(isoDate);

    // Format date and time
    const formattedDate = date.toLocaleDateString("en-GB"); // Change the locale if needed
    const formattedTime = date.toLocaleTimeString("en-GB", {
      hour: "2-digit",
      minute: "2-digit",
    });

    return `${formattedDate} ${formattedTime}`;
  };

  const [exampleTax, setExampleTax] = useState();

  interface Errors {
    [index: number]: {
      [field: string]: string;
    };
  }
  const [errors, setErrors] = useState<Errors>({});

  const [errorss, setErrorss] = useState<{
    [key: number]: { [key: string]: string };
  }>({});

  const validateField = (index: number, field: keyof TableRow, value: any) => {
    const newErrors = { ...errors };
    if (!newErrors[index]) {
      newErrors[index] = {};
    }

    if (field === "tax") {
      if (value.id === 0) {
        // Assuming value.id is 0 for the "Select Tax" option
        newErrors[index] = {
          ...newErrors[index],
          tax: "Please select a valid tax.",
        };
      } else {
        newErrors[index] = {
          ...newErrors[index],
          tax: "Please select a valid tax.",
        };
        // newErrors[index][field] = ""; // Clear error if valid
      }
    }

    if (field === "deposit" || field === "price") {
      if (value.trim() === "" || isNaN(Number(value))) {
        newErrors[index][field] = `${
          field.charAt(0).toUpperCase() + field.slice(1)
        } is required and must be a valid number.`;
      } else {
        newErrors[index][field] = ""; // Clear error if valid
      }
    }

    if (field === "tax") {
      console.log(value.name, "wertyhgvbnm,");
      if (value.name === "" || isNaN(Number(value))) {
        newErrors[index][field] = `${
          field.charAt(0).toUpperCase() + field.slice(1)
        } is required and must be a valid number.`;
      } else {
        newErrors[index][field] = ""; // Clear error if valid
      }
    }

    // Update the error state
    setErrors(newErrors);
  };

  const [name, setName] = useState("");
  const [Description, setDescription] = useState("");
  const [Package, setPackage] = useState<string>("");
  const [noofsetsavail, setnoofsetsavail] = useState<string>("");
  const [openTime, setOpenTime] = useState<Date>();
  const [closeTime, setCloseTime] = useState<Date>();
  const [anotherBookinggap, setAnotherBooking] = useState<string>("select");
  const [SASC, setSASC] = useState<string>("");
  const [updateId, setupdateId] = useState<string>("");

  //update Enabler
  const [update, setUpdate] = useState(false);

  const initialData: TableRow[] = [
    // {
    //   duration: "Yearly",
    //   variant: "NA",
    //   deposit: "",
    //   price: "",
    //   tax: "",
    //   checked: false,
    // }
  ];

  // State to hold the table data
  const [initialDatas, setInitialData] = useState([]);
  const [tableData, setTableData] = useState<TableRow[]>(initialData);

  //time
  const minTime = new Date();
  minTime.setHours(8, 0, 0); // 8:00 AM

  const maxTime = new Date();
  maxTime.setHours(20, 0, 59);
  const [document, setDocument] = useState([]);
  const [errorimageMessage, setErrorimegeMessage] = useState<string>("");
  useEffect(() => {
    SpacesApi();
  }, []);

  const SpacesApi = async () => {
    console.log(location.state, "<<12");

    const DataItem = location?.state || {};
    console.log(DataItem.Id, "updateId");
    console.log(DataItem.IsActive, "<><><><><>");
    if (DataItem.IsActive === undefined) {
      setActive(1);
    } else {
      setActive(DataItem.IsActive === true ? 1 : 2);
    }
    if (DataItem.Id !== undefined) {
      try {
        const response = await axios.get(
          `${baseUrl}odata/DocumentUploads?$filter=refno eq ${DataItem.Id} and contains(reftype, 'Space')`
        );
        const dataDoct = response.data.value;

        console.log(dataDoct.length, "<Document");
        const lastItem = dataDoct[dataDoct.length - 1];
        console.log(".", lastItem, "LastItemSelect");
        imgExtensionSet(`.${dataDoct[dataDoct.length - 1]["Type"]}`);

        setDocument(dataDoct);

        console.log(response.data.id, "<-responceData");
      } catch (err) {}
      setUpdate(true);
    }
    setupdateId(DataItem.Id);

    setName(DataItem.SpaceName || "");
    setDescription(DataItem.Description || "");
    setPackage(DataItem.Description || "");
    setnoofsetsavail(DataItem.NoOfSeats || "");
    // console.log("SelectedSpaceType ID->", spaceTypes);

    const matchingSpaceType = spaceTypes.find(
      (dds: any) => dds.Id === DataItem.SpaceTypeId
    );

    // console.log("SelectedSpaceType->", matchingSpaceType);
    setSpacetypevalue(matchingSpaceType);
    setSelectedSpaceTypeId(matchingSpaceType?.["Id"]);

    console.log(DataItem.SpaceOpenTime, "<-");
    console.log(new Date(), "<-");
    setOpenTime(
      DataItem.SpaceOpenTime !== undefined
        ? new Date(DataItem.SpaceOpenTime)
        : undefined
    );
    setCloseTime(
      DataItem.SpaceOpenTime !== undefined
        ? new Date(DataItem.SpaceCloseTime)
        : undefined
    );
    setAnotherBooking(DataItem.GapTiming || "");
    setSASC(DataItem.SASCCode || "");

    console.log(DataItem.SpaceOpenTime || "", "time to");
    const DataList = (await DataItem.SpaceDetails) || [];
    console.log(DataList, "1234567");

    // if (DataList.DurationName !== undefined) {
    try {
      const response = await axios.get(
        `${baseUrl}odata/GetAllTaxType?$filter=IsActive%20eq%20true`
      );

      console.log(response.data, "<<resTax");
      const TaxDataTempList = response.data;

      try {
        const response = await axios.get(`${baseUrl}odata/Durations`);
        console.log(response.data.value, "<");

        const datas = response.data.value || [];
        console.log(datas, "123458");

        const tempData = datas.map((dataItem: any) => {
          console.log(dataItem, "PackageDatas 1");
          const matchingDuration = DataList.find(
            (dds: any) => dds.DurationId === dataItem.Id
          );
          const match = DataList.find((dsdd: any) => {
            taxTypes.find((taxxs: any) => {
              console.log(dsdd.TaxPercentage === taxxs.id, "123458s");
              console.log(taxxs.id, "123458s");
            });
            // console.log(taxTypes?.ID === DataList.TaxPercentage, "taskId")
            // dsdd.Id === taxTypes?.ID
          });

          const isActive = DataList.find((ds: any) => {
            // console.log("<><>is Active", ds.IsActive, ds.DurationId === dataItem.Id)
            return ds.DurationId === dataItem.Id;
          });

          const enabler = DataList.find((ds: any) => {
            return ds.DurationId === dataItem.Id && ds.DurationId === 1;
          });
          if (enabler) {
            console.log("<<misouss check");
            setgapEnabler(true);
          }

          console.log(enabler, "thais");

          console.log(isActive, "<><>is Active23");

          const matchingVarient = packageDetails.find((packageData) =>
            DataList.find((dataLists: any) => {
              console.log(
                dataLists.DurationId,
                packageData?.["Id"],
                dataLists.DurationId,
                dataItem.Id,
                "121111222111"
              );
              return (
                dataLists.PackagaDetailsId === packageData?.["Id"] &&
                dataLists.DurationId === dataItem.Id
              );
            })
          );
          console.log(matchingVarient, "Mapping varient ");
          // const matchingVarient = packageDetails.find((packageData: any) => {
          //   console.log(packageData?.["Id"], "121111222111"); // For debugging
          //   return packageData?.["Id"] === dataItem.Id; // Match based on Id only
          // });

          console.log(dataItem, "<--Data Item from api");
          console.log(DataList, "Hkkl");
          console.log(TaxDataTempList, "Hkklsdsfs");

          const TaxData = TaxDataTempList.find((taxIds: any) =>
            DataList.find(
              (datalistss: any) =>
                taxIds.id === parseInt(datalistss.TaxId) &&
                datalistss.DurationId === dataItem.Id
            )
          );

          return {
            tableId: matchingDuration ? matchingDuration.Id : undefined,
            duration: dataItem.DurationType,
            variant: matchingVarient ? matchingVarient : "",
            variantDetails:
              dataItem.HasVarient === 1 ? `${dataItem.Varient1}` : "NA",
            deposit: matchingDuration ? matchingDuration.DepositAmount : "",
            price: matchingDuration ? matchingDuration.RentAmount : "",
            tax: TaxData ? TaxData : "",

            durationId: dataItem.Id,
            DurationIdhiden: dataItem.DurationTypeId,
            checked:
              matchingDuration && isActive.IsActive !== false ? true : false,
            isActive: isActive !== undefined ? isActive.IsActive : undefined,
          };
        });
        console.log(tempData, "matchingDuration");

        setTableData(tempData); // Set the mapped data to the table
      } catch (err) {
        console.error("Error fetching durations:", err);
      }
    } catch (err) {
      console.error("Error fetching durations:", err);
    }
    // } else {
    //   console.log("Hello");
    // }
  };
  // Time limits
  const TIME_LIMIT_START = new Date();
  TIME_LIMIT_START.setHours(8, 0, 0);

  const TIME_LIMIT_START_END = new Date();
  TIME_LIMIT_START_END.setHours(9, 0, 0);

  const TIME_LIMIT_END = new Date();
  TIME_LIMIT_END.setHours(21, 0, 0);

  const getHoursOnly = (dateString: any) => {
    const dateObject = new Date(dateString);
    const hours = dateObject.getHours();
    return hours.toString().padStart(2, "0");
  };

  let startTimeValue: any;

  // CSS styling for the components
  const styles = `
.no-wheel input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Hide spinner controls */
  pointer-events: none;
  user-select: none;
}
 
.no-wheel input::-webkit-inner-spin-button,
.no-wheel input::-webkit-calendar-picker-indicator {
  display: none;
}
 
.no-wheel input::-moz-inner-spin-button {
  display: none;
}`;

  useEffect(() => {
    const Datafgs = tableData.filter(
      (Valus: any) => Valus.DurationIdhiden === 1 && Valus.checked === true
    );
    console.log(Datafgs, "sdfghjkjhgfdsdfghjkl");
    if (Datafgs.length === 0) {
      setgapEnabler(false);
    } else {
      setgapEnabler(true);
    }
  }, [tableData]);
  const handleValueChange = (
    index: number,
    field: keyof TableRow,
    value: string | boolean
  ) => {
    if (value.toString().length <= 7) {
      validateField(index, field, value);
      console.log(value, field, index, "<--123");

      // Update the data if no errors

      const updatedData = tableData.map((item: TableRow, idx: number) => {
        if (idx === index) {
          return { ...item, [field]: value };
        }
        return item;
      });

      console.log(updatedData, "<<DataUpdated");
      setTableData(updatedData);
    }
  };
  const handleChange = (
    index: number,
    field: keyof TableRow,
    value: string | boolean
  ) => {
    validateField(index, field, value);
    console.log(value, field, index, "<--123");

    // Update the data if no errors

    const updatedData = tableData.map((item: TableRow, idx: number) => {
      if (idx === index) {
        return { ...item, [field]: value };
      }
      return item;
    });

    console.log(updatedData, "<<DataUpdated");
    setTableData(updatedData);
  };
  const handleCheckboxChange = (index: number, tableRow: any) => {
    console.log(tableRow, "<<misous");

    // if (!tableRow.checked === true && tableRow.durationId === 1) {
    //   console.log("<<misouss check");
    //   setgapEnabler(true);
    // } else if (!tableRow.checked === false && tableRow.durationId === 1) {
    //   console.log("<<misouss uncheck");
    //   setgapEnabler(false);
    // }

    setTableData((prevData) =>
      prevData.map((row, idx) => {
        // if()

        const hourlyCheck = idx === index ? row : "";
        console.log(
          !row.checked === true,
          "..",
          !row.checked,
          row.durationId === 1,
          "..",
          row.durationId,
          "mious"
        );
        console.log(hourlyCheck, "mious");

        console.log(idx === index ? row : "", "miyonus");
        return idx === index
          ? {
              ...row,
              checked: !row.checked,
              isActive:
                row.checked === false
                  ? true
                  : row.tableId === undefined && row.checked !== true
                  ? undefined
                  : false,
            }
          : row;
      })
    );
    console.log(tableData, "12345678934567845678");

    if (tableData[index].checked) {
      setErrorss((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[index];
        return newErrors;
      });
    }
  };

  const [active, setActive] = useState(0);
  const [activeError, setActiveError] = useState("");
  const [errorsTime, setErrorsTime] = useState({
    openTimeError: "",
    closeTimeError: "",
    timeComparisonError: "",
  });

  const [check, checkSet] = useState(false);
  const handleSubmit = (e: any) => {
    checkSet(true);
    let formIsValid = true;
    console.log("The img Extension was -->", imgExtension);
    const updatedErrors: { [key: number]: { [key: string]: string } } = {};
    const extMatch = extensionList.some((char) => imgExtension.includes(char));
    console.log("The match condition was -->", extMatch);

    tableData.forEach((row, index) => {
      if (row.checked) {
        if (!row.deposit || isNaN(Number(row.deposit))) {
          updatedErrors[index] = {
            ...updatedErrors[index],
            deposit: "Deposit is required",
          };
          formIsValid = false;
        } else if (Number(row.deposit) < 100) {
          updatedErrors[index] = {
            ...updatedErrors[index],
            deposit: "Deposit must be greater than 100.",
          };
          formIsValid = false;
        } else {
          // Clear the error if price is valid
          updatedErrors[index] = { ...updatedErrors[index], deposit: "" };
        }

        if (!row.price || isNaN(Number(row.price))) {
          updatedErrors[index] = {
            ...updatedErrors[index],
            price: "Price is required.",
          };
          formIsValid = false;
        } else if (Number(row.price) < 100) {
          updatedErrors[index] = {
            ...updatedErrors[index],
            price: "Price must be greater than 100.",
          };
          formIsValid = false;
        } else {
          // Clear the error if price is valid
          updatedErrors[index] = { ...updatedErrors[index], price: "" };
        }
        console.log(row, "<< Tax details");

        if (!row.tax) {
          updatedErrors[index] = {
            ...updatedErrors[index],
            tax: "Please select",
          };
          formIsValid = false;
        } else {
          updatedErrors[index] = { ...updatedErrors[index], tax: "" };
        }

        if (!row.variant) {
          updatedErrors[index] = {
            ...updatedErrors[index],
            variant: "Please select",
          };
          formIsValid = false;
        } else {
          updatedErrors[index] = { ...updatedErrors[index], variant: "" };
        }
        // console.log(row.tax, "hellos")
        // if (!row.tax ) {
        //   updatedErrors[index] = {
        //     ...updatedErrors[index],
        //     tax: "Tax is required.",
        //   };
        //   formIsValid = false;
        // } else if (Number(row.tax) > 100) {
        //   updatedErrors[index] = {
        //     ...updatedErrors[index],
        //     tax: "Tax must be less than 100.",
        //   };
        //   formIsValid = false;
        // } else {
        //   // Clear the error if price is valid
        //   updatedErrors[index] = { ...updatedErrors[index], tax: "" };
        // }
      }
    });

    if (imageData === "" && update === false) {
      console.log(imageData, "44");
      setErrorimegeMessage("Please Select image");
      formIsValid = false;
    } else {
      setErrorimegeMessage("");
      if (!extMatch) {
        console.log("The error image message was -->", errorimageMessage);
        console.log("The condition was true");
        errorFormatMessageSet("The Image Format was not allowed");
        formIsValid = false;
      } else {
        errorFormatMessageSet("");
      }
    }
    if (name.trim() === "") {
      setErrorName("Space name is required");
      formIsValid = false;
    } else {
      setErrorName("");
    }
    if (Description.trim() === "") {
      setErrordetails("Space details is required");
      formIsValid = false;
    } else {
      setErrordetails("");
    }
    if (noofsetsavail === "" || noofsetsavail === "0") {
      setErrorNoofAvail("No. of Seats available 1-20 required");
      formIsValid = false;
    } else {
      setErrorNoofAvail("");
    }

    if (active === undefined) {
      setActiveError("select active or inActive");
      formIsValid = false;
    } else {
      setActiveError("");
    }
    // if (isNaN(Number(anotherBookinggap))) {
    //   setErrorgap("Please enter a valid number.");
    //   formIsValid = false;
    // } else if (Number(anotherBookinggap) < 0 || Number(anotherBookinggap) > 100) {
    //   setErrorgap("Value must be between 1 and 100.");
    //   formIsValid = false;
    // } else {
    //   setErrorgap(""); // Clear the error if valid
    // }
    // if(anotherBookinggap === "select"){
    //   setErrorgap("select gap in min");
    //   formIsValid = false;
    // }else{
    //   setErrorgap("")
    // }

    if (gabEnabler === true) {
      if (
        Number(anotherBookinggap) < 0 ||
        Number(anotherBookinggap) > 50 ||
        !/^\d+$/.test(anotherBookinggap)
      ) {
        // setErrorgap("Value must be between 15 and 30.");
        setErrorgap("select gap between another booking");
        formIsValid = false;
      } else {
        setErrorgap(""); // Clear error if value is within range
      }
    }

    const alphanumericPattern = /^[a-zA-Z0-9]*$/;

    if (!alphanumericPattern.test(SASC)) {
      setErrorsasc("Only alphanumeric characters are allowed.");
      formIsValid = false;
    } else {
      setErrorsasc("");
    }
    const Datas = tableData.filter((row) => row.checked);
    console.log(Datas.length, "<< table Length");
    if (Datas.length === 0) {
      setErrorTableNotChecked("Please select Duration's");
      formIsValid = false;
    } else {
      setErrorTableNotChecked("");
    }
    console.log(SpaceTypevalue, "spaceType");
    if (SpaceTypevalue?.["Id"] === undefined || SpaceTypevalue?.["Id"] === 0) {
      setspaceTypeError("Please select the Space Types");
      formIsValid = false;
    } else {
      setspaceTypeError("");
    }
    let newErrors = {
      openTimeError: "",
      closeTimeError: "",
      timeComparisonError: "",
    };

    if (!openTime) {
      newErrors.openTimeError = "Please select an open time.";
      formIsValid = false;
    } else {
      newErrors.openTimeError = "";
    }

    if (!closeTime) {
      newErrors.closeTimeError = "Please select a close time.";
      formIsValid = false;
    } else {
      newErrors.closeTimeError = "";
    }

    // if (openTime && closeTime && openTime <= closeTime) {
    //   newErrors.timeComparisonError = "Close time must be after open time.";
    //   formIsValid = false;
    // } else {
    //   newErrors.timeComparisonError = "";
    // }
    setErrorsTime(newErrors);
    setErrorss(updatedErrors);
    // UpdateRoom(e);
    if (formIsValid) {
      console.log("hellso");
      setLoader(true);
      if (update === false) {
        createRoom(e);
      } else {
        console.log("updatees");
        UpdateRoom(e);
      }

      console.log("Form is valid, submit data:", tableData);
    } else {
      console.log("Form has errors, cannot submit.");
    }
  };
  const [loader, setLoader] = useState(false);
  const [errorneme, setErrorName] = useState("");
  const [errordetails, setErrordetails] = useState("");
  const [errorgap, setErrorgap] = useState<string>("");
  const [errorsasc, setErrorsasc] = useState<string>("");
  const [errorTableNotChecked, setErrorTableNotChecked] = useState<string>("");
  const [errorNoofAvail, setErrorNoofAvail] = useState("");
  const [spaceTypeError, setspaceTypeError] = useState("");

  const convertToISOFormat = (inputDate: any): string => {
    console.log("Input Date->", inputDate);
    // Parse the input date string to a Date object
    const dateObj = new Date(inputDate);
    console.log("Date->", dateObj);

    // Extract the individual date components
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so add 1
    const day = String(dateObj.getDate()).padStart(2, "0");
    const hours = String(dateObj.getHours()).padStart(2, "0");
    const minutes = String(dateObj.getMinutes()).padStart(2, "0");
    const seconds = String(dateObj.getSeconds()).padStart(2, "0");

    // Get the timezone offset in hours and minutes
    const timezoneOffset = -dateObj.getTimezoneOffset(); // In minutes
    const offsetHours = String(Math.floor(timezoneOffset / 60)).padStart(
      2,
      "0"
    );
    const offsetMinutes = String(Math.abs(timezoneOffset % 60)).padStart(
      2,
      "0"
    );

    // Construct the ISO 8601 formatted string with timezone
    const isoFormattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}+${offsetHours}:${offsetMinutes}`;

    console.log("Formatted Date->", isoFormattedDate);
    return isoFormattedDate;
  };

  const [statusDialog, statusDialogSet] = useState(false);
  const [alert, alertSet] = useState("");
  const [alertState, alertStateSet] = useState(false);

  const navigateBack = () => {
    if (alertState === false) {
      navigate("/masters/room/");
    }
    statusDialogSet(false);
  };

  return (
    <div>
      {loader === true ? (
        <div className="loader">
          <Loader size="large" type={"converging-spinner"} />
        </div>
      ) : (
        <div className="overall-view ">
          <div className="overall-view">
            <Breadcrumbs breadCrumbData={breadCrumbsData} />
            <hr className="hr-border" />
          </div>
          <div className="common-margin-cresate">
            {/* <form onSubmit={createRoom}> */}
            <div className="form-row-create">
              <div className="form-group-new">
                <Label>
                  Name of the Space <span style={{ color: "red" }}>*</span>
                </Label>
                <Input
                  className="book-dropdown-new"
                  name="roomtype"
                  value={name}
                  placeholder="Enter the space name"
                  maxLength={25}
                  onChange={(e) => {
                    if (e.value.trim() === "") {
                      setErrorName("Space name is required");
                    } else {
                      setErrorName("");
                    }
                    setName(e.value);
                  }}
                />
                {errorneme && <span style={{ color: "red" }}>{errorneme}</span>}
              </div>
              <div className="form-group">
                <Label>
                  Description of the space{" "}
                  <span style={{ color: "red" }}>*</span>
                </Label>
                <TextArea
                  className="book-input-view"
                  name="Notes"
                  rows={3}
                  maxLength={500}
                  placeholder="Enter description"
                  value={Description}
                  onChange={(e) => {
                    if (e.value.trim() === "") {
                      setErrordetails("Space details is required");
                    } else {
                      setErrordetails("");
                    }
                    setDescription(e.value);
                    console.log(e.value);
                  }}
                  // onBlur={handleBlurNotes}
                />{" "}
                {errordetails && (
                  <span style={{ color: "red" }}>{errordetails}</span>
                )}
              </div>
            </div>
            <hr className="hr-border" />

            <div className="form-row-create">
              <div className="form-group">
                <Label>
                  Space type <span style={{ color: "red" }}>*</span>
                </Label>
                <DropDownList
                  svgIcon={chevronDownIcon}
                  style={{
                    backgroundColor: "white",
                  }}
                  className="book-dropdown-new"
                  defaultItem={{
                    Id: 0,
                    Name: "Select",
                    ShortName: "Single Seater",
                    Description:
                      "A dedicated, ergonomic workspace tailored for individuals seeking focus and productivity in a shared environment. Ideal for freelancers and remote workers who need a personal desk.",
                    IsActive: true,
                    IsDelete: false,
                    CreatedBy: 1,
                    CreatedOn: "2024-09-26T13:15:10.6371356+05:30",
                    ModifiedBy: 1,
                    ModifiedOn: "2024-09-26T13:12:25.219+05:30",
                  }}
                  data={spaceTypes}
                  textField="Name"
                  value={SpaceTypevalue}
                  onChange={(e) => {
                    setSelectedSpaceTypeId(e.target.value.Id);
                    setSpacetypevalue(e.target.value);
                  }}
                />
                {spaceTypeError && (
                  <span style={{ color: "red" }}>{spaceTypeError}</span>
                )}
              </div>
              <div className="form-group">
                <Label>
                  No. of Seats available <span style={{ color: "red" }}>*</span>
                </Label>
                <Input
                  type="number"
                  name="percentageBooking"
                  className="book-dropdown-new"
                  value={noofsetsavail === "0" ? "" : noofsetsavail}
                  min={1}
                  onChange={(e) => {
                    const numericValue = Number(e.value);
                    if (e.value === "") {
                      setErrorNoofAvail("No. of Seats available 0-20 required");
                    } else if (
                      numericValue < 0 ||
                      numericValue > 20 ||
                      isNaN(numericValue)
                    ) {
                      setErrorNoofAvail(
                        "Please enter a number between 0 and 20"
                      );
                    } else {
                      setErrorNoofAvail("");
                    }
                    if (e.value.includes("-")) {
                      return;
                    }
                    const regex = /^[0-9]*$/;

                    if (
                      regex.test(e.value) &&
                      Number(e.value) >= 0 &&
                      Number(e.value) <= 20
                    ) {
                      setErrorNoofAvail(""); // Clear error if input is valid
                      setnoofsetsavail(e.value); // Set the value if it's a valid number
                    } else {
                      setErrorNoofAvail(
                        "Please enter a valid number between 0 and 20"
                      );
                    }
                    // setnoofsetsavail(e.value);
                  }}
                  placeholder="Enter 0-20"
                  style={{ width: "80%", height: "42px" }}
                />
                {errorNoofAvail && (
                  <span style={{ color: "red" }}>{errorNoofAvail}</span>
                )}
              </div>

              <div className="form-group">
                <Label>Space Status</Label>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <RadioButton
                    name="group2"
                    value={1}
                    checked={active === 1}
                    label="Active"
                    onChange={(e) => {
                      console.log(e.value, "sdfghjklkjhgfdsxdcfghjkijuhygtfr");
                      setActive(e.value);
                    }}
                  />
                  <div style={{ width: "30px" }}></div>
                  <RadioButton
                    name="group3"
                    value={2}
                    checked={active === 2}
                    label="inActive"
                    onChange={(e) => {
                      console.log(e.value, "sdfghjklkjhgfdsxdcfghjkijuhygtfr");
                      setActive(e.value);
                    }}
                  />
                </div>

                {activeError && <p style={{ color: "red" }}>{activeError}</p>}

                {/* <Label>package applicable</Label>
            <DropDownList
              svgIcon={chevronDownIcon}
              className="book-dropdown-new "
              data={packages}
              // name="package"
              // value={""}
              // required={check}
              dataItemKey="Id"
              textField="PackageName"
              defaultItem={{ Id: null, PackageName: "Select Package Type" }}
              // validationMessage={packageValidationMessage}
              // onChange={handleChangedropdownpackgae}
            /> */}
              </div>
            </div>

            <hr className="hr-border" />

            <div className="form-row-create">
              <style>{styles}</style>
              <div className="form-group no-wheel">
                <Label>
                  Space Open Time <span style={{ color: "red" }}>*</span>
                </Label>
                <div className="createRoomTimePicker">
                  <TimePicker
                    value={openTime}
                    className="book-dropdown-new border_error_Change"
                    // format= {{hour: "2-digit",minute: "2-digit",}}
                    placeholder={"Hour / Min"}
                    // min={minTime}

                    // className=''
                    min={minTime}
                    max={new Date(maxTime.getTime() - 60 * 60 * 1000)}
                    onChange={(e) => {
                      setCloseTime(maxTime);
                      if (e.nativeEvent.type === "input") {
                        e.syntheticEvent.preventDefault();
                        console.log(e.value, "<tsfdytfsgsdhgsd");
                        if (e.value === null) {
                          setOpenTime(minTime);
                        }
                      } else {
                        if (
                          e.value !== null &&
                          e.value!.getHours() >= minTime.getHours() &&
                          e.value! < maxTime
                        ) {
                          setOpenTime(new Date(e.value!.setSeconds(0)));

                          console.log(e.value, "<backspaces");
                          setErrors((prev) => ({ ...prev, openTimeError: "" }));
                        }
                      }
                      console.log(minTime, "<backspacess");
                      console.log(e.value, "<backspacess e.value");
                    }}
                  />
                </div>
                {errorsTime.openTimeError && (
                  <p style={{ color: "red" }}>{errorsTime.openTimeError}</p>
                )}
              </div>
              <style>{styles}</style>
              <div className="form-group no-wheel">
                <Label>
                  Space Close Time <span style={{ color: "red" }}>*</span>
                </Label>
                <div className="createRoomTimePicker">
                  <TimePicker
                    required={false}
                    disabled={openTime !== undefined ? false : true}
                    className="book-dropdown-new border_error_Change"
                    value={closeTime}
                    // format={{ hour: "2-digit", minute: "2-digit" }}
                    min={
                      openTime
                        ? new Date(
                            openTime.setSeconds(0o0) + +60 * 60 * 1000 + 1000
                          )
                        : undefined
                    }
                    max={new Date(maxTime.setHours(20, 0, 59))}
                    placeholder={"Hour / Min"}
                    onChange={(e) => {
                      let newErrorss = {
                        openTimeError: "",
                        closeTimeError: "",
                        timeComparisonError: "",
                      };
                      // console.log(e.value! > openTime!, "<backspace2");
                      // console.log(e.value !== null, "<backspace2");
                      // console.log(e.value! < maxTime, "<backspace2");
                      // console.log(
                      //   e.value!.getHours(),
                      //   openTime!.getHours(),
                      //   "<backspace222"
                      // );
                      // console.log(
                      //   e.value!.getHours() > openTime!.getHours(),
                      //   "<backspace222"
                      // );

                      if (e.nativeEvent.type === "input") {
                        e.syntheticEvent.preventDefault();

                        if (e.value === null) {
                          setCloseTime(maxTime);
                        }
                      } else {
                        if (
                          e.value!.getHours() > openTime!.getHours()! &&
                          e.value !== null &&
                          e.value! < maxTime
                        ) {
                          setCloseTime(e.value!);
                          setErrorsTime(newErrorss);
                          setErrors((prev) => ({
                            ...prev,
                            closeTimeError: "",
                          }));
                        }
                      }
                    }}
                  />
                </div>
                {errorsTime.timeComparisonError && (
                  <p style={{ color: "red" }}>
                    {errorsTime.timeComparisonError}
                  </p>
                )}
              </div>
              {gabEnabler === true ? (
                <div className="form-group">
                  <Label>
                    Is there any time gap between another booking? (in mins)
                    <span style={{ color: "red" }}>*</span>
                  </Label>
                  <style>{styles}</style>
                  <DropDownList
                    data={options}
                    className="book-dropdown-new"
                    value={anotherBookinggap || "select"}
                    onChange={(e) => {
                      if (e.value === "select") {
                        setErrorgap("select gap in min");
                      } else {
                        setErrorgap("");
                      }

                      setAnotherBooking(e.value);
                    }}
                  />
                  {/* {gapError && <div style={{ color: 'red', marginTop: '5px' }}>{gapError}</div>} */}
                  {/* <Input
              name="percentageBooking"
              className="book-dropdown-new"
              value={anotherBookinggap}
              type="number"
              min={0}
              maxLength={3}
              max={100}
               
              onChange={(e) => {
                console.log(">4567>", e.nativeEvent.data)

                let value = e.value;

                // Ensure the input value is a number
                // if ( ) {
                //   setErrorgap("Please enter a valid number."); // Error for non-numeric input
                //   return;
                // }
            
                if (value === "" || value.includes("-")) {
                  setErrorgap(""); 
                  setAnotherBooking(""); 
                } else {
                  const numericValue = Number(value);
                  
                  if (numericValue < 15 || numericValue > 30 || !/^\d+$/.test(value) ) {
                    setErrorgap("Value must be between 15 and 30.");
                  } else {
                    setErrorgap(""); // Clear error if value is within range
                  }
              
               
            
                  
                  // Limit the input to 3 digits
                  if (value.length > 2) {
                    value = value.slice(0, 2);
                  }
                  if( e.nativeEvent.data !== undefined){
                  setAnotherBooking(value); // Update the state with the valid input
                  }}
                // const value = e.value;
                // // if (value.length > 3) {
                // //   value = value.slice(0, 3); // Truncate to the first 3 digits
                // // }
                // // Validation logic (e.g., check if it's a number between 0 and 100)
                // if (Number(anotherBookinggap) <= 0 && Number(anotherBookinggap) >= 100 || value === "0") {
                //   setErrorgap("Value must be between 1 and 100.");
                //   setAnotherBooking(value.slice(0, 3));
                // setAnotherBooking(e.value.slice(0, 3));
                
                // } else {
                //   setErrorgap(""); // Clear the error if valid
                // }
            

                
              }}
              placeholder="Enter the Time Gap in mins"
              style={{ width: "80%", height: "42px" }}
            /> */}
                  {errorgap && <div style={{ color: "red" }}>{errorgap}</div>}
                </div>
              ) : (
                <div></div>
              )}

              <div className="form-group"></div>
            </div>
            {/* <div className="form-row-create">
          <div className="form-group">
            <Label>SASC <span style={{color:"red"}}>*</span></Label>
            <Input
              className="book-dropdown-new"
              name="seats"
              value={SASC}
              maxLength={12}
              placeholder="Enter"
              onChange={(e) => {
                const value = e.value.toUpperCase();
                // Alphanumeric validation using regex
                const alphanumericPattern = /^[a-zA-Z0-9]*$/;

                if (!alphanumericPattern.test(value)) {
                  setErrorsasc("Only alphanumeric characters are allowed.");
                } else {
                  setErrorsasc(""); // Clear the error if valid
                }

                setSASC(value);
              }}
            />
            {errorsasc && <div style={{ color: "red" }}>{errorsasc}</div>}
          </div>
          <div className="form-group"></div>
          <div className="form-group"></div>
        </div> */}

            <hr className="hr-border" />
            <div>
              <Label>
                Space Photo <span style={{ color: "red" }}>*</span>
              </Label>
              <ExternalDropZone
                className="field-space border-file-upload"
                uploadRef={uploadRefs}
                customHint={hint}
                customNote={note}
              />
              <div className="center-view" style={{ paddingTop: "10px" }}>
                <Upload
                  ref={uploadRefs}
                  restrictions={{
                    maxFileSize: 2000000,
                    // allowedExtensions: [".jpeg", ".png", ".jpg", ".pdf"],
                  }}
                  batch={false}
                  multiple={false}
                  defaultFiles={[]}
                  withCredentials={false}
                  saveUrl={onSaveRequest}
                  removeUrl={onRemoveRequest}
                />
                {errorimageMessage && (
                  <div style={{ color: "red" }}>{errorimageMessage}</div>
                )}
                {errorFormatMessage && (
                  <div style={{ color: "red" }}>{errorFormatMessage}</div>
                )}
              </div>
            </div>
            <hr className="hr-border" />
            {update === false ? (
              <div></div>
            ) : (
              <div>
                <div className="form-group">
                  <Label>Space Image</Label>
                </div>
                {document[0] !== undefined ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <img
                      width="30%"
                      src={`${imageURL}/${
                        document[document.length - 1]["ImagePath"]
                      }`}
                      alt="new"
                    />
                    <Label>
                      {document[document.length - 1]["Name"]}.
                      {document[document.length - 1]["Type"]}
                    </Label>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            )}
            <hr className="hr-border" />
            {errorTableNotChecked && (
              <span style={{ color: "red" }}>{errorTableNotChecked}</span>
            )}
            {tableData.length !== 0 ? (
              <table cellPadding="10">
                <thead>
                  <tr>
                    <th>Select</th>
                    <th>Duration</th>
                    <th>Variant</th>
                    <th style={{ width: "20%" }}>
                      Package <span style={{ color: "red" }}>*</span>
                    </th>
                    <th>
                      Upfront Deposit (₹){" "}
                      <span style={{ color: "red" }}>*</span>
                    </th>
                    <th>
                      Price (₹) <span style={{ color: "red" }}>*</span>
                    </th>
                    <th>
                      Tax (GST in %) <span style={{ color: "red" }}>*</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((row, index) => (
                    <tr key={index}>
                      <td>
                        <input
                          type="checkbox"
                          checked={row.checked}
                          onChange={() => handleCheckboxChange(index, row)}
                        />
                      </td>
                      <td>{row.duration}</td>
                      <td>{row.variantDetails}</td>
                      <td>
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <DropDownList
                            svgIcon={chevronDownIcon}
                            style={{
                              backgroundColor: "white",
                            }}
                            className="book-dropdown-new"
                            disabled={!row.checked}
                            data={packageDetails}
                            textField="PackageName"
                            value={
                              row.variant || {
                                Id: 0,
                                PackageName: "Select",
                                CreatedBy: 1,
                              }
                            }
                            onChange={(e) => {
                              console.log(e.value, "Targetvalue");

                              handleChange(index, "variant", e.target.value);
                            }}
                          />
                          {errorss[index]?.variant && (
                            <span style={{ color: "red" }}>
                              {errorss[index].variant}
                            </span>
                          )}
                        </div>
                      </td>
                      <td>
                        <Input
                          type="number"
                          maxLength={7}
                          disabled={!row.checked}
                          value={row.deposit}
                          placeholder="Enter value"
                          onChange={(e) => {
                            handleValueChange(index, "deposit", e.value);
                          }}
                        />
                        {errorss[index]?.deposit && (
                          <span style={{ color: "red" }}>
                            {errorss[index].deposit}
                          </span>
                        )}
                      </td>
                      <td>
                        <Input
                          type="number"
                          maxLength={7}
                          disabled={!row.checked}
                          placeholder="Enter value"
                          value={row.price}
                          onChange={(e) => {
                            handleValueChange(index, "price", e.value);
                          }}
                        />
                        {errorss[index]?.price && (
                          <span style={{ color: "red" }}>
                            {errorss[index].price}
                          </span>
                        )}
                      </td>
                      <td>
                        <DropDownList
                          data={taxTypes}
                          textField="name"
                          disabled={!row.checked}
                          value={row.tax || { name: "Select Tax", id: 0 }}
                          // value={taxTypes[4]}
                          onChange={(e) => {
                            console.log(e.value, "<< TaxType");
                            setSelectedTaxType(e.value);
                            handleChange(index, "tax", e.value);
                          }}
                          // defaultItem={{ name: "Select Tax", id: 0 }} // Default item
                        />
                        {/* <Input
                      type="number"
                       placeholder="Enter value"
                      disabled={!row.checked}
                      value={row.tax}
                      onChange={(e) => {
                        handleChange(index, "tax", e.value);
                      }}
                    /> */}
                        {errorss[index]?.tax && (
                          <span style={{ color: "red" }}>
                            {errorss[index].tax}
                          </span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div>
                <span style={{ color: "red" }}>
                  No Durations found Please create Duration and proceed with
                  Space creation
                </span>
              </div>
            )}

            {/* <div className="center-view" style={{ paddingTop: "50px" }}> */}
            {/* <Grid data={data.rows}>
          <GridColumn field="selected" title="" width="50px" cell={CustomCheckboxCell} />
            <GridColumn field="duration" title="Duration" />
            <GridColumn field="variant" title="Variant" />
            <GridColumn
              title="Upfront Deposit (₹)"
              cell={(props) => (
                <td>
                  <Input
                    type="text"
                    onInput={(e)=>{
                      console.log(e, "<<`")
                    }}

                    value={props.dataItem.deposit}
                    onChange={(event) =>
                      handleChange(event, "deposit", props.dataItem.id)
                    }
                  />
                </td>
              )}
            />
            <GridColumn
              title="Price (₹)"
              cell={(props) => (
                <td>
                  <Field
                    component={TextInputWithValidation}
                    name={`${props.field}`}
                    validator={requiredValidator}
                  />
                  <Input
                    type="text"
                    value={props.dataItem.price}
                    onChange={(event) =>
                      handleChange(event, "price", props.dataItem.id)
                    }
                  />
                </td>
              )}
            />
            <GridColumn
              title="Tax (GST in %)"
              cell={(props) => (
                <td>
                  <Input
                    type="text"
                    value={props.dataItem.tax}
                    onChange={(event) =>
                      handleChange(event, "tax", props.dataItem.id)
                    }
                  />
                </td>
              )}
            />{" "}
          </Grid> */}
            {/* {data.rows.map((item, index) => (
            <div key={item.id}>
              <Label>{item.duration}:</Label>
          <input
            type="text"
            value={item.deposit}
            onChange={(event) =>
              handleChange(event, "deposit", item.id)}
          />
            </div>
          ))} */}
            {/* </div> */}

            <div
              className="center-view"
              style={{
                paddingBottom: "50px",
              }}
            >
              <div className="form-row-area">
                <div className="form-group-area"></div>
              </div>
              <div className="button-group">
                {update === true ? (
                  <Button className="button-view" onClick={handleSubmit}>
                    {loader === false ? "UPDATE" : "Loading..."}
                  </Button>
                ) : (
                  <div>
                    {tableData.length !== 0 ? (
                      <Button className="button-view" onClick={handleSubmit}>
                        {loader === false ? "CREATE" : "Loading..."}
                      </Button>
                    ) : (
                      <div></div>
                    )}
                  </div>
                )}
                <Button
                  // type="submit"
                  className="common-button-view"
                  onClick={backScreen}
                >
                  CANCEL
                </Button>
              </div>
            </div>
            {/* </form> */}
            {statusDialog && (
              <Dialog>
                <div
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                  }}
                >
                  <h3>{alert}</h3>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingTop: "30px",
                    }}
                  >
                    <Button
                      className="button-view"
                      onClick={() => navigateBack()}
                    >
                      Ok
                    </Button>
                  </div>
                </div>
              </Dialog>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
