import axios from "axios";
import Config from "./Config";

const instance = axios.create(Config);

export const setupAxiosInterceptors = (store, dispatch) => {
  instance.interceptors.request.use(
    (config) => {
      /*
      const { auth_str, userId, userTradeRole } = store.getState().user
      if (auth_str) {
        config.headers['Authorization'] = `Bearer ${auth_str}`
      }
     */
      config.headers["Authorization"] = `Bearer ${localStorage.getItem(
        "token"
      )}`;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.data.code === "UNAUTHORIZED") {
      } //tokenExpired(dispatch);
      return Promise.reject(error);
    }
  );
};

export default instance;
