import React, { useEffect, useRef, useState } from "react";
import Breadcrumbs from "../components/Breadcrumbs";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import {
  chartPieIcon,
  chevronDownIcon,
  exportIcon,
  eyeIcon,
  listUnorderedIcon,
} from "@progress/kendo-svg-icons";
import { Card, CardBody } from "@progress/kendo-react-layout";
import { Label } from "@progress/kendo-react-labels";
import {
  Chart,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesLabels,
} from "@progress/kendo-react-charts";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import "../css/Reports.scss";
import "../css/Common.scss";
import "../css/Dashboard.scss";
import { Grid, GridCellProps, GridColumn } from "@progress/kendo-react-grid";
import {
  commonBaseUrl,
  commonChartColor,
  RightAlignedHeaderCell,
} from "../../services/CommonUtil";
import axios from "axios";
import { PDFExport } from "@progress/kendo-react-pdf";
import { useNavigate } from "react-router-dom";
import { process, State } from "@progress/kendo-data-query";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { Loader } from "@progress/kendo-react-indicators";
import { TextBox } from "@progress/kendo-react-inputs";

interface bookingReport {
  totalBookings: number;
  totalPastBookings: number;
  totalCancelledBookings: number;
  mostBookedSpaces: [
    {
      spaceTypeId: number;
      spaceTypeName: string;
      bookingCount: number;
      percentage: number;
    }
  ];
  peakHours: [
    {
      bookingFromTime: string;
      bookingCount: number;
    }
  ];
  avgDurationBooked: [
    {
      spaceName: string;
      durationTypeId: number;
      durationType: string;
      totalBookingCount: number;
      percentage: number;
    }
  ];
}

const initialDataState: State = {
  take: 5,
  skip: 0,
  group: [],
};

export const BookingSummary = () => {
  const baseURL = commonBaseUrl();

  const navigate = useNavigate();
  const hasFetched = useRef(false);
  const [selected, setSelected] = useState<string>("summary");
  const userTypeList = [
    {
      Id: 1,
      Name: "Company",
    },
    {
      Id: 2,
      Name: "Individual",
    },
  ];

  const defaultRoomTypeValue = {
    Id: 0,
    Name: "Select Room Type",
  };
  const defaultUserTypeValue = {
    Id: 0,
    Name: "Select User Type",
  };
  const [loading, setLoading] = React.useState(false);
  const [roomType, setRoomType] = useState(defaultRoomTypeValue);
  const [roomTypeList, setRoomTypeList] = useState([]);
  const [userType, setUserType] = useState(defaultUserTypeValue);
  const [bookingFrom, setBookingFrom] = useState<Date | null>(null);
  const [bookingTo, setBookingTo] = useState<Date | null>(null);
  const [roomTypeId, setRoomTypeId] = useState(0);
  const [userTypeId, setUserTypeId] = useState(0);

  const [allReportList, setAllReportList] = useState<bookingReport>();
  const [allbookingList, setAllbookingList] = useState([]);
  const [allCompletedbookingList, setAllCompletedbookingList] = useState([]);
  const [allCancelledBookingList, setAllCancelledBookingList] = useState([]);
  const [allMostBookingList, setAllMostdBookingList] = useState([]);
  const [allPeakBookingList, setAllPeakdBookingList] = useState([]);
  const [allAvgBookingList, setAllAvgdBookingList] = useState([]);

  const [filteredActiveData, setFiltereActivedData] = useState([]);
  const [filteredCompletedData, setFiltereCompletedData] = useState([]);
  const [filteredCancelData, setFiltereCancelData] = useState([]);
  const [dataActiveState, setDataActiveState] =
    useState<State>(initialDataState);
  const [dataCompletedState, setDataCompletedState] =
    useState<State>(initialDataState);
  const [dataCancelState, setDataCancelState] =
    useState<State>(initialDataState);

  const _exportActive = React.useRef<ExcelExport | null>(null);
  const _exportCompleted = React.useRef<ExcelExport | null>(null);
  const _exportCancel = React.useRef<ExcelExport | null>(null);
  const _gridActive = React.useRef<any>();
  const _gridCompleted = React.useRef<any>();
  const _gridCancel = React.useRef<any>();
  const pdfExportComponent = React.useRef<PDFExport>(null);

  // CSS styling for the components
  const styles = `
.no-wheel input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Hide spinner controls */
  pointer-events: none;
  user-select: none;
}

.no-wheel input::-webkit-inner-spin-button,
.no-wheel input::-webkit-calendar-picker-indicator {
  display: none;
}

.no-wheel input::-moz-inner-spin-button {
  display: none;
}
`;

  // Helper function to convert month number to short month name
  const getMonthName = (monthNumber: any) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return monthNames[monthNumber];
  };

  // Function to format the date string DD-MMM-YYYY hh:mm a
  const customDate = (dateString: any) => {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = getMonthName(date.getMonth());
    const year = date.getFullYear();

    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");

    const ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12;

    const formattedHours = String(hours).padStart(2, "0");

    return `${day}-${month}-${year} ${formattedHours}:${minutes} ${ampm}`;
  };

  // Function to format the date string DD-MMM-YYYY
  const customDateOnly = (dateString: any) => {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = getMonthName(date.getMonth());
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  // Function to format date to YYYY-MM-DD
  const formatDate = (date: any) => {
    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const getStartDate = () => {
    let now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), 1);
  };

  const getEndDate = () => {
    let now = new Date();
    return new Date(now.getFullYear(), now.getMonth() + 1, 0);
  };

  // BreadCrumbs
  const breadCrumbsData = [
    {
      id: "1",
      text: "Reports",
      route: "",
    },
    {
      id: "2",
      text: "Booking Summary Reports",
      route: "",
    },
  ];

  let colorIndex = 0; // Initialize a color index outside the function
  const commonChartColorCode = () => {
    const colors = [
      "#58c8ef",
      "#4d9fb1",
      "#4d82b1",
      "#7b659d",
      "#ed4d9c",
      "#ec4d74",
      "#f2944e",
      "#fccf4d",
      "#abcc66",
      "#56878f",
    ];
    // Get the current color based on the color index
    const color = colors[colorIndex];
    // Increment the color index and reset if it exceeds the length of the colors array
    colorIndex = (colorIndex + 1) % colors.length;
    return color;
  };

  useEffect(() => {
    // Prevent second call
    if (hasFetched.current) return;
    hasFetched.current = true;
    const startDate = formatDate(getStartDate());
    const endDate = formatDate(getEndDate());
    setBookingFrom(getStartDate());
    setBookingTo(getEndDate());
    getRoomType();
    getCurrentMonthSummary(roomTypeId, userTypeId, startDate, endDate);
    console.log("commonChartColorCode", commonChartColorCode);
  }, [commonChartColorCode]);

  // Initial API call
  const getRoomType = async () => {
    try {
      const response = await axios.get(`${baseURL}odata/SpaceTypes`);
      setRoomTypeList(response.data.value);
    } catch (err) {
      alert(err);
    }
  };

  const getCurrentMonthSummary = async (
    roomTypeId: any,
    userTypeId: any,
    startDate: string,
    endDate: string
  ) => {
    let fullURL = ``;
    let roomURL = roomTypeId === 0 ? "" : `&spaceTypeId=${roomTypeId}`;
    let userURL = userTypeId === 0 ? "" : `&customerTypeId=${userTypeId}`;

    fullURL = `${baseURL}odata/GetBookingSummary?startDate=${startDate}&endDate=${endDate}${roomURL}${userURL}`;
    try {
      const response = await axios.get(fullURL);
      setAllReportList(response.data);
      setAllMostdBookingList(response.data?.mostBookedSpaces);
      setAllPeakdBookingList(response.data?.peakHours);
      setAllAvgdBookingList(response.data?.avgDurationBooked);
      getCurrentMonthActiveDetails(roomTypeId, userTypeId, startDate, endDate);
    } catch (err) {
      alert(err);
    }
  };

  const getCurrentMonthActiveDetails = async (
    roomTypeId: any,
    userTypeId: any,
    startDate: string,
    endDate: string
  ) => {
    let fullURL = ``;
    let roomURL = roomTypeId === 0 ? "" : `&spaceTypeId=${roomTypeId}`;
    let userURL = userTypeId === 0 ? "" : `&customerTypeId=${userTypeId}`;

    fullURL = `${baseURL}odata/GetActiveBookingDetailsByDate?startDate=${startDate}&endDate=${endDate}${roomURL}${userURL}`;
    try {
      const response = await axios.get(fullURL);
      var content = response.data;
      for (let i = 0; i < content.length; i++) {
        if (Number(content[i]["durationTypeId"]) === 1) {
          content[i]["bookingFrom"] = customDate(content[i]["bookingFrom"]);
          content[i]["bookingTo"] = customDate(content[i]["bookingTo"]);
        } else {
          content[i]["bookingFrom"] = customDateOnly(content[i]["bookingFrom"]);
          content[i]["bookingTo"] = customDateOnly(content[i]["bookingTo"]);
        }
        content[i]["bookedDate"] = customDateOnly(content[i]["bookedDate"]);
      }
      const dataWithSerialNumbers = content.map((item: any, index: any) => ({
        serialNumber: index + 1,
        ...item,
      }));
      if (searchActiveTerm.trim() === "") {
        setFiltereActivedData(dataWithSerialNumbers); // Reset to original data when search is empty
      } else {
        const newData = dataWithSerialNumbers.filter((item: any) => {
          return item.userName
            ?.toLowerCase()
            .includes(searchActiveTerm.toLowerCase());
        });
        setDataActiveState(initialDataState);
        setFiltereActivedData(newData);
      }
      setAllbookingList(dataWithSerialNumbers);
      getCurrentMonthCompletedDetails(
        roomTypeId,
        userTypeId,
        startDate,
        endDate
      );
    } catch (err) {
      alert(err);
    }
  };

  const getCurrentMonthCompletedDetails = async (
    roomTypeId: any,
    userTypeId: any,
    startDate: string,
    endDate: string
  ) => {
    let fullURL = ``;
    let roomURL = roomTypeId === 0 ? "" : `&spaceTypeId=${roomTypeId}`;
    let userURL = userTypeId === 0 ? "" : `&customerTypeId=${userTypeId}`;

    fullURL = `${baseURL}odata/GetPastBookingDetailsByDate?startDate=${startDate}&endDate=${endDate}${roomURL}${userURL}`;
    try {
      const response = await axios.get(fullURL);
      var content = response.data;
      for (let i = 0; i < content.length; i++) {
        if (Number(content[i]["durationTypeId"]) === 1) {
          content[i]["bookingFrom"] = customDate(content[i]["bookingFrom"]);
          content[i]["bookingTo"] = customDate(content[i]["bookingTo"]);
        } else {
          content[i]["bookingFrom"] = customDateOnly(content[i]["bookingFrom"]);
          content[i]["bookingTo"] = customDateOnly(content[i]["bookingTo"]);
        }
        content[i]["bookedDate"] = customDateOnly(content[i]["bookedDate"]);
      }
      const dataWithSerialNumbers = content.map((item: any, index: any) => ({
        serialNumber: index + 1,
        ...item,
      }));
      if (searchPastTerm.trim() === "") {
        setFiltereCompletedData(dataWithSerialNumbers); // Reset to original data when search is empty
      } else {
        const newData = dataWithSerialNumbers.filter((item: any) => {
          return item.userName
            ?.toLowerCase()
            .includes(searchPastTerm.toLowerCase());
        });
        setDataCompletedState(initialDataState);
        setFiltereCompletedData(newData);
      }
      setAllCompletedbookingList(dataWithSerialNumbers);
      getCurrentMonthCancelDetails(roomTypeId, userTypeId, startDate, endDate);
    } catch (err) {
      alert(err);
    }
  };

  const getCurrentMonthCancelDetails = async (
    roomTypeId: any,
    userTypeId: any,
    startDate: string,
    endDate: string
  ) => {
    let fullURL = ``;
    let roomURL = roomTypeId === 0 ? "" : `&spaceTypeId=${roomTypeId}`;
    let userURL = userTypeId === 0 ? "" : `&customerTypeId=${userTypeId}`;

    fullURL = `${baseURL}odata/GetCancelledBookingDetailsByDate?startDate=${startDate}&endDate=${endDate}${roomURL}${userURL}`;
    try {
      const response = await axios.get(fullURL);
      var content = response.data;
      for (let i = 0; i < content.length; i++) {
        if (Number(content[i]["durationTypeId"]) === 1) {
          content[i]["bookingFrom"] = customDate(content[i]["bookingFrom"]);
          content[i]["bookingTo"] = customDate(content[i]["bookingTo"]);
        } else {
          content[i]["bookingFrom"] = customDateOnly(content[i]["bookingFrom"]);
          content[i]["bookingTo"] = customDateOnly(content[i]["bookingTo"]);
        }
        content[i]["bookedDate"] = customDateOnly(content[i]["bookedDate"]);
      }
      const dataWithSerialNumbers = content.map((item: any, index: any) => ({
        serialNumber: index + 1,
        ...item,
      }));
      if (searchCancelTerm.trim() === "") {
        setFiltereCancelData(dataWithSerialNumbers); // Reset to original data when search is empty
      } else {
        const newData = dataWithSerialNumbers.filter((item: any) => {
          return item.userName
            ?.toLowerCase()
            .includes(searchCancelTerm.toLowerCase());
        });
        setDataCancelState(initialDataState);
        setFiltereCancelData(newData);
      }
      setAllCancelledBookingList(dataWithSerialNumbers);
    } catch (err) {
      alert(err);
    }
  };

  const handleFilterChange = () => {
    getCurrentMonthSummary(
      roomTypeId,
      userTypeId,
      formatDate(bookingFrom),
      formatDate(bookingTo)
    );
  };

  const handleClearFilters = () => {
    setRoomType(defaultRoomTypeValue);
    setUserType(defaultUserTypeValue);
    setRoomTypeId(0);
    setUserTypeId(0);
    setBookingFrom(getStartDate());
    setBookingTo(getEndDate());
    getCurrentMonthSummary(
      0,
      0,
      formatDate(getStartDate()),
      formatDate(getEndDate())
    );
  };

  // Filter flow
  // From Date filter
  const onFilterChangefromdate = (ev: any) => {
    if (
      ev.nativeEvent.code === "ArrowUp" ||
      ev.nativeEvent.code === "ArrowDown" ||
      ev.nativeEvent.type === "input"
    ) {
      ev.syntheticEvent.preventDefault();
    } else {
      const selectedFromDate = ev.value;
      setBookingFrom(selectedFromDate);
      if (bookingTo && new Date(bookingTo) < new Date(selectedFromDate)) {
        setBookingTo(selectedFromDate);
      }
    }
  };

  // To Date filter
  const onFilterChangetodate = (ev: any) => {
    if (
      ev.nativeEvent.code === "ArrowUp" ||
      ev.nativeEvent.code === "ArrowDown" ||
      ev.nativeEvent.type === "input"
    ) {
      ev.syntheticEvent.preventDefault();
    } else {
      setBookingTo(ev.value);
    }
  };

  const onFilterDropDown = (ev: any) => {
    const { name, value } = ev.target;

    if (name === "roomType") {
      setRoomType(value);
      setRoomTypeId(value.Id);
    }

    if (name === "userType") {
      setUserType(value);
      setUserTypeId(value.Id);
    }
  };

  // Button Group

  const handleSelect = (view: string) => {
    setSelected(view);
  };

  const excelActiveExport = () => {
    setLoading(true);
    if (_exportActive.current !== null && allbookingList.length !== 0) {
      _exportActive.current.save(allbookingList, _gridActive.current.columns);
    }
  };

  const excelPastExport = () => {
    setLoading(true);
    if (
      _exportCompleted.current !== null &&
      allCompletedbookingList.length !== 0
    ) {
      _exportCompleted.current.save(
        allCompletedbookingList,
        _gridCompleted.current.columns
      );
    }
  };

  const excelCancelExport = () => {
    setLoading(true);
    if (
      _exportCancel.current !== null &&
      allCancelledBookingList.length !== 0
    ) {
      _exportCancel.current.save(
        allCancelledBookingList,
        _gridCancel.current.columns
      );
    }
  };

  const exportPDFWithComponent = () => {
    setLoading(true);
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
      setLoading(false);
    }
  };

  const CustomDateCell = (props: any) => {
    const { field, dataItem } = props;
    if (Number(props.dataItem.durationTypeId) === 1) {
      return (
        <td
          style={{
            height: "75px",
            textAlign: "right",
            paddingRight: "35px",
          }}
        >
          {customDate(dataItem[field])}
        </td>
      );
    } else {
      return (
        <td
          style={{
            height: "75px",
            textAlign: "right",
            paddingRight: "35px",
          }}
        >
          {customDateOnly(dataItem[field])}
        </td>
      );
    }
  };

  const CustomDateOnlyCell = (props: any) => {
    const { field, dataItem } = props;
    return (
      <td
        style={{
          height: "75px",
          textAlign: "right",
          paddingRight: "35px",
        }}
      >
        {customDateOnly(dataItem[field])}
      </td>
    );
  };

  const processedData = allAvgBookingList.map((item: any) => ({
    ...item,
    combinedCategory: `${item.durationType} - ${item.spaceName}`, // Combine both fields
  }));

  const labelContent = (e: any) => `${e.dataItem.percentage} %`;

  const summaryView = () => {
    return (
      <div>
        <PDFExport
          ref={pdfExportComponent}
          paperSize="auto"
          margin={40}
          fileName="Booking Summary Report"
        >
          {/* Count */}
          <div className="filter-container">
            <Label className="dashboard-header booking-card-header-align">
              Bookings Overview
            </Label>
          </div>
          <div>
            <div className="dashboard-grid">
              <div className="report-item">
                <Card>
                  <CardBody className="report-count-height">
                    <h3>Active & Upcoming Bookings</h3>
                    <p>{allReportList?.totalBookings}</p>
                  </CardBody>
                </Card>
              </div>
              <div className="report-item">
                <Card>
                  <CardBody className="report-count-height">
                    <h3>Past Bookings</h3>
                    <p>{allReportList?.totalPastBookings}</p>
                  </CardBody>
                </Card>
              </div>
              <div className="report-item">
                <Card>
                  <CardBody className="report-count-height">
                    <h3>Cancelled Bookings</h3>
                    <p>{allReportList?.totalCancelledBookings}</p>
                  </CardBody>
                </Card>
              </div>
            </div>
          </div>
          {/* Chart */}
          {allMostBookingList.length > 0 ||
          allPeakBookingList.length > 0 ||
          allAvgBookingList.length > 0 ? (
            <div>
              <div className="dashboard-chart">
                <div className="chart-container">
                  {/* Pie chart */}
                  <div className="chart-section">
                    <Label className="dashboard-header">
                      Most Booked Space
                    </Label>
                    {allMostBookingList.length > 0 ? (
                      <Chart className="report-border" transitions={false}>
                        <ChartLegend position="bottom" />
                        <ChartSeries>
                          <ChartSeriesItem
                            color={commonChartColorCode}
                            type="pie"
                            field="percentage"
                            categoryField="spaceTypeName"
                            data={allMostBookingList}
                            labels={{ visible: true }}
                          >
                            <ChartSeriesLabels
                              color="#000"
                              background="none"
                              content={labelContent}
                            />
                          </ChartSeriesItem>
                        </ChartSeries>
                      </Chart>
                    ) : (
                      <div
                        style={{
                          textAlign: "center",
                        }}
                      >
                        No Most Booked Space data available
                      </div>
                    )}
                  </div>
                  {/* Line chart */}
                  <div className="chart-section">
                    <Label className="dashboard-header">
                      Peak Booking Time
                    </Label>
                    {allPeakBookingList.length > 0 ? (
                      <Chart className="report-border" transitions={false}>
                        <ChartSeries>
                          <ChartSeriesItem
                            color={"#abcc66"}
                            style="smooth"
                            type="line"
                            field="bookingCount"
                            categoryField="bookingFromTime"
                            data={allPeakBookingList}
                          />
                        </ChartSeries>
                      </Chart>
                    ) : (
                      <div
                        style={{
                          textAlign: "center",
                        }}
                      >
                        No Peak Booking Time data available
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="dashboard-chart report-top">
                <div className="chart-container report-chart">
                  {/* Pie chart */}
                  <div className="chart-full-section">
                    <Label className="dashboard-header">
                      Average Booking Duration
                    </Label>
                    {allAvgBookingList.length > 0 ? (
                      <Chart className="report-border" transitions={false}>
                        <ChartLegend position="right" />
                        <ChartSeries>
                          <ChartSeriesItem
                            color={commonChartColorCode}
                            type="pie"
                            field="percentage"
                            categoryField="combinedCategory"
                            data={processedData}
                            labels={{ visible: true }}
                          >
                            <ChartSeriesLabels
                              color="#000"
                              background="none"
                              content={labelContent}
                            />
                          </ChartSeriesItem>
                        </ChartSeries>
                      </Chart>
                    ) : (
                      <div
                        style={{
                          textAlign: "center",
                        }}
                      >
                        No Average Booking Duration data available
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div
              style={{
                textAlign: "center",
              }}
            >
              No summary view data available
            </div>
          )}
        </PDFExport>
      </div>
    );
  };

  // View active booking
  const MyViewCommandCell = (props: ViewCommandCellProps) => (
    <ViewCommandCell {...props} enterView={props.enterView} />
  );

  const ViewCommandCell = (props: ViewCommandCellProps) => {
    return (
      <td style={{ paddingLeft: "0px" }}>
        <Button
          className="view-color"
          type="button"
          svgIcon={eyeIcon}
          onClick={() => props.enterView(props.dataItem)}
        ></Button>
      </td>
    );
  };

  const enterView = (item: any) => {
    console.log(
      "get",
      allCompletedbookingList,
      allbookingList,
      allCancelledBookingList
    );
    for (let d = 0; d < allCompletedbookingList.length; d++) {
      if (item.id === allCompletedbookingList[d]["id"]) {
        getBookingData(allCompletedbookingList[d]["id"], false);
        break;
      }
    }
    for (let d = 0; d < allbookingList.length; d++) {
      if (item.id === allbookingList[d]["id"]) {
        getBookingData(allbookingList[d]["id"], false);
        break;
      }
    }
    for (let d = 0; d < allCancelledBookingList.length; d++) {
      if (item.id === allCancelledBookingList[d]["id"]) {
        getBookingData(allCancelledBookingList[d]["id"], false);
        break;
      }
    }
  };

  const getBookingData = async (id: any, view: any) => {
    try {
      const response = await axios.get(` ${baseURL}odata/Bookings(${id})`);
      navigate("/viewbooking", {
        state: { infoId: response.data, view },
      });
    } catch (err) {}
  };

  // Grid flow
  // Pagination with settings
  const handlePageChange = (pageState: any) => {
    setDataActiveState({ ...dataActiveState, ...pageState });
  };

  const getPageableSettings = () => {
    const length = filteredActiveData.length;
    if (length === 0) {
      return false;
    } else if (length <= 5) {
      return { pageSizes: false, buttonCount: 1 };
    } else if (length <= 10) {
      return { pageSizes: [5, 10], buttonCount: 2 };
    } else if (length <= 20) {
      return { pageSizes: [5, 10, 20], buttonCount: 5 };
    } else {
      return { pageSizes: [5, 10, 20, 50], buttonCount: 5 };
    }
  };

  const handleCompletedPageChange = (pageState: any) => {
    setDataCompletedState({ ...dataCompletedState, ...pageState });
  };

  const getCompletedPageableSettings = () => {
    const length = filteredCompletedData.length;
    if (length === 0) {
      return false;
    } else if (length <= 5) {
      return { pageSizes: false, buttonCount: 1 };
    } else if (length <= 10) {
      return { pageSizes: [5, 10], buttonCount: 2 };
    } else if (length <= 20) {
      return { pageSizes: [5, 10, 20], buttonCount: 5 };
    } else {
      return { pageSizes: [5, 10, 20, 50], buttonCount: 5 };
    }
  };

  const handleCancelPageChange = (pageState: any) => {
    setDataCancelState({ ...dataCancelState, ...pageState });
  };

  const getCancelPageableSettings = () => {
    const length = filteredCancelData.length;
    if (length === 0) {
      return false;
    } else if (length <= 5) {
      return { pageSizes: false, buttonCount: 1 };
    } else if (length <= 10) {
      return { pageSizes: [5, 10], buttonCount: 2 };
    } else if (length <= 20) {
      return { pageSizes: [5, 10, 20], buttonCount: 5 };
    } else {
      return { pageSizes: [5, 10, 20, 50], buttonCount: 5 };
    }
  };
  //

  const handleActiveExport = (e: any) => {
    setLoading(false);
  };

  const handleCompletedExport = (e: any) => {
    setLoading(false);
  };

  const handleCanceledExport = (e: any) => {
    setLoading(false);
  };

  const [searchActiveTerm, setSearchActiveTerm] = useState("");
  const [searchPastTerm, setSearchPastTerm] = useState("");
  const [searchCancelTerm, setSearchCancelTerm] = useState("");

  const handleActiveSearchChange = (e: any) => {
    const value = e.target.value; // Update to use `target.value` from event
    setSearchActiveTerm(value);
    if (value === "") {
      setFiltereActivedData(allbookingList); // Reset to original data when search is empty
    } else {
      const newData = allbookingList.filter((item: any) => {
        return (
          item.userName?.toLowerCase().includes(value.toLowerCase()) ||
          item.userEmail?.toLowerCase().includes(value.toLowerCase()) ||
          item.userMobileNo
            .toString()
            ?.toLowerCase()
            .includes(value.toLowerCase())
        );
      });
      setDataActiveState(initialDataState);
      setFiltereActivedData(newData);
    }
  };

  const resetActiveSearch = () => {
    setSearchActiveTerm("");
    setFiltereActivedData(allbookingList);
  };

  const handlePastSearchChange = (e: any) => {
    const value = e.target.value; // Update to use `target.value` from event
    setSearchPastTerm(value);
    if (value === "") {
      setFiltereCompletedData(allCompletedbookingList);
    } else {
      const newData = allCompletedbookingList.filter((item: any) => {
        return (
          item.userName?.toLowerCase().includes(value.toLowerCase()) ||
          item.userEmail?.toLowerCase().includes(value.toLowerCase()) ||
          item.userMobileNo
            .toString()
            ?.toLowerCase()
            .includes(value.toLowerCase())
        );
      });
      setDataCompletedState(initialDataState);
      setFiltereCompletedData(newData);
    }
  };

  const resetPastSearch = () => {
    setSearchPastTerm("");
    setFiltereCompletedData(allCompletedbookingList);
  };

  const handleActiveCancelChange = (e: any) => {
    const value = e.target.value; // Update to use `target.value` from event
    setSearchCancelTerm(value);
    if (value === "") {
      setFiltereCancelData(allCancelledBookingList);
    } else {
      const newData = allCancelledBookingList.filter((item: any) => {
        return (
          item.userName?.toLowerCase().includes(value.toLowerCase()) ||
          item.userEmail?.toLowerCase().includes(value.toLowerCase()) ||
          item.userMobileNo
            .toString()
            ?.toLowerCase()
            .includes(value.toLowerCase())
        );
      });
      setDataCancelState(initialDataState);
      setFiltereCancelData(newData);
    }
  };

  const resetCancelSearch = () => {
    setSearchCancelTerm("");
    setFiltereCancelData(allCancelledBookingList);
  };

  const detailedView = () => {
    return (
      <div>
        {/* Checked-In and Cancelled Grid */}
        <div className="dashboard">
          <div className="report-booking-summary-container">
            <Label className="dashboard-header booking-card-header-align">
              Bookings Overview
            </Label>
            <div className="report-booking-summary-section ">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "1%",
                  alignItems: "center",
                }}
              >
                <TextBox
                  className="textBoxcustom"
                  placeholder="Search using Customer Name , Mobile Number & Email"
                  name="roomcost"
                  value={searchActiveTerm}
                  onChange={handleActiveSearchChange}
                  suffix={() => (
                    <>
                      <span className="input-icon">
                        {searchActiveTerm ? (
                          <Button icon="close" onClick={resetActiveSearch} />
                        ) : (
                          <Button icon="search" />
                        )}
                      </span>
                    </>
                  )}
                />
              </div>
              <div className="header-row">
                <Label className="dashboard-header">
                  Active & Upcoming Bookings
                </Label>
                <div>
                  {allbookingList.length > 0 && (
                    <Button
                      className="bio-export-button-view"
                      svgIcon={exportIcon}
                      onClick={excelActiveExport}
                    >
                      Export To CSV
                    </Button>
                  )}
                </div>
              </div>
              <ExcelExport
                ref={_exportActive}
                fileName="Active and Upcoming Booking.xlsx"
                onExportComplete={(e) => handleActiveExport(e)}
              />
              <Grid
                className={loading ? "k-disabled" : ""}
                data={process(filteredActiveData, dataActiveState)}
                pageable={getPageableSettings()}
                skip={dataActiveState.skip}
                take={dataActiveState.take}
                total={filteredActiveData.length}
                onPageChange={(e) => setDataActiveState(e.page)}
                onDataStateChange={handlePageChange}
                ref={_gridActive}
              >
                <GridColumn
                  field="serialNumber"
                  title="S.No."
                  width={10}
                  headerCell={RightAlignedHeaderCell}
                  cell={(props) => (
                    <td
                      style={{
                        height: "75px",
                        textAlign: "right",
                        paddingRight: "35px",
                      }}
                    >
                      {props.dataIndex + 1}
                    </td>
                  )}
                />
                <GridColumn field="bookingId" title="Booking ID" />
                <GridColumn
                  field="userName"
                  title="Customer Name"
                  cell={(props) => (
                    <td
                      style={{
                        height: "75px",
                        textAlign: "left",
                      }}
                    >
                      {props.dataItem.userName}
                    </td>
                  )}
                />
                <GridColumn
                  field="userMobileNo"
                  title="Mobile Number"
                  headerCell={RightAlignedHeaderCell}
                  cell={(props) => (
                    <td
                      style={{
                        height: "75px",
                        textAlign: "right",
                        paddingRight: "35px",
                      }}
                    >
                      {props.dataItem.userMobileNo}
                    </td>
                  )}
                />
                <GridColumn
                  field="userEmail"
                  title="Email"
                  cell={(props) => (
                    <td style={{ wordBreak: "break-word" }}>
                      {props.dataItem.userEmail}
                    </td>
                  )}
                />
                <GridColumn
                  field="spaceName"
                  title="Space Name"
                  cell={(props) => (
                    <td
                      style={{
                        height: "75px",
                        textAlign: "left",
                      }}
                    >
                      {props.dataItem.spaceName}
                    </td>
                  )}
                />
                <GridColumn
                  field="durationTypeName"
                  title="Duration"
                  cell={(props) => (
                    <td
                      style={{
                        height: "75px",
                        textAlign: "left",
                      }}
                    >
                      {props.dataItem.durationTypeName}
                    </td>
                  )}
                />
                <GridColumn
                  field="bookingFrom"
                  title="Booking From"
                  headerCell={RightAlignedHeaderCell}
                  cell={(props) => <CustomDateCell {...props} />}
                />
                <GridColumn
                  field="bookingTo"
                  title="Booking To"
                  headerCell={RightAlignedHeaderCell}
                  cell={(props) => <CustomDateCell {...props} />}
                />
                <GridColumn
                  field="bookedDate"
                  title="Booked Date"
                  headerCell={RightAlignedHeaderCell}
                  cell={(props) => <CustomDateOnlyCell {...props} />}
                />
                <GridColumn
                  title="Action"
                  cell={(props) => (
                    <td>
                      <MyViewCommandCell {...props} enterView={enterView} />
                    </td>
                  )}
                />
              </Grid>
            </div>
            <div className="report-booking-summary-section ">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "1%",
                  alignItems: "center",
                }}
              >
                <TextBox
                  className="textBoxcustom"
                  placeholder="Search using Customer Name , Mobile Number & Email"
                  name="roomcost"
                  value={searchPastTerm}
                  onChange={handlePastSearchChange}
                  suffix={() => (
                    <>
                      <span className="input-icon">
                        {searchPastTerm ? (
                          <Button icon="close" onClick={resetPastSearch} />
                        ) : (
                          <Button icon="search" />
                        )}
                      </span>
                    </>
                  )}
                />
              </div>
              <div className="header-row">
                <Label className="dashboard-header">Past Bookings</Label>
                <div>
                  {allCompletedbookingList.length > 0 && (
                    <Button
                      className="bio-export-button-view"
                      svgIcon={exportIcon}
                      onClick={excelPastExport}
                    >
                      Export To CSV
                    </Button>
                  )}
                </div>
              </div>
              <ExcelExport
                ref={_exportCompleted}
                fileName="Past Booking.xlsx"
                onExportComplete={(e) => handleCompletedExport(e)}
              />
              <Grid
                className={loading ? "k-disabled" : ""}
                data={process(filteredCompletedData, dataCompletedState)}
                pageable={getCompletedPageableSettings()}
                skip={dataCompletedState.skip}
                take={dataCompletedState.take}
                total={filteredCompletedData.length}
                onPageChange={(e) => setDataCompletedState(e.page)}
                onDataStateChange={handleCompletedPageChange}
                ref={_gridCompleted}
              >
                <GridColumn
                  field="serialNumber"
                  title="S.No."
                  width={10}
                  headerCell={RightAlignedHeaderCell}
                  cell={(props) => (
                    <td
                      style={{
                        height: "75px",
                        textAlign: "right",
                        paddingRight: "35px",
                      }}
                    >
                      {props.dataIndex + 1}
                    </td>
                  )}
                />
                <GridColumn field="bookingId" title="Booking ID" />
                <GridColumn
                  field="userName"
                  title="Customer Name"
                  cell={(props) => (
                    <td
                      style={{
                        height: "75px",
                        textAlign: "left",
                      }}
                    >
                      {props.dataItem.userName}
                    </td>
                  )}
                />
                <GridColumn
                  field="userMobileNo"
                  title="Mobile Number"
                  headerCell={RightAlignedHeaderCell}
                  cell={(props) => (
                    <td
                      style={{
                        height: "75px",
                        textAlign: "right",
                        paddingRight: "35px",
                      }}
                    >
                      {props.dataItem.userMobileNo}
                    </td>
                  )}
                />
                <GridColumn
                  field="userEmail"
                  title="Email"
                  cell={(props) => (
                    <td style={{ wordBreak: "break-word" }}>
                      {props.dataItem.userEmail}
                    </td>
                  )}
                />
                <GridColumn
                  field="spaceName"
                  title="Space Name"
                  cell={(props) => (
                    <td
                      style={{
                        height: "75px",
                        textAlign: "left",
                      }}
                    >
                      {props.dataItem.spaceName}
                    </td>
                  )}
                />
                <GridColumn
                  field="durationTypeName"
                  title="Duration"
                  cell={(props) => (
                    <td
                      style={{
                        height: "75px",
                        textAlign: "left",
                      }}
                    >
                      {props.dataItem.durationTypeName}
                    </td>
                  )}
                />
                <GridColumn
                  field="bookingFrom"
                  title="Booking From"
                  headerCell={RightAlignedHeaderCell}
                  cell={(props) => <CustomDateCell {...props} />}
                />
                <GridColumn
                  field="bookingTo"
                  title="Booking To"
                  headerCell={RightAlignedHeaderCell}
                  cell={(props) => <CustomDateCell {...props} />}
                />
                <GridColumn
                  field="bookedDate"
                  title="Booked Date"
                  headerCell={RightAlignedHeaderCell}
                  cell={(props) => <CustomDateOnlyCell {...props} />}
                />
                <GridColumn
                  title="Action"
                  width={10}
                  cell={(props) => (
                    <td>
                      <MyViewCommandCell {...props} enterView={enterView} />
                    </td>
                  )}
                />
              </Grid>
            </div>
            <div className="report-booking-summary-section ">
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginBottom: "1%",
                  alignItems: "center",
                }}
              >
                <TextBox
                  className="textBoxcustom"
                  placeholder="Search using Customer Name , Mobile Number & Email"
                  name="roomcost"
                  value={searchCancelTerm}
                  onChange={handleActiveCancelChange}
                  suffix={() => (
                    <>
                      <span className="input-icon">
                        {searchCancelTerm ? (
                          <Button icon="close" onClick={resetCancelSearch} />
                        ) : (
                          <Button icon="search" />
                        )}
                      </span>
                    </>
                  )}
                />
              </div>
              <div className="header-row">
                <Label className="dashboard-header">Cancelled Bookings</Label>
                <div>
                  {allCancelledBookingList.length > 0 && (
                    <Button
                      className="bio-export-button-view"
                      svgIcon={exportIcon}
                      onClick={excelCancelExport}
                    >
                      Export To CSV
                    </Button>
                  )}
                </div>
              </div>
              <ExcelExport
                ref={_exportCancel}
                fileName="Canceled Booking.xlsx"
                onExportComplete={(e) => handleCanceledExport(e)}
              />
              <Grid
                className={loading ? "k-disabled" : ""}
                data={process(filteredCancelData, dataCancelState)}
                pageable={getCancelPageableSettings()}
                skip={dataCancelState.skip}
                take={dataCancelState.take}
                total={filteredCancelData.length}
                onPageChange={(e) => setDataCancelState(e.page)}
                onDataStateChange={handleCancelPageChange}
                ref={_gridCancel}
              >
                <GridColumn
                  field="serialNumber"
                  title="S.No."
                  width={10}
                  headerCell={RightAlignedHeaderCell}
                  cell={(props) => (
                    <td
                      style={{
                        height: "75px",
                        textAlign: "right",
                        paddingRight: "35px",
                      }}
                    >
                      {props.dataIndex + 1}
                    </td>
                  )}
                />
                <GridColumn field="bookingId" title="Booking ID" />
                <GridColumn
                  field="userName"
                  title="Customer Name"
                  cell={(props) => (
                    <td
                      style={{
                        height: "75px",
                        textAlign: "left",
                      }}
                    >
                      {props.dataItem.userName}
                    </td>
                  )}
                />
                <GridColumn
                  field="userMobileNo"
                  title="Mobile Number"
                  headerCell={RightAlignedHeaderCell}
                  cell={(props) => (
                    <td
                      style={{
                        height: "75px",
                        textAlign: "right",
                        paddingRight: "35px",
                      }}
                    >
                      {props.dataItem.userMobileNo}
                    </td>
                  )}
                />
                <GridColumn
                  field="userEmail"
                  title="Email"
                  cell={(props) => (
                    <td style={{ wordBreak: "break-word" }}>
                      {props.dataItem.userEmail}
                    </td>
                  )}
                />
                <GridColumn
                  field="spaceName"
                  title="Space Name"
                  cell={(props) => (
                    <td
                      style={{
                        height: "75px",
                        textAlign: "left",
                      }}
                    >
                      {props.dataItem.spaceName}
                    </td>
                  )}
                />
                <GridColumn
                  field="durationTypeName"
                  title="Duration"
                  cell={(props) => (
                    <td
                      style={{
                        height: "75px",
                        textAlign: "left",
                      }}
                    >
                      {props.dataItem.durationTypeName}
                    </td>
                  )}
                />
                <GridColumn
                  field="bookingFrom"
                  title="Booking From"
                  headerCell={RightAlignedHeaderCell}
                  cell={(props) => <CustomDateCell {...props} />}
                />
                <GridColumn
                  field="bookingTo"
                  title="Booking To"
                  headerCell={RightAlignedHeaderCell}
                  cell={(props) => <CustomDateCell {...props} />}
                />
                <GridColumn
                  field="bookedDate"
                  title="Booked Date"
                  headerCell={RightAlignedHeaderCell}
                  cell={(props) => <CustomDateOnlyCell {...props} />}
                />
                <GridColumn
                  title="Action"
                  width="50px"
                  cell={(props) => (
                    <td>
                      <MyViewCommandCell {...props} enterView={enterView} />
                    </td>
                  )}
                />
              </Grid>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // Reference for the TimePicker input element
  const timePickerRef = useRef<HTMLDivElement | null>(null);

  // Prevent mouse wheel scrolling
  useEffect(() => {
    const element = timePickerRef.current;

    // Function to handle mouse wheel events
    const handleWheel = (event: WheelEvent) => {
      event.preventDefault(); // Prevent scrolling with mouse wheel
    };

    // Function to handle keyboard events
    const handleKeyDown = (event: KeyboardEvent) => {
      event.preventDefault();
    };

    if (element) {
      // Attach wheel event listener to prevent scrolling with mouse wheel
      element.addEventListener("wheel", handleWheel, { passive: false });

      const inputElement = element.querySelector("input");

      if (inputElement) {
        // Attach keydown event listener to prevent up/down arrows and numbers
        inputElement.addEventListener("keydown", handleKeyDown);
      }

      // Cleanup event listeners when component unmounts
      return () => {
        element.removeEventListener("wheel", handleWheel);
        if (inputElement) {
          inputElement.removeEventListener("keydown", handleKeyDown);
        }
      };
    }
  }, []);

  return (
    <div className="dashboard-bg" style={{ padding: "10px" }}>
      {loading ? (
        <Loader
          size={"large"}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
          type={"converging-spinner"}
        />
      ) : (
        <div>
          <div className="overall-view">
            <div>
              <div className="dashboard-button">
                <Breadcrumbs breadCrumbData={breadCrumbsData} />
                <div>
                  {selected === "summary" && (
                    <div>
                      {(allMostBookingList.length > 0 ||
                        allPeakBookingList.length > 0 ||
                        allAvgBookingList.length > 0 ||
                        allReportList?.totalBookings !== 0 ||
                        allReportList.totalCancelledBookings !== 0) && (
                        <Button
                          className="bio-device-button-view"
                          onClick={exportPDFWithComponent}
                        >
                          Download Summary
                        </Button>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <hr className="hr-border" />
            </div>
          </div>
          <div className="overall-view">
            <div>
              {/* Filter */}
              <div className="common-margin">
                <div>
                  <Label className="list-heading">Filter</Label>
                  <div className=" filter-view ">
                    <div className="rowset">
                      <div className="columnset">
                        <Label className="common-label-size">Room Type</Label>
                        <DropDownList
                          svgIcon={chevronDownIcon}
                          name="roomType"
                          className="book-dropdown"
                          textField="Name"
                          dataItemKey="Id"
                          data={roomTypeList}
                          value={roomType}
                          onChange={onFilterDropDown}
                        />
                      </div>
                      <div className="columnset-datepicker">
                        <Label className="common-label-size">User Type</Label>
                        <DropDownList
                          svgIcon={chevronDownIcon}
                          name="userType"
                          className="book-dropdown"
                          textField="Name"
                          dataItemKey="Id"
                          data={userTypeList}
                          value={userType}
                          onChange={onFilterDropDown}
                        />
                      </div>
                      <div className="columnset">
                        <Label className="common-label-size">
                          Booking From
                        </Label>
                        <style>{styles}</style>
                        <div className="no-wheel" ref={timePickerRef}>
                          <DatePicker
                            name="fromDate"
                            className="book-dropdown"
                            onChange={onFilterChangefromdate}
                            value={bookingFrom}
                            format={"dd/MM/yyyy"}
                            placeholder="DD/MM/YYYY"
                            ref={timePickerRef}
                          />
                        </div>
                      </div>
                      <div className="columnset">
                        <Label className="common-label-size">Booking To</Label>
                        <style>{styles}</style>
                        <div className="no-wheel" ref={timePickerRef}>
                          <DatePicker
                            name="toDate"
                            className="book-dropdown"
                            onChange={onFilterChangetodate}
                            min={bookingFrom!}
                            value={bookingTo}
                            format={"dd/MM/yyyy"}
                            placeholder="DD/MM/YYYY"
                            ref={timePickerRef}
                          />
                        </div>
                      </div>
                      <div className="columnset-end">
                        <Label className="common-label-size"></Label>
                        <Button
                          className="common-button-view search-color"
                          onClick={handleFilterChange}
                        >
                          Search
                        </Button>
                      </div>
                      <div className="columnset-end">
                        <Label className="common-label-size"></Label>
                        <Button
                          className="common-button-view clear-color"
                          onClick={handleClearFilters}
                        >
                          Clear
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Group Button */}
              <div className="button-toggle report-top">
                <ButtonGroup>
                  <Button
                    selected={selected === "summary"}
                    onClick={() => {
                      handleSelect("summary");
                    }}
                    className={
                      selected === "summary"
                        ? "selected-color"
                        : "unselect-color"
                    }
                    svgIcon={chartPieIcon}
                    style={{ borderColor: "#42B677" }}
                  >
                    SUMMARY VIEW
                  </Button>
                  <Button
                    selected={selected === "detailed"}
                    onClick={() => handleSelect("detailed")}
                    svgIcon={listUnorderedIcon}
                    className={
                      selected === "detailed"
                        ? "selected-color"
                        : "unselect-color"
                    }
                    style={{ borderColor: "#42B677" }}
                  >
                    DETAILED VIEW
                  </Button>
                </ButtonGroup>
              </div>
              {selected === "summary" ? summaryView() : detailedView()}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

interface ViewCommandCellProps extends GridCellProps {
  enterView: (item: any) => void;
}
