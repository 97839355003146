import React, { useEffect, useRef, useState } from "react";
// import { format } from "date-fns";
import bcrypt from "bcryptjs";
// import moment from "moment";

export const encryptedBcryptPassword = (password) => {
  var salt = bcrypt.genSaltSync(10);
  var hashPassword = bcrypt.hashSync(password, salt);
  return hashPassword;
};

// Register
export const encryptedBase64Password = (password) => {
  var base64EncryptedPassword = password;
  for (var i = 0; i < 5; i++) {
    base64EncryptedPassword = btoa(base64EncryptedPassword);
  }
  return base64EncryptedPassword;
};

export const decryptBase64Password = (base64EncryptedPassword) => {
  var base64DecryptedPassword = base64EncryptedPassword;
  for (var i = 0; i < 5; i++) {
    base64DecryptedPassword = atob(base64DecryptedPassword);
  }
  return base64DecryptedPassword;
};

export const commonBaseUrl = () => {
  //const baseURL = `https://dev-cw-api.equadriga.in/`; // Dev
  const baseURL = `https://preprod-api.workfromhaven.in/`; // Pre
  // const baseURL = `https://uat-wfh-api.equadriga.in/`; // UAT
  return baseURL;
};

export const commonImageUrl = () => {
  //const imageURL = `https://cws-dev-stor.equadriga.in/`; // Dev
  const imageURL = `https://preprod-stor.workfromhaven.in/`; // Pre
  // const imageURL = `https://uat-wfh-stor.equadriga.in/`; // UAT
  return imageURL;
};

export const barChartColor = () => {
  return "#abcc66";
};

export const commonChartColor = () => {
  // ArrayList of Hex Color Codes
  const colors = [
    "#58c8ef",
    "#4d9fb1",
    "#4d82b1",
    "#7b659d",
    "#ed4d9c",
    "#ec4d74",
    "#f2944e",
    "#fccf4d",
    "#abcc66",
    "#56878f",
  ];

  // Function to get a random color
  const randomIndex = Math.floor(Math.random() * colors.length);
  return colors[randomIndex];
};

export const RightAlignedHeaderCell = (props) => (
  <th
    style={{
      display: "flex",
      color: "#757575",
      fontWeight: "700",
      marginRight: "10px",
      justifyContent: "end",
      textAlign: "right",
      paddingBottom: "0px",
    }}
    {...props}
  >
    {props.title}
  </th>
);
