import * as React from "react";
import { Breadcrumb } from "@progress/kendo-react-layout";
import { useLocation, useNavigate } from "react-router-dom";

interface DataModel {
  id: number;
  text?: string;
  route: string;
}

const Breadcrumbs = (props: any) => {
  const { breadCrumbData } = props;
  const [data, setData] = React.useState(breadCrumbData);

  const navigate = useNavigate();

  const handleItemSelect = (event: any) => {
    const selectedID = event.id;
    const itemIndex = getIndex(selectedID, data);
    const newData = data[itemIndex].route;
    if (newData !== "") {
      navigate(newData);
    }
  };

  const getIndex = (idVal: any, dataArr: any) => {
    return dataArr.findIndex((item: any) => item.id === idVal);
  };

  return (
    <div>
      <Breadcrumb
        className="breadcrum-view"
        data={data}
        onItemSelect={handleItemSelect}
      />
    </div>
  );
};
export default Breadcrumbs;
