import React, { useEffect, useRef, useState } from "react";
import Breadcrumbs from "../components/Breadcrumbs";
import { Button } from "@progress/kendo-react-buttons";
import { eyeIcon } from "@progress/kendo-svg-icons";
import "../css/Reports.scss";
import "../css/Common.scss";
import "../css/Dashboard.scss";
import { Grid, GridCellProps, GridColumn } from "@progress/kendo-react-grid";
import {
  commonBaseUrl,
  RightAlignedHeaderCell,
} from "../../services/CommonUtil";
import axios from "axios";
import { process, State } from "@progress/kendo-data-query";
import { Loader } from "@progress/kendo-react-indicators";
import { TextBox } from "@progress/kendo-react-inputs";
import { useNavigate } from "react-router-dom";

const initialDataState: State = {
  take: 5,
  skip: 0,
  group: [],
};

export const MemberList = () => {
  const baseURL = commonBaseUrl();
  const navigate = useNavigate();
  const hasFetched = useRef(false);
  const [loading, setLoading] = React.useState(false);
  const [memberList, setMemberList] = useState([]);
  const [dataState, setDataState] = useState<State>(initialDataState);
  const [memberRoleList, setMemberRoleList] = useState([]);

  // BreadCrumbs
  const breadCrumbsData = [
    {
      id: "1",
      text: "Member Register",
      route: "",
    },
    {
      id: "2",
      text: "Members",
      route: "",
    },
  ];

  useEffect(() => {
    // Prevent second call
    if (hasFetched.current) return;
    hasFetched.current = true;
    MemberRoleListAPI();
  });

  // Initial API Call
  const MemberRoleListAPI = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${baseURL}odata/UserRoles`);
      setMemberRoleList(response.data.value);
      MemberListAPI(response.data.value);
    } catch (err) {
      setLoading(false);
      alert(err);
    }
  };

  const MemberListAPI = async (roleList: any) => {
    try {
      const response = await axios.get(
        // `${baseURL}odata/Users?$filter=RoleId ne 2`
        `${baseURL}odata/Users?$filter=RoleId ne 2`
      );
      const content = response.data.value;

      for (let i = 0; i < content.length; i++) {
        for (let r = 0; r < roleList.length; r++) {
          if (content[i].RoleId === roleList[r].Id) {
            content[i]["RoleId"] = roleList[r].Name;
            break;
          }
        }
      }

      setMemberList(response.data.value);
      setFilteredData(response.data.value);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  // Grid flow
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(memberList);

  const handleSearchChange = (e: any) => {
    const value = e.target.value; // Update to use `target.value` from event
    setSearchTerm(value);
    if (value === "") {
      setFilteredData(memberList); // Reset to original data when search is empty
    } else {
      const newData = memberList.filter((item: any) => {
        return (
          item.Name === null ||
          item.Name.toLowerCase().includes(value.toLowerCase()) ||
          item.Mobile.toString().includes(value.toString()) ||
          item.Email.toLowerCase().includes(value.toLowerCase())
        );
      });
      setDataState(initialDataState);
      setFilteredData(newData);
    }
  };

  // Pagination with settings
  const handlePageChange = (pageState: any) => {
    setDataState({ ...dataState, ...pageState });
  };

  const getPageableSettings = () => {
    const length = filteredData.length;
    if (length === 0) {
      return false;
    } else if (length <= 5) {
      return { pageSizes: false, buttonCount: 1 };
    } else if (length <= 10) {
      return { pageSizes: [5, 10], buttonCount: 2 };
    } else if (length <= 20) {
      return { pageSizes: [5, 10, 20], buttonCount: 5 };
    } else {
      return { pageSizes: [5, 10, 20, 50], buttonCount: 5 };
    }
  };

  // Reset filter values
  const resetSearch = () => {
    setSearchTerm("");
    setFilteredData(memberList);
  };

  // View active booking
  const MyViewCommandCell = (props: ViewCommandCellProps) => (
    <ViewCommandCell {...props} enterView={props.enterView} />
  );

  const ViewCommandCell = (props: ViewCommandCellProps) => {
    return (
      <td style={{ paddingLeft: "0px" }}>
        <Button
          className="view-color"
          type="button"
          svgIcon={eyeIcon}
          onClick={() => props.enterView(props.dataItem)}
        ></Button>
      </td>
    );
  };

  const enterView = (item: any) => {
    for (let d = 0; d < memberList.length; d++) {
      if (item.Id === memberList[d]["Id"]) {
        getMemberData(memberList[d]["Id"], false);
        break;
      }
    }
  };

  const getMemberData = async (id: any, view: any) => {
    try {
      const response = await axios.get(` ${baseURL}odata/Users(${id})`);
      navigate("/member/create", {
        state: { infoMember: response.data.value[0], view },
      });
    } catch (err) {}
  };

  const createMemberData = () => {
    const viewData = true;
    navigate("/member/create", {
      state: { infoMember: undefined, viewData },
    });
  };

  return (
    <div className="dashboard-bg" style={{ padding: "10px" }}>
      {loading ? (
        <Loader
          size={"large"}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
          type={"converging-spinner"}
        />
      ) : (
        <div>
          <div className="overall-view">
            <div>
              <div className="dashboard-button">
                <Breadcrumbs breadCrumbData={breadCrumbsData} />
                <Button
                  style={{
                    height: "20%",
                    padding: "12px",
                    backgroundColor: "#42B677",
                    color: "white",
                  }}
                  onClick={() => {
                    createMemberData();
                  }}
                  icon="plus"
                >
                  CREATE REGISTER
                </Button>
              </div>
              <hr className="hr-border" />
            </div>
          </div>
          <div className="overall-view">
            <div>
              {/* Member Grid */}
              <div className="dashboard">
                <div className="report-booking-summary-container">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "1%",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: "2rem",
                        margin: "20px 0px 10px 10px",
                      }}
                    >
                      Member List
                    </div>
                    <TextBox
                      className="textBoxcustom"
                      placeholder="Search using Name, Email ID and Mobile Number"
                      name="roomcost"
                      value={searchTerm}
                      onChange={handleSearchChange}
                      suffix={() => (
                        <>
                          <span className="input-icon">
                            {searchTerm ? (
                              <Button icon="close" onClick={resetSearch} />
                            ) : (
                              <Button icon="search" />
                            )}
                          </span>
                        </>
                      )}
                    />
                  </div>
                  <Grid
                    data={process(filteredData, dataState)}
                    pageable={getPageableSettings()}
                    skip={dataState.skip}
                    take={dataState.take}
                    total={filteredData.length}
                    onPageChange={(e) => setDataState(e.page)}
                    onDataStateChange={handlePageChange}
                  >
                    <GridColumn
                      field="serialNumber"
                      title="S.No."
                      width={10}
                      headerCell={RightAlignedHeaderCell}
                      cell={(props) => (
                        <td
                          style={{
                            height: "75px",
                            textAlign: "right",
                            paddingRight: "35px",
                          }}
                        >
                          {props.dataIndex + 1}
                        </td>
                      )}
                    />
                    <GridColumn
                      field="Name"
                      title="Member Name"
                      cell={(props) => (
                        <td
                          style={{
                            height: "75px",
                            textAlign: "left",
                          }}
                        >
                          {props.dataItem.Name}
                        </td>
                      )}
                    />
                    <GridColumn
                      field="Email"
                      title="Email ID"
                      cell={(props) => (
                        <td style={{ wordBreak: "break-word" }}>
                          {props.dataItem.Email}
                        </td>
                      )}
                    />
                    <GridColumn
                      field="Mobile"
                      title="Mobile Number"
                      headerCell={RightAlignedHeaderCell}
                      cell={(props) => (
                        <td
                          style={{
                            height: "75px",
                            textAlign: "right",
                            paddingRight: "45px",
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                          }}
                        >
                          {props.dataItem.Mobile}
                        </td>
                      )}
                    />
                    <GridColumn
                      field="RoleId"
                      title="Role"
                      // width="50px"
                      cell={(props) => (
                        <td
                          style={{
                            height: "75px",
                            textAlign: "left",
                          }}
                        >
                          {props.dataItem.RoleId}
                        </td>
                      )}
                    />
                    <GridColumn
                      title="Action"
                      width={10}
                      cell={(props) => (
                        <td>
                          <MyViewCommandCell {...props} enterView={enterView} />
                        </td>
                      )}
                    />
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

interface ViewCommandCellProps extends GridCellProps {
  enterView: (item: any) => void;
}
