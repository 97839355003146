import { Button } from "@progress/kendo-react-buttons";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Grid, GridCellProps, GridColumn } from "@progress/kendo-react-grid";
import { TextBox } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { chevronDownIcon, exportIcon } from "@progress/kendo-svg-icons";
import React, { useState } from "react";
import Breadcrumbs from "../components/Breadcrumbs";
import { process, State } from "@progress/kendo-data-query";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { useNavigate } from "react-router-dom";

const initialDataState: State = {
  take: 5,
  skip: 0,
  group: [],
};

export const AuditLogs = () => {
  const navigate = useNavigate();
  const [mainData, setMainData] = useState([]);
  const [bookingType, setBookingType] = useState();
  const [filterValue, setFilterValue] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [dataState, setDataState] = useState<State>(initialDataState);
  const [bookingTypeList, setBookinTypeList] = useState([]);
  const [accessDialog, setAccessDialog] = useState(false);

  // Breadcrums
  const breadCrumbsData = [
    {
      id: "1",
      text: "Biometric Devices",
      route: "",
    },
    {
      id: "2",
      text: "Audit Logs",
      route: "",
    },
  ];

  const handleCreate = () => {
    navigate("/biodevice/deviceconfig/add", { state: { infoId: [] } });
  };

  // Reset filter values
  const resetSearch = () => {
    setFilterValue("");
    const dataValue: any = searchData;
    for (var i = 0; i < dataValue.length; i++) {
      dataValue[i]["UserId"] = i + 1;
    }
    const DataValueMine = dataValue.filter(
      (Dataset: any) => Dataset.IsActive === true
    );
    setFilteredData(DataValueMine);
  };

  // Pagination with settings
  const handlePageChange = (pageState: any) => {
    setDataState({ ...dataState, ...pageState });
  };

  const getPageableSettings = () => {
    const length = filteredData.length;
    if (length === 0) {
      return false;
    } else if (length <= 5) {
      return { pageSizes: false, buttonCount: 1 };
    } else if (length <= 10) {
      return { pageSizes: [5, 10], buttonCount: 2 };
    } else if (length <= 20) {
      return { pageSizes: [5, 10, 20], buttonCount: 5 };
    } else {
      return { pageSizes: [5, 10, 20, 50], buttonCount: 5 };
    }
  };
  //

  // Filter search
  const onFilterChange = (ev: any) => {
    let value = ev.value;
    setFilterValue(ev.value);
    var newData: any = [];

    if (value === "") {
      newData = searchData;
    } else {
      newData = searchData.filter((item: any) => {
        return (
          item.CustomerName === null ||
          item.CustomerName.toLowerCase().includes(value.toLowerCase()) ||
          item.BookingId.toLowerCase().includes(value.toLowerCase()) ||
          item.ContactNo.toString().includes(value.toString())
        );
      });
    }
    for (var i = 0; i < newData.length; i++) {
      newData[i]["UserId"] = i + 1;
    }

    setFilteredData(newData);
    let clearedPagerDataState = { ...dataState, take: 5, skip: 0 };
    setDataState(clearedPagerDataState);
  };

  const [searchBooking, setSearchBooking] = useState("");

  // Clear data
  const clearInput = () => {
    const DataValueMine = mainData.filter(
      (Dataset: any) => Dataset.IsActive === true
    );
    setFilteredData(DataValueMine);
    setSearchBooking("");
  };

  const handleChangeBookingType = (e: any) => {
    setSearchBooking(e.value.Id);
    setBookingType(e.value);
  };

  const onHandleSearch = () => {
    //     var bookingFilterURL = ``;
    //     if (searchBooking === "" && SearchFromDate === "") {
    //     } else if (
    //       searchBooking !== "" &&
    //       searchBooking !== undefined &&
    //       SearchFromDate === ""
    //     ) {
    //       bookingFilterURL = ` ${baseURL}odata/Bookings?$expand=Space&$filter=SpaceId%20eq%20${searchBooking}&orderby=Id desc`;
    //       BookingExpandWithFilterAPi(bookingFilterURL);
    //     } else if (
    //       (searchBooking === "" || searchBooking === undefined) &&
    //       SearchFromDate !== ""
    //     ) {
    //       bookingFilterURL = `
    //  ${baseURL}odata/Bookings?$expand=Space
    // &$filter=BookingFrom%20eq%20${SearchFromDate}&orderby=Id desc`;
    //       console.log(SearchFromDate, "<< search Date");
    //       BookingExpandWithFilterAPi(bookingFilterURL);
    //     } else if (
    //       searchBooking !== "" &&
    //       searchBooking !== undefined &&
    //       SearchFromDate !== ""
    //     ) {
    //       bookingFilterURL = `
    //  ${baseURL}odata/Bookings?$expand=Space
    // &$filter=SpaceId%20eq%20${searchBooking}%20and%20BookingFrom%20eq%20${SearchFromDate}&orderby=Id desc`;
    //       BookingExpandWithFilterAPi(bookingFilterURL);
    //     }
  };

  const [selectDate, setSelectDate] = useState("");
  const [filterDate, setFilterDate] = useState<Date | null>();

  // Date  filter
  const onFilterChangetodate = (ev: any) => {
    setFilterDate(ev.value);
  };

  const customFormatterDate = (ev: any) => {
    const date = new Date(ev);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() returns 0-based index
    const day = String(date.getDate()).padStart(2, "0");

    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");

    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert 0 to 12 for 12-hour format

    const formattedTime = `${String(hours).padStart(
      2,
      "0"
    )}:${minutes} ${ampm}`;
    const formattedDate = `${year}-${month}-${day}`;
    const fullDateTime = `${formattedDate} ${formattedTime}`;
    return fullDateTime;
  };

  const CustomDateCell = (props: any) => {
    const { field, dataItem } = props;
    return <td>{customFormatterDate(dataItem[field])}</td>;
  };

  return (
    <div className="overall-view ">
      <div className="space-burger">
        <Breadcrumbs breadCrumbData={breadCrumbsData} />
        <Button
          className="bio-export-button-view"
          svgIcon={exportIcon}
          onClick={handleCreate}
        >
          Export Data
        </Button>
      </div>
      <hr className="hr-border" />
      <div>
        <div className="common-margin">
          <Label className="list-heading">Filter</Label>
          <div className=" filter-view ">
            <div className="rowset">
              <div className="columnset">
                <Label className="common-label-size">Select Date</Label>
                <DatePicker
                  name="toDate"
                  className="book-dropdown"
                  onChange={onFilterChangetodate}
                  value={filterDate}
                  format={"dd/MM/yyyy"}
                  placeholder="DD/MM/YYYY"
                />
              </div>
              <div className="columnset">
                <Label className="common-label-size">User Type</Label>
                <DropDownList
                  svgIcon={chevronDownIcon}
                  name="bookingType"
                  className="book-dropdown"
                  data={bookingTypeList}
                  textField="SpaceName"
                  value={
                    bookingType !== undefined
                      ? bookingType
                      : { Id: 0, SpaceName: "Select User Type" }
                  }
                  onChange={(e) => handleChangeBookingType(e)}
                />
              </div>
              <div className="columnset-datepicker">
                <Label className="common-label-size">Access Status</Label>
                <DropDownList
                  svgIcon={chevronDownIcon}
                  name="bookingType"
                  className="book-dropdown"
                  data={bookingTypeList}
                  textField="SpaceName"
                  value={
                    bookingType !== undefined
                      ? bookingType
                      : { Id: 0, SpaceName: "Select Access Status" }
                  }
                  onChange={(e) => handleChangeBookingType(e)}
                />
              </div>
              <div className="columnset-datepicker">
                <Label className="common-label-size">
                  Location / Space Name
                </Label>
                <DropDownList
                  svgIcon={chevronDownIcon}
                  name="bookingType"
                  className="book-dropdown"
                  data={bookingTypeList}
                  textField="SpaceName"
                  value={
                    bookingType !== undefined
                      ? bookingType
                      : { Id: 0, SpaceName: "Select Location / Space Name" }
                  }
                  onChange={(e) => handleChangeBookingType(e)}
                />
              </div>
              <div className="columnset-end">
                <Label className="common-label-size"></Label>
                <Button
                  className="common-button-view search-color"
                  onClick={onHandleSearch}
                >
                  Filter
                </Button>
              </div>
              <div className="columnset-end">
                <Label className="common-label-size"></Label>
                <Button
                  className="common-button-view clear-color"
                  onClick={clearInput}
                >
                  Clear
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="filter-container"></div>
        <div className="common-margin view-margin">
          <div className="table-title-view">
            <div className="large-custom-input-container">
              <Label className="list-heading">Audit Logs</Label>
              <TextBox
                className="textBoxcustom"
                placeholder="Search using any keywords"
                name="roomcost"
                value={filterValue}
                onChange={onFilterChange}
                suffix={() => (
                  <>
                    <span className="input-icon">
                      {filterValue ? (
                        <Button icon="close" onClick={resetSearch} />
                      ) : (
                        <Button icon="search" />
                      )}
                    </span>
                  </>
                )}
              />
            </div>
          </div>
          <div>
            <Grid
              data={process(filteredData, dataState)}
              pageable={getPageableSettings()}
              skip={dataState.skip}
              take={dataState.take}
              total={filteredData.length}
              onPageChange={(e) => setDataState(e.page)}
              onDataStateChange={handlePageChange}
            >
              <GridColumn
                field="UserId"
                title="S.No"
                format="{0:d}"
                className="list-sno"
              />
              <GridColumn
                field="BookingId"
                title="Date"
                cell={(props) => <CustomDateCell {...props} />}
              />
              <GridColumn field="CustomerName" title="Location/Space Name" />
              <GridColumn field="CustomerName" title="Access Status" />
              <GridColumn field="CustomerName" title="User ID" />
              <GridColumn field="CustomerName" title="Name" />
              <GridColumn
                field="Space.SpaceTypeName"
                title="User Type"
                filter="text"
              />
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};
