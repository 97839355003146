import { useEffect, useState } from "react";
import Breadcrumbs from "../../components/Breadcrumbs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Button } from "@progress/kendo-react-buttons";
import { LinearGradient } from "@progress/kendo-drawing";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { formatDateDMY } from "../../components/commoncomponents";

import { Label } from "@progress/kendo-react-labels";
import { Input, TextArea } from "@progress/kendo-react-inputs";
import { commonBaseUrl } from "../../../services/CommonUtil";

//interfaces
interface Row {
  type: string;
  description: string;
  rent: number;
  discount: number;
  subTotal: number;
}
interface bookingDetails {
  spaceBooked: string;
  BookingDate: string;
  Duration: string;
  Name: string;
  Mobile: string;
  Email: string;
  Address: string;
  Notes: string;
}

export const UpdateInvoice = () => {
  const location = useLocation();
  const baseURL = commonBaseUrl();

  const breadCrumbsData = [
    {
      id: "1",
      text: "Masters",
      route: "",
    },
    {
      id: "2",
      text: "Bookings",
      route: "/management/listbooking/invoice",
    },
    {
      id: "3",
      text: "Generate invoice",
      route: "",
    },
  ];

  const bookingStatuses = ["Pending", "Confirmed", "Cancelled"];
  const rowtype = ["Select", "Rent", "Other"];
  const bookingIDs = ["INWFH-0005", "INWFH-0006", "INWFH-0007"];
  const [formData, setFormData] = useState({
    bookingID: "INWFH-0005",
    status: "Pending",
    invoiceDate: null,
    dueDate: null,
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const [users, setUsers] = useState([]);
  const [notes, setNotes] = useState<string>("");
  const [total, setTotal] = useState<number>(0); // Example total with tax
  const taxRate = 0.18;
  const handleSubmit = () => {
    // Add logic to handle invoice creation (e.g., API call)
    console.log("Invoice Created:", { rows, notes, total });
  };
  const [view, setView] = useState(false);

  const [rows, setRows] = useState<Row[]>([]);
  const [BookingDetails, setBookingDetails] = useState<bookingDetails>();

  useEffect(() => {
    const subTotal = rows.reduce((sum, row) => sum + row.subTotal, 0);
    const tax = subTotal * taxRate;
    setTotal(subTotal + tax);
  }, [rows]);
  useEffect(() => {
    console.log(location.state, "location");
    if (location.state !== null) {
      getinvoiceData(location.state);
    }
  }, []);
  const getinvoiceData = async (state: any) => {
    const Data = state;
    setView(Data.view);
    console.log(Data.view, "<< Data");
    try {
      // Fetch data from the API
      const response = await axios.get(
        `${baseURL}GetInvoiceHeaderAndDetailById?id=${Data.invoiceId}`
      );

      const getData = response.data || {};
      console.log(getData, "<<- this response view");
      const rowDetailsResponce = getData.invoiceDetails;
      const rowDetails = [
        {
          type: rowDetailsResponce[0].invoiceType,
          description: rowDetailsResponce[0].description,
          rent: rowDetailsResponce[0].invoiceRent,
          discount: rowDetailsResponce[0].invoiceDiscount,
          subTotal: rowDetailsResponce[0].invoiceTotal - rowDetailsResponce[0].invoiceDiscount,
        },
      ];
      setNotes(getData.invoiceNotes);
      const details = {
        spaceBooked: getData.spaceBooked,
        BookingDate: getData.bookingDate,
        Duration: getData.duration,
        Name: getData.name,
        Mobile: getData.mobileNumber,
        Email: getData.email,
        Address: getData.address,
        Notes: getData.invoiceNotes,
      };
      console.log(details, "<<- Processed object details");
      setRows(rowDetails);
      setBookingDetails(details);
    } catch (error) {
      console.error("Error fetching invoice details:", error);
    }
    //     try {
    //       const responce = await axios.get(`${baseUrl}GetInvoiceHeaderAndDetailById?id=${Data.invoiceId}`);

    //       const getData = responce.data || {};
    //       console.log(getData, "<<- this responce view");
    // const details = getData.map((DataItem: any)=>{
    //   console.log(DataItem, "ayusgou")
    //   return {
    //     spaceBooked: DataItem["spaceBooked"],
    //     BookingDate: DataItem.bookingDate,
    //     Duration:DataItem.duration,
    //     Name : DataItem.name,
    //     Mobile: DataItem.mobileNumber,
    //     Email: DataItem.email,
    //     Address: DataItem.address

    //   };
    // });
    // console.log(details,"aiysgdiyl" )
    // setBookingDetails(details);

    //     } catch (error) {
    //       console.error("Error fetching invoices:", error);
    //     }
  };

  const handleChanges = (
    index: number,
    field: keyof Row,
    value: string | number
  ) => {
    const updatedRows = rows.map((row, i) => {
      // For the row at the specified index, we update the relevant fields
      if (i === index) {
        const updatedRow = {
          ...row,
          [field]: value,
          subTotal:
            field === "rent" || field === "discount"
              ? Math.max(
                  0,
                  (field === "rent" ? Number(value) : row.rent) -
                    (field === "discount" ? Number(value) : row.discount || 0)
                )
              : row.subTotal,
        };
        return updatedRow;
      }
      return row; // For other rows, return them unchanged
    });

    setRows(updatedRows);
  };
  const addRow = () => {
    setRows([
      ...rows,
      { type: "", description: "", rent: 0, discount: 0, subTotal: 0 },
    ]);
  };

  const removeRow = (index: number) => {
    const updatedRows = rows.filter((_, i) => i !== index);
    setRows(updatedRows);
  };

  return (
    <div className="overall-view ">
      <div className="overall-view ">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingRight: "20px",
          }}
        >
          <Breadcrumbs breadCrumbData={breadCrumbsData} />

          <div
            style={{ display: "flex", gap: "10px", alignItems: "center" }}
          ></div>
        </div>

        <div>
          <div className="form-row-create">
            <div className="form-group">
              <Label>User ID</Label>
              <DropDownList
                className="book-dropdown-new"
                data={bookingIDs}
                value={formData.bookingID}
                onChange={handleChange}
                name="bookingID"
              />
            </div>
            <div className="form-group" style={{ padding: "0px 10px 0px 0px" }}>
              <Label>Booking ID</Label>
              <DropDownList
                className="book-dropdown-new"
                data={bookingIDs}
                value={formData.bookingID}
                onChange={handleChange}
                name="bookingID"
              />
            </div>
            <div className="form-group"></div>
            <div className="form-group"></div>
          </div>

          <hr className="hr-border" />

          <div style={{ backgroundColor: "#eef3f2" }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <div
                className="booking-details"
                style={{ padding: "10px", width: "50%" }}
              >
                <h4>Booking Details</h4>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",

                    width: "100%",
                    padding: "20px",
                  }}
                >
                  <div className="form-row-create">
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div className="form-group">
                        <div>Space Booked</div>

                        <div style={{ fontWeight: "bold" }}>
                          {" "}
                          {BookingDetails?.spaceBooked}
                        </div>
                      </div>
                    </div>

                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div className="form-group">
                        <div>Booking Date</div>

                        <div style={{ fontWeight: "bold" }}>
                          {BookingDetails?.BookingDate === null
                            ? `${formatDateDMY(
                                `${BookingDetails?.BookingDate}`
                              )}`
                            : ""}
                        </div>
                      </div>
                    </div>

                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div className="form-group">
                        <div>Duration</div>

                        <div style={{ fontWeight: "bold" }}>
                          {BookingDetails?.Duration}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div
                  style={{
                    display: "flex",
                    justifyContent: "center",

                    width: "100%",
                    padding: "20px",
                  }}
                >
                  <div className="form-row-create">
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div className="form-group">
                        <div>Space Booked</div>

                        <div style={{ fontWeight: "bold" }}> Lounge Space</div>
                      </div>
                    </div>{" "}
                  </div>

                  <div className="form-row-create">
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div className="form-group">
                        <div>Booking Date</div>

                        <div style={{ fontWeight: "bold" }}> 28/08/2024</div>
                      </div>
                    </div>
                  </div>

                  <div className="form-row-create">
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div className="form-group">
                        <div>Duration</div>

                        <div style={{ fontWeight: "bold" }}>Monthly x 2</div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>

              <div
                style={{
                  width: "1px",
                  backgroundColor: "grey",
                  color: "black",
                }}
              >
                {"."}
              </div>

              <div
                className="booking-details"
                style={{ padding: "10px", width: "50%" }}
              >
                <h4>Booking Details</h4>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",

                    width: "100%",
                    padding: "20px",
                  }}
                >
                  <div className="form-row-create">
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div className="form-group">
                        <div>name</div>

                        <div style={{ fontWeight: "bold" }}>
                          {" "}
                          {BookingDetails?.Name}
                        </div>
                      </div>
                    </div>

                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div className="form-group">
                        <div>Mobile</div>

                        <div style={{ fontWeight: "bold" }}>
                          {BookingDetails?.Mobile}
                        </div>
                      </div>
                    </div>

                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div className="form-group">
                        <div>Email</div>

                        <div style={{ fontWeight: "bold" }}>
                          {BookingDetails?.Email}
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div className="form-group">
                        <div>Address</div>

                        <div style={{ fontWeight: "bold" }}>
                          {BookingDetails?.Address}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className="form-row-create">
              <div className="form-group" style={{ padding: "10px" }}>
                <Label>Status</Label>
                <DropDownList
                  data={bookingStatuses}
                  value={formData.status}
                  onChange={handleChange}
                  name="status"
                />
              </div>

              <div className="form-group" style={{ padding: "10px" }}>
                <Label>Invoice Date</Label>
                <DatePicker
                  value={formData.invoiceDate}
                  //   onChange={(e) => setFormData({ ...formData, invoiceDate: e.value })}
                  name="invoiceDate"
                />
              </div>
              <div className="form-group" style={{ padding: "10px" }}>
                <Label>Due Date</Label>
                <DatePicker
                  value={formData.dueDate}
                  //   onChange={(e) => setFormData({ ...formData, dueDate: e.value })}
                  name="dueDate"
                />
              </div>
              <hr className="hr-border" />
            </div>
          </div>
        </div>

        <div>
          <div
            style={{
              border: "1px solid black",
              borderRadius: 5,
              padding: "10px",
            }}
          >
            <table
              border={0}
              style={{ width: "100%", borderCollapse: "collapse" }}
            >
              <thead>
                <tr
                  style={{
                    borderBottom: "1px solid grey",
                  }}
                >
                  <th></th>
                  <th style={{ paddingBottom: "10px" }}>Type</th>
                  <th>Description</th>
                  <th>Rent (₹)</th>
                  <th>Discount (₹)</th>
                  <th>Sub Total (₹)</th>
                </tr>
              </thead>
              <div style={{ padding: "10px" }} />
              <tbody style={{ padding: "100px" }}>
                {rows.map((row, index) => (
                  <tr key={index}>
                    <td>
                      {/* <Button
                        onClick={() => removeRow(index)}
                        style={{ color: "red" }}
                      >
                        X
                      </Button> */}
                    </td>
                    <td style={{ paddingRight: "50px", paddingBottom: "10px" }}>
                      {row.type}
                      {/* <DropDownList
                        data={rowtype}
                        defaultValue={"hello"}
                        value={row.type}
                        onChange={(e) => handleChanges(index, "type", e.value)}
                        name="status"
                      /> */}
                    </td>
                    <td style={{ paddingBottom: "10px" }}>
                      <Input
                        type="text"
                        className="book-dropdown-new"
                        placeholder="Enter description"
                        value={row.description}
                        onChange={(e: any) =>
                          handleChanges(index, "description", e.value)
                        }
                      />
                    </td>
                    <td style={{ paddingBottom: "10px" }}>
                      <Input
                        type="number"
                        className="book-dropdown-new"
                        placeholder="Enter rent"
                        value={row.rent === 0 ? "" : row.rent}
                        onChange={(e: any) =>
                          handleChanges(index, "rent", parseInt(e.value))
                        }
                      />
                    </td>
                    <td style={{ paddingBottom: "10px" }}>
                      <Input
                        type="number"
                        className="book-dropdown-new"
                        placeholder="Enter discount"
                        value={row.discount === 0 ? "" : row.discount}
                        onChange={(e: any) =>
                          handleChanges(
                            index,
                            "discount",
                            parseInt(e.target.value)
                          )
                        }
                      />
                    </td>
                    <td style={{ paddingBottom: "10px" }}>
                      <Input
                        type="number"
                        className="book-dropdown-new"
                        value={row.subTotal === 0 ? "" : row.subTotal}
                        readOnly
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "20px",
            }}
          >
            {/* <Button
              style={{
                height: "70%",
                color: "green"
              }}
              onClick={addRow}
              icon="plus-outline"
            >
              Add New Row
            </Button> */}
          </div>
        </div>
        <hr className="hr-border" />
        <div className="form-row-create">
          <div className="form-group">
            <div style={{ marginTop: "20px" }}>
              <Label>Notes</Label>
              <TextArea
                value={notes}
                onChange={(e) => setNotes(e.value)}
                style={{ width: "100%", height: "80px", marginTop: "10px" }}
              />
            </div>
          </div>

          <div className="form-group"></div>

          <div className="form-group">
            <div style={{ marginTop: "20px" }}>
              <div style={{ marginTop: "20px", textAlign: "right" }}>
                <div
                  style={{
                    display: "flex",
                    margin: "15px 0px 15px 0px",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ color: "grey" }}>Sub Total :</div>
                  <div>
                    ₹{" "}
                    {rows
                      .reduce((sum, row) => sum + row.subTotal, 0)
                      .toFixed(2)}
                  </div>
                </div>

                <div className="border02"></div>

                <div
                  style={{
                    margin: "15px 0px 16px 0px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ color: "grey" }}> Discount :</div>
                  <div>
                    ₹{" "}
                    {rows
                      .reduce((sum, row) => sum + row.discount, 0)
                      .toFixed(2)}
                  </div>
                </div>
                <div className="border02"></div>

                <div
                  style={{
                    margin: "15px 0px 15px 0px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ color: "grey" }}>Tax (GST 18%) :</div>
                  <div>
                    ₹{" "}
                    {(
                      rows.reduce((sum, row) => sum + row.subTotal, 0) * taxRate
                    ).toFixed(2)}
                  </div>
                </div>
                <div className="border02"></div>

                <div
                  style={{
                    margin: "15px 0px 15px 0px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    fontWeight: "bold",
                    fontSize: "18px",
                  }}
                >
         
                  {/* <div>₹ {Math.round(total).toFixed(2)}</div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            onClick={handleSubmit}
            style={{
              marginRight: "10px",
              backgroundColor: "green",
              color: "white",
              padding: "10px 20px",
              border: "none",
            }}
          >
            Create Invoice
          </Button>
          <Button
            style={{
              backgroundColor: "orange",
              color: "white",
              padding: "10px 20px",
              border: "none",
            }}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};
