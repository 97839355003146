import { useEffect, useRef, useState } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input, TextArea } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { Label } from "@progress/kendo-react-labels";
import { Loader } from "@progress/kendo-react-indicators";
import { Dialog } from "@progress/kendo-react-dialogs";
import { commonBaseUrl } from "../../services/CommonUtil";
import Breadcrumbs from "../components/Breadcrumbs";
import { formatDateDMY } from "../components/commoncomponents";
import { MyDatePicker } from "../masters/MyDatePicker";

//interfaces
interface Row {
  type: string;
  description: string;
  rent: number;
  discount: number;
  discountPercentage: number;
  subTotal: number;
}
interface CustomerDetails {
  Name: string;
  Mobile: string;
  Email: string;
  Address: string;
  Notes: string;
}

interface BookingDetails {
  spaceBooked: string;
  BookingDate: string;
  Duration: string;
}

export const ViewPaymentDetails = () => {
  const baseUrl = commonBaseUrl();
  const [formData, setFormData] = useState({
    invoiceDate: null,
    dueDate: null,
  });

  const [errors, setErrors] = useState({
    invoiceDateError: "",
    dueDateError: "",
  });
  const timePickerRef = useRef<HTMLDivElement | null>(null);
  // endDate
  // const setTimeCalc = (value: any, name: string) => {
  //   const curDate = new Date();
  //   if (name === "end") {
  //     const newDueDate = value;
  //     endDateSet(value);
  //     // setFormData((prevData) => ({
  //     //   ...prevData,
  //     //   dueDate: newDueDate,
  //     // }));

  //     validateDates(startDate, endDate);
  //   } else {
  //     const newInvoiceDate = value;
  //     startDateSet(value);
  //     validateDates(startDate, endDate);
  //     endDateSet(undefined);
  //     // setFormData((prevData) => ({
  //     //   ...prevData,
  //     //   invoiceDate: newInvoiceDate,
  //     // }));
  //     validateDates(startDate, endDate);
  //   }

  // };
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [errorDate, setErrorDate] = useState<string | null>(null);
  const [errorstartDate, setErrorstartDate] = useState<string | null>(null);
  const validateDates = (start: Date | null, end: Date | null) => {
    if (start && end && end < start) {
      setErrorDate("End date cannot be before start date.");
      return false;
    }
    setErrorDate(null); // Clear error if dates are valid
    return true;
  };

  // Start date change handler
  const handleStartDateChange = (date: Date | null) => {
    setStartDate(date);
    validateDates(date, endDate); // Validate when start date is changed
  };

  // End date change handler
  const handleEndDateChange = (date: Date | null) => {
    setEndDate(date);
    validateDates(startDate, date); // Validate when end date is changed
  };
  const location = useLocation();
  const breadCrumbsData = [
    {
      id: "1",
      text: "Home",
      route: "",
    },
    {
      id: "2",
      text: "Dashboard",
      route: "/home/dashboard",
    },
    {
      id: "3",
      text: "View Payment",
      route: "",
    },
  ];
 
  const navigate = useNavigate();

  const customStyles = `
  .no-wheel input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* Hide spinner controls */
    pointer-events: none;
    user-select: none;
  }
  
  .no-wheel input::-webkit-inner-spin-button,
  .no-wheel input::-webkit-calendar-picker-indicator {
    display: none;
  }
  
  .no-wheel input::-moz-inner-spin-button {
    display: none;
  }
  `;

  // const [bookingId,bookingIdSet]  = useState([]);

  //enebler
  const [bookingIdEnabler, setBookingIdEnabler] = useState(false);
  const [detailsEnabler, setDetailsEnabler] = useState(false);

  const [users, setUsers] = useState([]);
  const [selectUser, setSelectUser] = useState({
    Id: 0,
    CustomerId: "Select Customer Id",
    Name: "Select User",
  });

  const [bookingId, bookingIdSet] = useState();
  const [selectBookingId, setSelectBookingId] = useState({
    Id: 0,
    BookingId: "Select Booking Id",
  });
  const [defaultBookingId, defaultBookingIdSet] = useState({
    Id: 0,
    BookingId: "Booking data is Empty",
  });

  const [notes, setNotes] = useState<string>("");
  const [total, setTotal] = useState<number>(0); // Example total with tax
  const [taxRate, setTaxRate] = useState<number>(0);
  const [taxRateCheck, setTaxRateCheck] = useState(false);
  // const taxRate = 0.18;
  const [rentAmount, setRentAmount] = useState(0);

  const [rows, setRows] = useState<Row[]>([]);
  const [customerDetails, setCustomerDetails] = useState<CustomerDetails>();
  const [BookingDetails, setBookingCustomerDetails] =
    useState<BookingDetails>();

  const [paymentStatusList, setPaymentStatusList] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState(undefined);
  const [taxC,taxCSet] = useState("");
  const [taxS,taxSSet] = useState("");
  const [taxI, taxISet] = useState("");
  const [taxCP,taxCPSet] = useState(0);
  const [taxSP,taxSPSet] = useState(0);
  const [taxIP, taxIPSet] = useState(0);
  useEffect(() => {
    if (location.state !== null) {
    } else {
      const subTotal = rows.reduce(
        // (sum, row) => Number(row.rent) - ( sum + (Number(row.rent) * Number(row.discount) /100)),
        (sum, row) => {
          console.log("the Row was -->", row);
          const total =  (Number(row.rent) * Number(row.discount) /100) || 0;
          console.log("the total was -->", total);
          const final = row.rent -total;
          console.log("the final was -->", final);
          return final;
        },0
          // Number(row.rent) - ( sum + (Number(row.rent) * Number(row.discount) /100)),
        // 0
      );

      console.log("The sub-total was -->", subTotal)
      const tempTaxCP = subTotal * taxCP/100;
      const tempTaxSP = subTotal * taxSP/100;
      const tempTaxIP = subTotal * taxIP/100;
      console.log("Sub-Total -->",subTotal);
      console.log("The three taxes were -->",tempTaxCP, tempTaxSP, tempTaxIP);
      console.log("The three taxes were -->",tempTaxCP, tempTaxSP, tempTaxIP);

      setTotal(subTotal + tempTaxCP +tempTaxSP + tempTaxIP);

    }
  }, [rows]);
  const [taxTypes, setTaxTypes] = useState([]);
  const [selectedTax, setSelectedTax] = useState();
  const [userId, setUserId] = useState();
  const [invoiceNo, invoiceNoSet] = useState("");
  const [deposit, setdeposit] = useState(0);
  const [invoiceId, setInvoiceId] = useState(0);
  const [view, setView] = useState(false);
  useEffect(() => {
    getPaymentStatus();
    console.log(location.state, "<<><><");
    if (location.state !== null) {
      setBookingIdEnabler(true);
      setDetailsEnabler(true);
      setView(location.state.view);
      setInvoiceId(location.state.invoiceId);
      setBookingDetails(location.state.bookingId, location.state.invoiceId);
      console.log(
        location.state.invoiceId,
        location.state.bookingId,
        "invoice<< "
      );
    } else {
      fetchUsers();
    }
  }, [taxTypes > [], setPaymentStatusList.length > 0, rows.length > 0]);

  const setBookingDetails = async (id: any, invoiceid?: any) => {
    try {
      const responseSpace = await axios.get(`${baseUrl}odata/Bookings/${id}`);
      console.log("Response status", responseSpace.data);
      const getDetails = responseSpace.data;
      setSelectBookingId(getDetails);
      // bookingIdSet(getDetails);
      try {
        // Fetch data from the API
        const responseDetails = await axios.get(
          `${baseUrl}GetInvoiceHeaderAndDetailById?id=${invoiceid}`
        );

        const getData = responseDetails.data || {};
        setStartDate(new Date(getData.invoiceDate));
        invoiceNoSet(getData.invoiceCode);
        console.log("Invoice code-->", getData.invoiceCode);
        setEndDate(getData.dueDate !== null ? new Date(getData.dueDate) : null);

        // endDateSet(getData.dueDate);

        console.log(getData, "<<- this response view");
        const details = {
          Name: getData.name === null ? "" : getData.name,
          Mobile: getData.mobileNumber === null ? "" : getData.mobileNumber,
          Email: getData.email === null ? "" : getData.email,
          Address: `${
            getData.address !== null && getData.address !== ""
              ? getData.address + ","
              : ""
          } `,
          Notes: "",
        };

        setLoader(false);

        setCustomerDetails(details);

        //   const detailsData = {
        //     Name: getData.name,
        //     Mobile: getData.Mobile === null ? "" : getData.Mobile,
        //     Email: getData.Email === null ? "" : getData.Email,
        //     Address: `${getData.Address !== null && getData.Address !== "" ? getData.Address + "," : ""} ${
        //   getData.Address2 !== null && getData.Address2.trim() !== "" ?  getData.Address2 + "," : ""
        // } ${getData.City !== null && getData.City !== "" ? getData.City + "," : ""} ${
        //   getData.Country !== null  && getData.Country !== ""?  getData.Country + "," : ""
        // }`,
        //     Notes: "",
        //   };

        //   //   console.log(details, "<<- Processed object details");
        //   setCustomerDetails(detailsData);
        const rowDetailsResponce = getData.invoiceDetails;
        try {
          const response = await axios.get(
            `${baseUrl}odata/GetAllTaxType?$filter=IsActive eq true`
          );
          const selectTaxs = taxTypes.find(
            (taxData: any) => taxData.id === rowDetailsResponce[0].taxId
          );
          try {
            const responsecgstigst = await axios.get(
              `${baseUrl}odata/GetCGSTSGSTDetails?taxId=${selectTaxs!["id"]}`
            );

            console.log(responsecgstigst.data, "TaxRate1");
            // const taxcget = responsecgstigst.data.cgstDetail!==null? responsecgstigst.data.cgstDetail.name:"";
            // const taxsget = responsecgstigst.data.sgstDetail!==null ?responsecgstigst.data.sgstDetail.name:"";
            // const taxsiget = responsecgstigst.data.igstDetail!==null ?responsecgstigst.data.igstDetail.name:"";
            // const taxcgetpercentage = responsecgstigst.data.cgstDetail!==null && responsecgstigst.data.cgstDetail.percentage;
            // const taxsgetpercentage = responsecgstigst.data.sgstDetail!==null && responsecgstigst.data.sgstDetail.percentage;
            // const taxsigetpercentage = responsecgstigst.data.igstDetail!==null && responsecgstigst.data.igstDetail.percentage;
            // console.log("The tax data were -->", taxcget, taxsget, taxsiget);
            // console.log("The tax percentage data from api were -->", responsecgstigst.data.cgstDetail.percentage, responsecgstigst.data.sgstDetail.percentage, responsecgstigst.data.igstDetail.percentage);
            // console.log("The tax percentage data were -->", taxcgetpercentage, taxsgetpercentage, taxsigetpercentage);
            // console.log("Done",responsecgstigst.data );
            // taxCSet(taxcget);
            // taxCPSet(taxcgetpercentage);
            // taxSSet(taxsget);
            // taxSPSet(taxsgetpercentage);
            // taxISet(taxsiget);
            // taxIPSet(taxsigetpercentage);

//part 2
            const taxcget = responsecgstigst.data.cgstDetail !== null ? responsecgstigst.data.cgstDetail.name : "";
            const taxsget = responsecgstigst.data.sgstDetail !== null ? responsecgstigst.data.sgstDetail.name : "";
            const taxsiget = responsecgstigst.data.igstDetail !== null ? responsecgstigst.data.igstDetail.name : "";
            const taxcgetpercentage = responsecgstigst.data.cgstDetail !== null ? responsecgstigst.data.cgstDetail.percentage : 0;
            const taxsgetpercentage = responsecgstigst.data.sgstDetail !== null ? responsecgstigst.data.sgstDetail.percentage : 0;
            const taxsigetpercentage = responsecgstigst.data.igstDetail !== null ? responsecgstigst.data.igstDetail.percentage : 0;
            console.log("The tax data were -->", taxcget, taxsget, taxsiget);
            console.log("The tax percentage data were -->", taxcgetpercentage, taxsgetpercentage, taxsigetpercentage);
            taxCSet(taxcget);
            taxCPSet(taxcgetpercentage);
            taxSSet(taxsget);
            taxSPSet(taxsgetpercentage);
            taxISet(taxsiget);
            taxIPSet(taxsigetpercentage);



            // const DataAdd =
            //   taxcget === null && taxcget === null
            //     ? taxsiget.percentage / 100
            //     : (taxcget.percentage + taxsget.percentage) / 100;
            // setTaxRate(DataAdd);
            // setTaxRateCheck(taxcget === null ? true : false);
          } catch (e) {}

          console.log(selectTaxs, "<<resTax");

          setTaxId(selectTaxs !== undefined ? selectTaxs["id"] : 0);
          setSelectedTax(selectTaxs);
          const TaxData =
            /// getPercentage??
            console.log(rowDetailsResponce[0], "2345678sdfghjk");

          setTaxTypes(response.data);
        } catch (error) {
          console.error("Error fetching tax types:", error);
        }
        setRentAmount(rowDetailsResponce[0].invoiceRent);
        console.log(
          rowDetailsResponce[0].invoiceDiscount,
          "suydgfuysudkfvkjvsdhgsdf"
        );
        setTotal(rowDetailsResponce[0].invoiceTotal);
        console.log();
        const rowDetails = [
          {
            type: rowDetailsResponce[0].invoiceType,
            description: rowDetailsResponce[0].description,
            rent: rowDetailsResponce[0].invoiceRent,
            discount: rowDetailsResponce[0].invoiceDiscount,
            discountPercentage: rowDetailsResponce[0].DiscountPercentage,
            subTotal: rowDetailsResponce[0].invoiceTotal,
          },
        ];

        setNotes(getData.invoiceNotes);
        // const details = {
        //   spaceBooked: getData.spaceBooked,
        //   BookingDate: getData.bookingDate,
        //   Duration: getData.duration,
        //   Name: getData.Name,
        //   Mobile: getData.mobileNumber,
        //   Email: getData.email,
        //   Address: getData.address,
        //   Notes: getData.invoiceNotes,
        // };
        // console.log(details, "<<- Processed object details");
        setRows(rowDetails);
        // setBookingDetails(details);

        // handleDiscountChange(0, rowDetailsResponce[0].invoiceDiscount)
      } catch (error) {
        console.error("Error fetching invoice details:", error);
      }

      setdeposit(getDetails.Deposit);

      try {
        const responseSpace = await axios.get(
          `${baseUrl}odata/SpaceHeaders(${getDetails.SpaceId})`
        );

        const responseDuration = await axios.get(
          `${baseUrl}odata/Durations/${getDetails.DurationId}`
        );
        console.log(responseDuration.data.value, "responseSpace");

        setBookingCustomerDetails({
          spaceBooked: responseSpace.data.SpaceTypeName,
          BookingDate: getDetails.BookingDatetime,
          Duration: responseDuration.data.value[0].DurationType,
        });

        setDetailsEnabler(true);
      } catch (error: any) {
        console.error("Error fetching user booking data:", error);
        alert(`${error.message}`);
      }
    } catch (error: any) {
      console.error("Error fetching user booking data:", error);
      // alert(`${error.message}`);
    }
  };

  const getPaymentStatus = async () => {
    try {
      const paymentResponce = await axios.get(`${baseUrl}odata/PaymentStatus`);
      const paymentStatus = paymentResponce.data.value || [];

      if (location.state !== null) {
        const pendingStatus = paymentStatus.filter(
          (status: any) => status.Description === "Paid"
        );
        setSelectedPayment(pendingStatus[0]);
      } else {
        const pendingStatus = paymentStatus.filter(
          (status: any) => status.Description === "Pending"
        );
        setSelectedPayment(pendingStatus[0]);
      }

      const paidStatus = paymentStatus.filter(
        (status: any) => status.Description === "Paid"
      );
      setPaidDetails(paidStatus[0].id);
      console.log(paidStatus[0].Id, "wertyhjksdfghjk");

      setPaymentStatusList(paymentStatus);

      console.log(paymentResponce.data.value, "Payment Invoice");
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get(
        `${baseUrl}odata/Users?$filter=(IsActive eq true)and(RoleId eq 2)`
      );
      const usersData = response.data.value || [];
      // getinvoiceData(usersData);
      console.log("The users were -->", usersData);
      setUsers(usersData);
      for (let i = 0; i < usersData.length; i++) {
        // if(usersData[i].CustomerId === null){
        //   usersData[i].CustomerId = "Empty";
        // }
        usersData[i].CustomerId =
          usersData[i].CustomerId !== null
            ? usersData[i].CustomerId
            : "Id Null";
        // console.log("Customer Id", usersData[i].CustomerId);
      }
      // setUserId()
      console.log(usersData, "<user");
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };
  //While select the user id

  const fetchUserBooking = async (Id: any) => {
    console.log("Id from user -->", Id);
    try {
      const response = await axios.get(
        `${baseUrl}odata/Bookings?$filter=(IsActive eq true)and(UserId eq ${Id})and(BookingStatus ne 3)`
      );
      const bookingData = response.data.value || [];
      console.log("The booking data were -->", bookingData.reverse());
      // getinvoiceData(usersData);
      // setUsers(usersData);
      setInvoiceId(bookingData[0].Id);
      bookingIdSet(bookingData.reverse());
    } catch (error) {
      console.error("Error fetching user booking data:", error);
    }
  };
  const [discountEnabler, setDiscountEnabler] = useState(0);
  useEffect(() => {}, [discountEnabler]);

  const getBookingDetails = async (event: any) => {
    setSelectBookingId(event.value);
    console.log("The value -->",event.value);
    setdeposit(event.value.Deposit);
    const DiscountValue = event.value.DiscountValue;
    console.log(DiscountValue, "sdfghjklkjhgfdxscvbnjkytfrdxcvbhjytfdc");
    if (DiscountValue > 0) {
      setDiscountEnabler(DiscountValue);
    } else {
      console.log(DiscountValue, "noWay");
      setDiscountEnabler(DiscountValue);
    }
    try {
      const response = await axios.get(
        `${baseUrl}odata/GetAllTaxType?$filter=IsActive%20eq%20true`
      );
      console.log(`${baseUrl}odata/GetAllTaxType?$filter=IsActive%20eq%20true`,"Response -->", response.data);
      const selectTaxs = response.data.find(
        (taxData: any) => taxData.id === event.value.TaxId
      );
      setSelectedTax(selectTaxs);
      console.log("The selected tax is -->",selectTaxs);
      const responsecgstigst = await axios.get(
        `${baseUrl}odata/GetCGSTSGSTDetails?taxId=${event.value.TaxId}`
      );
      console.log(responsecgstigst.data, "TaxRate2");
      // const taxcget = responsecgstigst.data.cgstDetail;
      // const taxsget = responsecgstigst.data.sgstDetail;
      // const taxsiget = responsecgstigst.data.igstDetail;

      const taxcget = responsecgstigst.data.cgstDetail!==null? responsecgstigst.data.cgstDetail.name:"";
      const taxsget = responsecgstigst.data.sgstDetail!==null ?responsecgstigst.data.sgstDetail.name:"";
      const taxsiget = responsecgstigst.data.igstDetail!==null ?responsecgstigst.data.igstDetail.name:"";
      const taxcgetpercentage = responsecgstigst.data.cgstDetail!==null ? responsecgstigst.data.cgstDetail.percentage:0;
      const taxsgetpercentage = responsecgstigst.data.sgstDetail!==null ? responsecgstigst.data.sgstDetail.percentage:0;
      const taxsigetpercentage = responsecgstigst.data.igstDetail!==null ? responsecgstigst.data.igstDetail.percentage:0;
      console.log("The tax data were -->", taxcget, taxsget, taxsiget);
      console.log("The tax percentage data were -->", taxcgetpercentage, taxsgetpercentage, taxsigetpercentage);
      taxCSet(taxcget);
      taxCPSet(taxcgetpercentage);
      taxSSet(taxsget);
      taxSPSet(taxsgetpercentage);
      taxISet(taxsiget);
      taxIPSet(taxsigetpercentage);



      // console.log("Data were cget-->", taxcget);
      // console.log("Data were sget -->", taxsget);
      // console.log("Data were iget -->", taxsiget.percentage);
      // const DataAdd =
      //   taxcget === null
      //     ? taxsiget.percentage / 100
      //     : (taxcget.percentage + taxsget.percentage) / 100;

      // console.log("Data add -->", DataAdd);
      // setTaxRate(DataAdd);
      setTaxRateCheck(taxcget === null ? true : false);
      setRentAmount(parseInt(event.value.SpaceRent));
      console.log("The space rent was -->",taxcget === null,event.value.SpaceRent)
      const rowDetails = [
        {
          type: "Space Rent",
          description: "",
          rent: event.value.SpaceRent,
          discount: DiscountValue,
          discountPercentage: event.value.DiscountPercentage,
          // taxRate: selectTaxs,
          subTotal: event.value.SpaceRent,
        },
      ];

      setRows(rowDetails);
    } catch (e) {}
    getSpaceId(event.value.SpaceId);

    try {
      const responseSpace = await axios.get(
        `${baseUrl}odata/SpaceHeaders(${event.value.SpaceId})`
      );

      const responseDuration = await axios.get(
        `${baseUrl}odata/Durations/${event.value.DurationId}`
      );
      console.log(responseDuration.data.value, "responseSpace");
      if (responseDuration.data.value.length === 0) {
        // Alert(responseDuration.data.value);
      }

      setBookingCustomerDetails({
        spaceBooked: responseSpace.data.SpaceTypeName,
        BookingDate: event.value.BookingDatetime,
        Duration: responseDuration.data.value[0].DurationType,
      });
      setDurationType(responseDuration.data.value[0].DurationType);
      setDetailsEnabler(true);
    } catch (error) {
      console.error("Error fetching user booking data:", error);
    }
  };

  const getinvoiceData = async (userData: any) => {
    console.log(userData, "<userData");

    const details = {
      Name: userData.Name === null ? "" : userData.Name,
      Mobile: userData.Mobile === null ? "" : userData.Mobile,
      Email: userData.Email === null ? "" : userData.Email,
      Address: `${
        userData.Address !== null && userData.Address !== ""
          ? userData.Address + ","
          : ""
      } ${
        userData.Address2 !== null && userData.Address2.trim() !== ""
          ? userData.Address2 + ","
          : ""
      } ${
        userData.City !== null && userData.City !== ""
          ? userData.City + ","
          : ""
      } ${
        userData.Country !== null && userData.Country !== ""
          ? userData.Country + ","
          : ""
      }`,
      Notes: "",
    };

    setLoader(false);

    setCustomerDetails(details);
  };

  const handleChanges = (
    index: number,
    field: keyof Row,
    value: string | number
  ) => {
    const updatedRows = rows.map((row, i) => {
      // For the row at the specified index, we update the relevant fields
      if (i === index) {
        const updatedRow = {
          ...row,
          [field]: value,
          subTotal:
            field === "rent" || field === "discount"
              ? Math.max(
                  0,
                  (field === "rent"
                    ? Number(value || "0") || 0
                    : row.rent || 0) - // Ensure rent defaults to 0
                    (field === "discount"
                      ? Number(value || "0") || 0
                      : row.discount || 0) // Ensure discount defaults to 0
                )
              : row.subTotal,
        };
        return updatedRow;
      }
      return row; // For other rows, return them unchanged
    });

    setRows(updatedRows);
  };
  const [error, setError] = useState("");
  const [errorDescription, setErrorDescription] = useState("");
  const [notesError, setNotesError] = useState("");

  const handleDiscountChange = (index: any, e: any) => {
    const Values =
      e.target.value === undefined || e.target.value === ""
        ? "0"
        : e.target.value;
    const value = parseInt(Values);
    console.log(value, "discount change");

    // Validation check: Ensure the discount is >= 0
    if (value < 0) {
      setError("Discount cannot be less than 0"); // Set error message
    } else {
      setError(""); // Clear the error message
      handleChanges(index, "discount", value); // Update the state with the valid discount value
    }
  };

  //createapi
  const handleSubmit = () => {
    let valid = true;
    if (!startDate) {
      setErrorstartDate("Please select an invoice date.");
      valid = false;
    } else {
      setErrorstartDate("");
    }

    if (!endDate) {
      setErrorDate("Please select a due date.");
      valid = false;
    } else if (new Date(endDate) < new Date(startDate!)) {
      setErrorDate("Due date cannot be earlier than the invoice date.");
      valid = false;
    } else {
      setErrorDate("");
    }

    rows.forEach((row, index) => {
      if (row.discount < 0) {
        setError("Discount cannot be less than 0");
        valid = false;
      } else {
        setError("");
      }

      if (row.description.trim().length <= 0) {
        setErrorDescription("Fill Description");
        valid = false;
      } else {
        setErrorDescription("");
      }
    });

    if (notes.trim().length <= 0) {
      setNotesError("Fill notes");
      valid = false;
    } else {
      setNotesError("");
    }

    if (valid === true) {
      createInvoice();
      console.log("Invoice Created:", { rows, notes, total });
    }
  };
  const [paidStatusDeils, setPaidDetails] = useState();
  const [spaceId, getSpaceId] = useState();
  const [durationtype, setDurationType] = useState("");
  const [taxIds, setTaxId] = useState(0);

  const createInvoice = () => {
    console.log("The Selected Value is -->", selectUser);

    setLoader(true);
    // console.log(selectBookingId.Id, "cssCkehhsd");

    // {location.state !== null
    //   ? (
    //       rows.reduce((sum, row) => {
    //         const subTotal =
    //           Number(row.rent - row.discount) || 0; // Ensure subTotal is a number, defaulting to 0 if invalid
    //         console.log("Tax", sum, subTotal);
    //         return subTotal / 2;
    //       }, 0) * (Number(taxRate) || 0)
    //     ).toFixed(2)
    //   : (
    //       rows.reduce((sum, row) => {
    //         const subTotal =
    //           Number(rentAmount - row.discount) ||
    //           0; // Ensure subTotal is a number, defaulting to 0 if invalid
    //         return sum + subTotal / 2;
    //       }, 0) * (Number(taxRate) || 0)
    //     ).toFixed(2)}

    const Variable =
      rows.reduce((sum, row) => {
        const subTotal = Number(rentAmount - row.discount); // Ensure subTotal is a number, defaulting to 0 if invalid
        return sum + subTotal / 2;
      }, 0) * (Number(taxRate) || 0);
    console.log(Variable, "final tax");

    const rowValue = rows[0];
    const { subTotal } = rowValue;
    const tempTaxCP = subTotal * (taxCP / 100);
    const tempTaxSP = subTotal * (taxSP / 100);
    const tempTaxIP = subTotal * (taxIP / 100);

    const setdata = {
      bookingId: selectBookingId.Id,
      statusId: paidStatusDeils,
      invoiceDate: startDate,
      dueDate: endDate,
      spaceBooked: spaceId,
      bookingDate: BookingDetails?.BookingDate,
      duration: durationtype,
      invoiceNotes: notes,
      isActive: true,
      isDelete: true,
      createdBy: 1,
      createdOn: new Date(),
      modifiedBy: 1,
      modifiedOn: new Date(),
      userId: selectUser.Id,
      invoiceDetails: [
        {
          invoiceType: rows[0].type,
          description: rows[0].description,
          invoiceRent: rows[0].rent,
          invoiceDiscount: rows[0].discount,
          invoiceSubtotal: roundOffTotal(rows[0].subTotal),
          invoiceTotal: roundOffTotal(total),
          isActive: true,
          isDelete: true,
          createdBy: 1,
          createdOn: new Date(),
          modifiedBy: 1,
          modifiedOn: new Date(),
          invoiceBalance: 0,
          taxId: selectedTax!["id"],
          invoiceCgstAmount:
            taxRateCheck === false ? parseFloat(tempTaxCP.toFixed(2)) : 0,
          invoiceSgstAmount:
            taxRateCheck === false ? parseFloat(tempTaxSP.toFixed(2)) : 0,
          invoiceIgstAmount:
            taxRateCheck === false ? parseFloat(tempTaxIP.toFixed(2)) : 0,
        },
      ],
    };
    createInvoiceAPi(setdata);
    console.log("final data >>", setdata);
  };

  const createInvoiceAPi = async (setdata: any) => {
    try {
      // Fetch data from the API
      const responseDetails = await axios.post(
        `${baseUrl}odata/InvoiceHeaders`,
        setdata
      );

      console.log(responseDetails, "status update");
      setLoader(false);
      // if (responseDetails.status === 500) {

      //   setLoader(false);
      //   alertdilogSet(`${responseDetails.status}`);
      // } else {
      setDilogEnable(true);
      const details = responseDetails.data.message;
      alertdilogSet(`${details}`);
      // }
    } catch (e: any) {
      if (e.status === 500) {
        setLoader(false);
        setDilogEnable(true);
        alertdilogSet(`${e.response.data.detail}`);
      } else {
        // Handle other possible errors
        // alert(`Error: ${responseDetails!.detail}`);
      }
      console.log(e.response.data.detail, "aydvuiatosdaliusd");
    }
  };
  const [loader, setLoader] = useState(false);
  const [alertdilog, alertdilogSet] = useState("");
  const [dilogEnable, setDilogEnable] = useState(false);

  const roundOffTotal = (total: number) => {
    const decimalPart = total % 1;
    console.log(decimalPart, total, "1234567890");
    if (decimalPart >= 0.5) {
      // console.log(Math.ceil(total),"1234567890");
      return Math.ceil(total);
    } else {
      // console.log(Math.floor(total), "1234567890");
      return Math.floor(total);
    }
  };
  const [username, setUserName] = useState("");
  const [usernameenabler, setUserNameEnabler] = useState(false);

  const handleCancel = () => {
    navigate(-1);
    window.scrollTo(0, 0);
  };

  return (
    <div className="overall-view ">
      {loader === true ? (
        <div className="loader">
          <Loader size="large" type={"converging-spinner"} />
        </div>
      ) : (
        <div>
          {" "}
          {dilogEnable === true ? (
            <Dialog
              // height={"60%"}
              // onClose={() => setVisibleDialog(false)}
              className="dialog-containers"
            >
              <div>
                <div
                  style={{
                    height: "100%",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                  className="dialog-content"
                >
                  <h1>{alertdilog}</h1>
                  <Button
                    onClick={() => {
                      navigate("/management/listbooking/invoice");
                    }}
                    style={{
                      // marginRight: "20px",
                      backgroundColor: "#42B677",
                      padding: "10px",
                      color: "#fff",
                      paddingRight: "20px",
                      paddingLeft: "20px",
                    }}
                  >
                    {/* <Button primary={true} onClick={handleView} onClickCapture={handleView}> */}
                    OK
                  </Button>
                </div>
              </div>
            </Dialog>
          ) : (
            <div className="overall-view ">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingRight: "20px",
                }}
              >
                <Breadcrumbs breadCrumbData={breadCrumbsData} />

                <div
                  style={{ display: "flex", gap: "10px", alignItems: "center" }}
                ></div>
              </div>

              <div>
                <div className="form-row-create">
                  {view === false ? (
                    <div className="form-group">
                      <Label>Customer Id</Label>
                      <DropDownList
                        className="book-dropdown-new"
                        data={users}
                        disabled={view}
                        textField="CustomerId"
                        value={selectUser}
                        onChange={(event) => {
                          console.log(event.value, "Event value");
                          setUserNameEnabler(true);
                          bookingIdSet(undefined);
                          setSelectBookingId({
                            Id: 0,
                            BookingId: "Select Booking Id",
                          });
                          getinvoiceData(event.value);
                          setSelectUser(event.value);
                          setUserName(event.value.Name);
                          fetchUserBooking(event.value.Id);
                          setBookingIdEnabler(true);
                          setDetailsEnabler(false);
                        }}
                        name="Name"
                      />
                    </div>
                  ) : (
                    <div></div>
                  )}
                  {invoiceNo !== "" && (
                    <div className="form-group">
                      <div>
                        <Label>Invoice No</Label>
                        <Input
                          type="text"
                          className="book-dropdown-new"
                          placeholder=""
                          disabled={true}
                          value={invoiceNo}
                        />
                      </div>
                    </div>
                  )}
                  {usernameenabler == true ? (
                    <div>
                      <Label>Customer Name</Label>
                      <Input
                        type="text"
                        className="book-dropdown-new"
                        placeholder=""
                        disabled={true}
                        value={username}
                      />
                    </div>
                  ) : (
                    <div></div>
                  )}
                  {bookingIdEnabler === true ? (
                    <div
                      className="form-group"
                      style={{ padding: "0px 10px 0px 0px" }}
                    >
                      <Label>Booking ID</Label>
                      <DropDownList
                        className="book-dropdown-new"
                        data={bookingId}
                        disabled={view || bookingId === undefined}
                        textField="BookingId"
                        value={
                          bookingId === undefined && selectBookingId.Id === 0
                            ? defaultBookingId
                            : selectBookingId
                        }
                        onChange={(event) => getBookingDetails(event)}
                        name="bookingID"
                      />
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <div className="form-group"></div>
                </div>
                {detailsEnabler === true ? (
                  <div>
                    <hr className="hr-border" />

                    <div style={{ backgroundColor: "#eef3f2" }}>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        <div
                          className="booking-details"
                          style={{ padding: "10px", width: "50%" }}
                        >
                          <h4>Booking Details</h4>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",

                              width: "100%",
                              padding: "20px",
                            }}
                          >
                            <div className="form-row-create">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <div className="form-group">
                                  <div>Space Booked</div>

                                  <div style={{ fontWeight: "bold" }}>
                                    {" "}
                                    {BookingDetails?.spaceBooked}
                                  </div>
                                </div>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <div className="form-group">
                                  <div>Booking Date</div>

                                  <div style={{ fontWeight: "bold" }}>
                                    {BookingDetails?.BookingDate !== null
                                      ? `${formatDateDMY(
                                          `${BookingDetails?.BookingDate}`
                                        )}`
                                      : ""}
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <div className="form-group">
                                  <div>Duration</div>

                                  <div style={{ fontWeight: "bold" }}>
                                    {BookingDetails?.Duration}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            width: "1px",
                            backgroundColor: "grey",
                            color: "black",
                          }}
                        >
                          {""}
                        </div>
                        <div
                          className="booking-details"
                          style={{ padding: "10px", width: "50%" }}
                        >
                          <h4>Customer Details</h4>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",

                              width: "100%",
                              padding: "20px",
                            }}
                          >
                            <div className="form-row-create">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <div className="form-group">
                                  <div>Name</div>

                                  <div style={{ fontWeight: "bold" }}>
                                    {" "}
                                    {customerDetails?.Name}
                                  </div>
                                </div>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <div className="form-group">
                                  <div>Mobile</div>

                                  <div style={{ fontWeight: "bold" }}>
                                    {customerDetails?.Mobile}
                                  </div>
                                </div>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <div className="form-group">
                                  <div>Email</div>

                                  <div style={{ fontWeight: "bold" }}>
                                    {customerDetails?.Email === null
                                      ? ""
                                      : customerDetails?.Email}
                                  </div>
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <div className="form-group">
                                  <div>Address</div>

                                  <div style={{ fontWeight: "bold" }}>
                                    {customerDetails?.Address}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="form-row-create">
                        <div className="form-group" style={{ padding: "10px" }}>
                          <Label>Status</Label>
                          <DropDownList
                            data={paymentStatusList}
                            textField="Description"
                            value={selectedPayment || undefined}
                            disabled
                            onChange={(e) => {
                              setSelectedPayment(e.value);
                              console.log(
                                e.value,
                                "ayitwdeiqkwfdiqbadrafsdvyawdiyakw"
                              );
                              // setSelectedPayment(e)
                            }}
                            // name="status"
                            // placeholder="Select Status"
                          />
                        </div>
                        <div className="form-group" style={{ padding: "10px" }}>
                          <Label>
                            Invoice Date <span style={{ color: "red" }}>*</span>
                          </Label>
                          <style>{customStyles}</style>
                          <div className="no-wheel" style={{ width: "80%" }}>
                            <MyDatePicker
                              minDate={new Date()} // Start date can't be in the past
                              checkInDate={startDate!}
                              isDisableCheck={view}
                              customFormatter={false}
                              setCheckInDate={(e: any) => {
                                handleStartDateChange(e);
                              }}
                            />
                          </div>

                          {errorstartDate && (
                            <p style={{ color: "red" }}>{errorstartDate}</p>
                          )}
                        </div>
                        <div className="form-group" style={{ padding: "10px" }}>
                          <Label>
                            Due Date <span style={{ color: "red" }}>*</span>
                          </Label>
                          <style>{customStyles}</style>
                          <div className="no-wheel" style={{ width: "80%" }}>
                            <MyDatePicker
                              minDate={startDate || new Date()} // End date must be >= start date
                              checkInDate={endDate!}
                              isDisableCheck={view}
                              customFormatter={false}
                              setCheckInDate={(e: any) => {
                                handleEndDateChange(e);
                              }}
                            />
                          </div>

                          {errorDate && (
                            <p style={{ color: "red" }}>{errorDate}</p>
                          )}
                        </div>

                        <hr className="hr-border" />
                      </div>
                    </div>
                    <div>
                      <div
                        style={{
                          border: "1px solid black",
                          borderRadius: 5,
                          padding: "10px",
                        }}
                      >
                        <table
                          border={0}
                          style={{ width: "100%", borderCollapse: "collapse" }}
                        >
                          <thead>
                            <tr
                              style={{
                                borderBottom: "1px solid grey",
                              }}
                            >
                              <th></th>
                              <th style={{ paddingBottom: "10px" }}>Type</th>
                              <th>
                                Description{" "}
                                <span style={{ color: "red" }}>*</span>
                              </th>
                              <th>Rent (₹)</th>
                              <th>Discount (%)</th>
                              <th>Tax (₹)</th>
                              {/* <th>Sub Total (₹)</th> */}
                            </tr>
                          </thead>
                          <div style={{ padding: "10px" }} />
                          <tbody style={{ padding: "100px" }}>
                            {rows.map((row, index) => (
                              <tr key={index}>
                                <td></td>
                                <td
                                  style={{
                                    paddingRight: "50px",
                                    paddingBottom: "10px",
                                  }}
                                >
                                  {row.type}
                                </td>
                                <td style={{ paddingBottom: "10px" }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <TextArea
                                      autoSize={true}
                                      maxLength={50}
                                      // type="text"
                                      className="book-dropdown-new"
                                      placeholder="Enter description"
                                      disabled={view}
                                      value={row.description}
                                      onChange={(e: any) =>
                                        handleChanges(
                                          index,
                                          "description",
                                          e.value
                                        )
                                      }
                                    />
                                    {errorDescription && (
                                      <span style={{ color: "red" }}>
                                        {errorDescription}
                                      </span>
                                    )}{" "}
                                  </div>
                                </td>
                                <td style={{ paddingBottom: "10px" }}>
                                  <Input
                                    type="number"
                                    className="book-dropdown-new"
                                    placeholder="Enter rent"
                                    disabled
                                    value={row.rent === 0 ? "" : row.rent}
                                    onChange={(e: any) =>
                                      handleChanges(
                                        index,
                                        "rent",
                                        parseInt(e.value)
                                      )
                                    }
                                  />
                                </td>
                                <td style={{ paddingBottom: "10px" }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    {discountEnabler > 0 ? (
                                      <Input
                                        type="number"
                                        className="book-dropdown-new"
                                        placeholder="Enter discount"
                                        // min={0}
                                        max={row.rent}
                                        disabled={true}
                                        value={row.discount}
                                        onChange={(e: any) => {
                                          console.log(
                                            row.rent,
                                            "asdfkuhavjsdhsa"
                                          );
                                          if (100 >= e.value) {
                                            handleDiscountChange(index, e);
                                          }
                                        }}
                                      />
                                    ) : (
                                      <Input
                                        type="number"
                                        className="book-dropdown-new"
                                        placeholder="Enter discount"
                                        // min={0}
                                        max={row.rent}
                                        disabled={view}
                                        value={row.discount}
                                        onChange={(e: any) => {
                                          if (100>= e.value) {
                                            handleDiscountChange(index, e);
                                          }
                                        }}
                                      />
                                    )}
                                    {error && (
                                      <span style={{ color: "red" }}>
                                        {error}
                                      </span>
                                    )}{" "}
                                    {/* Show error message */}
                                  </div>
                                </td>
                                <td style={{ paddingBottom: "10px" }}>
                                  <DropDownList
                                    data={taxTypes}
                                    textField="name"
                                    disabled
                                    value={
                                      selectedTax || {
                                        name: "Select Tax",
                                        id: 0,
                                      }
                                    }
                                    // value={selectedTax}
                                    onChange={(e) => {
                                      console.log(e.value, "<< TaxType");
                                      // setSelectedTaxType(e.value);
                                      // handleChange(index, "tax", e.value);
                                    }}
                                    // defaultItem={{ name: "Select Tax", id: 0 }} // Default item
                                  />
                                </td>
                                {/* <td style={{ paddingBottom: "10px" }}>
                            <Input
                              type="number"
                              disabled
                              className="book-dropdown-new"
                              value={
                                row.subTotal >= 0 ? row.rent : row.subTotal
                              }
                              readOnly
                            />
                          </td> */}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          padding: "20px",
                        }}
                      ></div>
                    </div>

                    <hr className="hr-border" />
                    {/* Calculation part */}
                    <div className="form-row-create">
                      <div className="form-group">
                        <div style={{ marginTop: "20px" }}>
                          <Label>
                            Notes <span style={{ color: "red" }}>*</span>
                          </Label>

                          <TextArea
                            placeholder="Enter your comments here"
                            value={notes}
                            disabled={view}
                            onChange={(e) => setNotes(e.value)}
                            style={{
                              width: "100%",
                              height: "80px",
                              marginTop: "10px",
                            }}
                          />
                          {notesError && (
                            <p style={{ color: "red" }}>{notesError}</p>
                          )}
                        </div>
                      </div>

                      <div className="form-group"></div>

                      <div className="form-group">
                        <div style={{ marginTop: "20px" }}>
                          <div
                            style={{ marginTop: "20px", textAlign: "right" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                margin: "15px 0px 15px 0px",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <div style={{ color: "grey" }}>Deposit :</div>
                              <div>₹ {deposit.toFixed(2)}</div>
                            </div>

                            <div className="border02"></div>
                            <div
                              style={{
                                display: "flex",
                                margin: "15px 0px 15px 0px",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <div style={{ color: "grey" }}>Sub Total :</div>
                              <div>
                                ₹{" "}
                                {
                                  rentAmount.toFixed(2)
                                  // rows
                                  //   .reduce((sum, row) => {
                                  //     const subTotal = Number(row.subTotal) || 0; // Ensure subTotal is a number, defaulting to 0 if invalid
                                  //     return sum + subTotal;
                                  //   }, 0)
                                  //   .toFixed(2)
                                }
                              </div>
                            </div>

                            <div className="border02"></div>

                            <div
                              style={{
                                margin: "15px 0px 16px 0px",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <div style={{ color: "grey" }}>
                                {" "}
                                Discount({rows
                                  .reduce((sum, row) => {
                                    const discount = Number(row.discount) || 0; // Ensure discount is a number, defaulting to 0 if invalid
                                    return sum + discount;
                                  }, 0)
                                  .toFixed(0)}%)  :{" "}
                                {rows[0] === undefined ||
                                rows[0].discountPercentage === 0 ||
                                rows[0].discountPercentage === undefined
                                  ? ""
                                  : `(${rows[0].discountPercentage}%)`}
                              </div>
                              <div>
                                ₹{" "}
                                {rows
                                  .reduce((sum, row) => {
                                    const discount = rentAmount * Number(row.discount) /100 || 0; // Ensure discount is a number, defaulting to 0 if invalid
                                    return sum + discount;
                                  }, 0)
                                  .toFixed(2)}
                              </div>
                            </div>
                            <div className="border02"></div>
                            {taxC!=="" &&<div
                                  style={{
                                    margin: "15px 0px 15px 0px",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div style={{ color: "grey" }}>
                                    Tax ({taxC}) :
                                  </div>
                                  <div>
                                    ₹{" "}
                                    {location.state !== null
                                      ? (
                                          rows.reduce((sum, row) => {
                                            const total =  (Number(row.rent) * Number(row.discount) /100) || 0;
                                            const subTotal =
                                              Number(row.rent - total) ||
                                              0; // Ensure subTotal is a number, defaulting to 0 if invalid
                                            return subTotal / 100;
                                          }, 0) * (Number(taxCP) || 0)
                                        ).toFixed(2)
                                      : (
                                          rows.reduce((sum, row) => {
                                            const total =  (Number(row.rent) * Number(row.discount) /100) || 0;
                                            console.log("The sum, row, rentamount, discount, percentage was -->", sum, row, rentAmount, row.discount, taxCP);
                                            const subTotal =
                                              Number(
                                                rentAmount - total
                                              ) || 0; // Ensure subTotal is a number, defaulting to 0 if invalid
                                            return subTotal / 100;
                                          }, 0) * (Number(taxCP) || 0)
                                        ).toFixed(2)}
                                  </div>
                                </div> }
                                <div className="border02"></div>
                            {taxS!==""&& <div
                                  style={{
                                    margin: "15px 0px 15px 0px",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div style={{ color: "grey" }}>
                                    Tax ({taxS}) :
                                  </div>
                                  <div>
                                    ₹{" "}
                                    {location.state !== null
                                      ? (
                                          rows.reduce((sum, row) => {
                                            const total =  (Number(row.rent) * Number(row.discount) /100) || 0;
                                            const subTotal =
                                              Number(row.rent - total) ||
                                              0; // Ensure subTotal is a number, defaulting to 0 if invalid
                                            return subTotal / 100;
                                          }, 0) * (Number(taxSP) || 0)
                                        ).toFixed(2)
                                      : (
                                          rows.reduce((sum, row) => {
                                            const total =  (Number(row.rent) * Number(row.discount) /100) || 0;
                                            const subTotal =
                                              Number(
                                                rentAmount - total
                                              ) || 0; // Ensure subTotal is a number, defaulting to 0 if invalid
                                            return sum + subTotal / 100;
                                          }, 0) * (Number(taxSP) || 0)
                                        ).toFixed(2)}
                                  </div>
                                </div>}
                                <div className="border02"></div>
                            {taxI !==""&& <div
                                  style={{
                                    margin: "15px 0px 15px 0px",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div style={{ color: "grey" }}>
                                    Tax ({taxI} ) :
                                  </div>
                                  <div>
                                    ₹{" "}
                                    {location.state !== null
                                      ? (
                                          rows.reduce((sum, row) => {
                                            const total =  (Number(row.rent) * Number(row.discount) /100) || 0;
                                            const subTotal =
                                              Number(row.rent -total) ||
                                              0; // Ensure subTotal is a number, defaulting to 0 if invalid
                                            return subTotal / 100;
                                          }, 0) * (Number(taxIP) || 0)
                                        ).toFixed(2)
                                      : (
                                          rows.reduce((sum, row) => {
                                            const total =  (Number(row.rent) * Number(row.discount) /100) || 0;
                                            const subTotal =
                                              Number(
                                                rentAmount - total
                                              ) || 0; // Ensure subTotal is a number, defaulting to 0 if invalid
                                            return sum + subTotal / 100;
                                          }, 0) * (Number(taxIP) || 0)
                                        ).toFixed(2)}
                                  </div>
                                </div>}
                            <div className="border02"></div>
                            {/* {taxRateCheck === false ? (
                              <div>
                                <div
                                  style={{
                                    margin: "15px 0px 15px 0px",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div style={{ color: "grey" }}>
                                    Tax (CGST {((taxRate / 2) * 100).toFixed(2)}
                                    %) :
                                  </div>
                                  <div>
                                    ₹{" "}
                                    {location.state !== null
                                      ? (
                                          rows.reduce((sum, row) => {
                                            const subTotal =
                                              Number(row.rent - row.discount) ||
                                              0; // Ensure subTotal is a number, defaulting to 0 if invalid
                                            return subTotal / 2;
                                          }, 0) * (Number(taxRate) || 0)
                                        ).toFixed(2)
                                      : (
                                          rows.reduce((sum, row) => {
                                            const subTotal =
                                              Number(
                                                rentAmount - row.discount
                                              ) || 0; // Ensure subTotal is a number, defaulting to 0 if invalid
                                            return sum + subTotal / 2;
                                          }, 0) * (Number(taxRate) || 0)
                                        ).toFixed(2)}
                                  </div>
                                </div>
                                <div className="border02"></div>
                                <div
                                  style={{
                                    margin: "15px 0px 15px 0px",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div style={{ color: "grey" }}>
                                    Tax (SGST {((taxRate / 2) * 100).toFixed(2)}
                                    %) :
                                  </div>
                                  <div>
                                    ₹{" "}
                                    {location.state !== null
                                      ? (
                                          rows.reduce((sum, row) => {
                                            const subTotal =
                                              Number(row.rent - row.discount) ||
                                              0; // Ensure subTotal is a number, defaulting to 0 if invalid
                                            console.log("Tax", sum, subTotal);
                                            return subTotal / 2;
                                          }, 0) * (Number(taxRate) || 0)
                                        ).toFixed(2)
                                      : (
                                          rows.reduce((sum, row) => {
                                            const subTotal =
                                              Number(
                                                rentAmount - row.discount
                                              ) || 0; // Ensure subTotal is a number, defaulting to 0 if invalid
                                            return sum + subTotal / 2;
                                          }, 0) * (Number(taxRate) || 0)
                                        ).toFixed(2)}
                                  </div>
                                </div>
                                <div className="border02"></div>
                              </div>
                            ) : (
                              <div>
                                <div
                                  style={{
                                    margin: "15px 0px 15px 0px",
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div style={{ color: "grey" }}>
                                    Tax (IGST {(taxRate * 100).toFixed(2)}%) :
                                  </div>
                                  <div>
                                    ₹{" "}
                                    {location.state !== null
                                      ? (
                                          rows.reduce((sum, row) => {
                                            const subTotal =
                                              Number(row.rent - row.discount) ||
                                              0;
                                            return subTotal;
                                          }, 0) * (Number(taxRate) || 0)
                                        ).toFixed(2)
                                      : (
                                          rows.reduce((sum, row) => {
                                            const subTotal =
                                              Number(
                                                rentAmount - row.discount
                                              ) || 0;
                                            return sum + subTotal;
                                          }, 0) * (Number(taxRate) || 0)
                                        ).toFixed(2)}
                                  </div>
                                </div>
                                <div className="border02"></div>
                              </div>
                            )} */}

                            <div
                              style={{
                                margin: "15px 0px 15px 0px",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                fontWeight: "bold",
                                fontSize: "18px",
                              }}
                            >
                              <div> Total :</div>
                              <div>
                                ₹ {roundOffTotal(Number(total) || 0).toFixed(2)}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      style={{
                        marginTop: "20px",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      {view === false ? (
                        <Button
                          onClick={handleSubmit}
                          style={{
                            marginRight: "10px",
                            padding: "10px 20px",
                            border: "none",
                            backgroundColor: "#42B677",
                            color: "white",
                          }}
                        >
                          Create Invoice
                        </Button>
                      ) : (
                        <div></div>
                      )}
                      <Button
                        onClick={() => handleCancel()}
                        style={{
                          backgroundColor: "orange",
                          color: "white",
                          padding: "10px 20px",
                          border: "none",
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};