import React, { useEffect, useRef, useState } from "react";
import { Card, CardBody } from "@progress/kendo-react-layout";
import "../../css/Dashboard.scss";
import "../../css/Common.scss";

import { Grid, GridCellProps, GridColumn } from "@progress/kendo-react-grid";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import {
  exportIcon,
  eyeIcon,
  pencilIcon,
  trashIcon,
} from "@progress/kendo-svg-icons";
import { useNavigate } from "react-router-dom";
import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";

import axios from "axios";
import Breadcrumbs from "../../components/Breadcrumbs";
import { Input, TextBox } from "@progress/kendo-react-inputs";
import { formatDateDMY } from "../../components/commoncomponents";
import {
  commonBaseUrl,
  RightAlignedHeaderCell,
} from "../../../services/CommonUtil";

export const ContractStatus = {
  Active: 1,
  Closed: 2,
  Pending: 3,
  Expired: 4,
};

export const ContractMasterList = () => {
  // Set initial state for the dynamic data
  const baseURL = commonBaseUrl();

  //
  const [contractList, setContractList] = useState([]);
  const [paymenStatus, setpaymentStatus] = useState([]);
  const [bookingList, setBookingList] = useState([]);
  const [spaceHeaders, setSpaceHeaders] = useState([]);

  const navigate = useNavigate();
  const hasFetched = useRef(false);

  // Variables
  const [totalContract, setTotalContract] = useState("0");
  const [pending, setPending] = useState("0");
  const [active, setActive] = useState("0");
  const [expired, setExpired] = useState("0");

  // Breadcrums
  const breadCrumbsData = [
    {
      id: "1",
      text: "Masters",
      route: "",
    },
    {
      id: "2",
      text: "Contract master",
      route: "",
    },
  ];

  useEffect(() => {
    getAllList();
  }, []);

  //   const getAllList = async () => {
  //     try {
  //       const responseListData = await axios.get(`${baseURL}odata/Contracts`);
  //       const getAllData = responseListData.data.value;
  //       console.log(getAllData, "getAllData");
  //       try {
  //         const responseListData = await axios.get(`${baseURL}odata/Status`);
  //         console.log(responseListData, "getStatusData");
  //         const filteredActiveListData = getAllData.filter((item: any) => item.StatusId === ContractStatus.Active);
  //         console.log("Active Count->", filteredActiveListData.length);
  //         const filteredClosedListData = getAllData.filter((item: any) => item.StatusId === ContractStatus.Closed);
  //         console.log("Closed Count->", filteredClosedListData.length);
  //         const filteredPendingListData = getAllData.filter((item: any) => item.StatusId === ContractStatus.Pending);
  //         console.log("Pending Count->", filteredPendingListData.length);
  //         const filteredExpiredListData = getAllData.filter((item: any) => item.StatusId === ContractStatus.Expired);
  //         console.log("Expired Count->", filteredExpiredListData.length);
  //         for(let status of responseListData.data) {
  //           console.log("Status->", status === ContractStatus.Active);
  //         }
  //         setpaymentStatus(responseListData.data);
  //         try {
  //           const responseBookingData = await axios.get(
  //             `${baseURL}odata/Bookings`
  //           );
  //           try {
  //             const responsespaces = await axios.get(
  //               `${baseURL}odata/SpaceHeaders`
  //             );

  //             setSpaceHeaders(responsespaces.data.value);
  //             const bookingDetails = responseBookingData.data.value.find(
  //               (bookingListData: any) =>
  //                 getAllData.find(
  //                   (getAll: any) =>
  //                     parseInt(getAll.SpaceBookedId) ===
  //                     parseInt(bookingListData.Id)
  //                 )
  //             );
  //             // console.log(bookingDetails, "<< Booking Data");
  //             const noofseats =bookingDetails.NoOfSeats;

  //             // console.log(responsespaces.data.value, "<< Booking Data");
  // // const spaceBookeds  = responsespaces.data.value.find((Values: any)=>
  // //   Values.SpaceTypeId ===)

  //             // console.log(noofseats, "<< Booking Data");

  //             const spaceBookedValue = responsespaces.data.value.find(
  //               (Value: any) =>
  //                 getAllData.find(
  //                   (getAll: any) => Value.SpaceTypeId === getAll.SpaceTypeId
  //                 )
  //             );

  //             const Datassss = responsespaces.data.value.find((vs: any) =>
  //               getAllData.find(
  //                 (vsc: any) => vs.SpaceTypeId === vsc.SpaceBookedId
  //               )
  //             );
  //             // console.log(responsespaces.data.value, "trdfgvhbjgfsfedhjk");

  //             // console.log(getAllData, "trdfgvhbjgfhjk");
  //             const finalData = getAllData.map((Datas: any) => {

  //               const Datastt = responsespaces.data.value.find((ddfs: any)=>
  //                 Datas.find((gt: any)=>gt.SpaceBookedId === ddfs.SpaceTypeId)

  //                 )
  //               // console.log(Datastt, "trdfgvhbasdsajgfhjk");

  //               const status = responseListData.data.find(
  //                 (PaymentDatas: any) => Datas.StatusId === PaymentDatas.id
  //               );

  //               const pendingstatuslength = responseListData.data.find(
  //                 (PaymentDatas: any) => {
  //                   // console.log( Datas.StatusId , PaymentDatas.id ,"qwertyuiopoiuhgfdsasdfghjkjhgfrfvbhjygfv")
  //                   return Datas.StatusId === PaymentDatas.id;
  //                 }
  //               );

  //               // console.log(
  //               //   Datas.Id,
  //               //   "qwertyuiopoiuhgfdsasdfghjkjhgfrfvbhjygfv"
  //               // );

  //               setTotalContract(getAllData.length);
  //               return {
  //                 ids : Datas.Id,
  //                 ContractNumber: Datas.ContractNumber,
  //                 BookingId: bookingDetails.BookingId,
  //                 StatusId: status,

  //                 SpaceBookedId: Datastt.SpaceName,

  //                 PeriodStart: Datas.PeriodStart,
  //                 PeriodEnd: Datas.PeriodEnd,
  //               };
  //             });
  //             setContractList(finalData);
  //           } catch (err) {}
  //         } catch (err) {}
  //       } catch (err) {}
  //     } catch (err) {}
  //   };

  const [CfilteredData, setCfilteredData] = useState([]);

  const getAllList = async () => {
    try {
      // Fetch all contract data
      const responseListData = await axios.get(`${baseURL}odata/Contracts`);
      const getAllData = responseListData.data.value;

      // Fetch Status Data
      const statusResponse = await axios.get(`${baseURL}odata/Status`);
      const statusData = statusResponse.data;
      console.log(getAllData, "sfghyuiohgfdfcvhjuiuytfdc");

      // Filter data based on status
      const activeList = getAllData.filter(
        (item: any) => item.StatusId === ContractStatus.Active
      );
      const closedList = getAllData.filter(
        (item: any) => item.StatusId === ContractStatus.Closed
      );
      const pendingList = getAllData.filter(
        (item: any) => item.StatusId === ContractStatus.Pending
      );
      const expiredList = getAllData.filter(
        (item: any) => item.StatusId === ContractStatus.Expired
      );

      // Set the counts in the UI for the respective status
      setTotalContract(getAllData.length);
      setPending(pendingList.length);
      setActive(activeList.length);
      setExpired(expiredList.length);

      // Fetch Booking Data
      const responseBookingData = await axios.get(`${baseURL}odata/Bookings`);
      const bookingData = responseBookingData.data.value;

      // Fetch SpaceHeaders Data
      const spaceResponse = await axios.get(`${baseURL}odata/SpaceHeaders`);
      const spaceHeaders = spaceResponse.data.value;
      console.log(bookingData, "dfghjkjhgfdsdfghjkhgfdsfghj");
      setSpaceHeaders(spaceHeaders);

      // Map through all data and prepare final list
      const finalData = getAllData.map((contract: any) => {
        const bookingDetails = bookingData.find(
          (booking: any) => booking.Id === contract.BookingId
        );
        const spaceDetails = spaceHeaders.find(
          (space: any) => space.SpaceTypeId === contract.SpaceBookedId
        );
        console.log(spaceDetails, "dfghjkjhgfdefghjkl");
        const status = statusData.find(
          (status: any) => status.id === contract.StatusId
        );

        return {
          ids: contract.Id,
          ContractNumber: contract.ContractNumber,
          BookingId: bookingDetails ? bookingDetails.BookingId : null,
          StatusId: contract.StatusId,
          bookingId: contract.BookingId,
          SpaceBookedId: spaceDetails
            ? `${spaceDetails.NoOfSeats} x ${spaceDetails.SpaceName}`
            : "Unknown",
          PeriodStart: contract.PeriodStart,
          PeriodEnd: contract.PeriodEnd,
        };
      });

      // Store finalData globally for filtering
      setContractList(finalData); // Set contract list
      setCfilteredData(finalData); // Store filtered data for dropdown filters
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };

  // Filtering the contracts based on selected filter
  const handleFilterChange = (event: string) => {
    const filterValue = event; // Extract value from the event
    if (filterValue === "All") {
      setContractList(CfilteredData); // Show all contracts
    } else if (filterValue === "Active") {
      setContractList(CfilteredData.filter((item: any) => item.StatusId === 1));
    } else if (filterValue === "Pending") {
      setContractList(CfilteredData.filter((item: any) => item.StatusId === 3));
    } else if (filterValue === "Expired") {
      setContractList(CfilteredData.filter((item: any) => item.StatusId === 4));
    }
  };

  // Call getAllList in useEffect to fetch data on component mount
  useEffect(() => {
    getAllList();
  }, []);

  const [page, setPage] = useState({ skip: 0, take: 5 });

  const handlePageChange = (e: any) => {
    setPage(e.page);
  };

  const statusCell = (props: any) => {
    // console.log(props.dataItem, "Status ID");
    // const status = props.dataItem.StatusId as
    //   | 1 as "Active"
    //   | 3 as "Pending"
    //   | 4 as "Expired"; // Explicitly typing the status
    // const statusStyles: { [key: string]: React.CSSProperties } = {
    //   Active: { color: "green" },
    //   Pending: { color: "orange" },
    //   Expired: { color: "gray" },
    // };

    var bgColor = {};
    var status = "";
    switch (props.dataItem.StatusId) {
      case 1:
        status = "Active";
        bgColor = { color: "green" };
        break;
      case 3:
        status = "Pending";
        bgColor = { color: "orange" };
        break;
      case 4:
        status = "Expired";
        bgColor = { color: "gray" };
        break;
    }

    // Fallback style if the status doesn't match any predefined value
    // const defaultStyle: React.CSSProperties = { color: "black" };

    // Use the style from statusStyles or fall back to defaultStyle
    // const style = statusStyles[status] || defaultStyle;

    return <td style={bgColor}>{status}</td>;
  };
  // const spacecellCell = (props: any) => {
  //   console.log(props.dataItem, "tscsvtbgysubsfhkub");
  //   console.log(spaceHeaders, "tcsvtbgysubsfhkub");
  //   const noofseats = props.dataItem.SpaceBookedId.NoOfSeats;
  //   const spaceHeader = spaceHeaders.find(
  //     (spaceHead: any) => spaceHead.Id === props.dataItem.SpaceBookedId.SpaceId
  //   );
  //   // const space =
  //   console.log(
  //     spaceHeader !== undefined ? spaceHeader["SpaceTypeName"] : "northing",
  //     ";lkadlkmasd;kjnd"
  //   );

  //   const spaceType =
  //     spaceHeader !== undefined ? spaceHeader["SpaceTypeName"] : "";

  //   return (
  //     <td>
  //       {noofseats} x {spaceType}
  //     </td>
  //   );
  // };

  const actionCell = (props: any) => {
    return (
      <td>
        <Button
          style={{ marginRight: "10px" }}
          className="view-color"
          type="button"
          svgIcon={eyeIcon}
          onClick={() => {
            console.log(props, "ytdasydkvluhysdgluaassadks");
            navigate("/masters/editcontractmaster", {
              state: {
                contractId: props.dataItem.ids,
                bookingId: props.dataItem.bookingId,
                view: true,
              },
            });
          }}
        ></Button>
        {/* <Button
          className="edit-color"
          type="button"
          svgIcon={pencilIcon}
          onClick={() => {
            navigate("/masters/editcontractmaster", {
              state: { contractId: props.dataItem.ids, view: true },
            });
          }}
        ></Button>
        <Button
          className="delete-color"
          type="button"
          svgIcon={trashIcon}
          onClick={() => {}}
        ></Button> */}
      </td>
    );
  };

  const [filter, setFilter] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChangess = (e: any) => {
    setPage({ skip: 0, take: page.take });
    setSearchQuery(e.target.value);
  };

  // First filter based on Status
  const filteredData =
    filter === "All"
      ? contractList
      : contractList.filter((contract: any) => contract.StatusId === filter);

  // Further filter based on search query
  const filtered = filteredData.filter((contractDataList: any) => {
    const search = searchQuery.toLowerCase();

    return (
      contractDataList.ContractNumber?.toLowerCase().includes(search) || // Ensure fields exist before calling methods
      contractDataList.BookingId?.toLowerCase().includes(search) || // Check correct casing of field name
      contractDataList.SpaceBookedId?.toLowerCase().includes(search) || // Check correct field name
      contractDataList.PeriodStart?.toString().includes(search) || // Ensure toString for numeric values
      contractDataList.PeriodEnd?.toString().includes(search) // Period might be a range; checking PeriodStart and PeriodEnd separately
    );
  });

  console.log("filtered", filteredData, filtered, contractList);

  return (
    <div className="dashboard-bg" style={{ padding: "10px" }}>
      <div className="overall-view">
        <div>
          <div className="dashboard-button">
            <Breadcrumbs breadCrumbData={breadCrumbsData} />

            <div style={{ display: "flex", flexDirection: "row" }}>
              {/*side Buttons*/}
              {/* <Button
                style={{
                  height: "70%",
                  padding: "15px",
                  backgroundColor: "white",
                  color: "#42B677",
                  borderColor: "#42B677",
                }}
                onClick={() => {}}
                svgIcon={exportIcon}
                //   icon="exportIcon"
                // themeColor="success"
              >
                EXPORT DATA
              </Button> */}
              <div style={{ padding: "10px" }}> </div>
              <Button
                style={{
                  height: "70%",
                  padding: "15px",
                  backgroundColor: "#42B677",
                  color: "white",
                }}
                onClick={() => {
                  navigate("/masters/editcontractmaster");
                }}
                icon="plus"
                // themeColor="success"
              >
                ADD CONTRACT
              </Button>
            </div>
          </div>
          <hr className="hr-border" />
        </div>
        {/* Count view */}
        <div className="dashboard-grid">
          <div className="dashboard-item">
            <Card>
              <CardBody>
                <h3>Total Contract</h3>
                <p>{totalContract}</p>
              </CardBody>
            </Card>
          </div>

          <div className="dashboard-item">
            <Card>
              <CardBody>
                <h3>Pending</h3>
                <p>{pending}</p>
              </CardBody>
            </Card>
          </div>

          <div className="dashboard-item">
            <Card>
              <CardBody>
                <h3>Active</h3>
                <p>{active}</p>
              </CardBody>
            </Card>
          </div>
          <div className="dashboard-item">
            <Card>
              <CardBody>
                <h3>Expired</h3>
                <p>{expired}</p>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
      <div className="overall-view">
        <div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "2rem",
                margin: "20px 0px 10px 10px",
              }}
            >
              Contracts List
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "20px 0px 10px 10px",
              }}
            >
              <DropDownList
                data={["All", "Active", "Pending", "Expired"]}
                defaultValue="All"
                style={{ width: "200px", marginBottom: "10px" }}
                onChange={(e) => {
                  setPage({ skip: 0, take: page.take });
                  handleFilterChange(e.value);
                }} // Handle filter change
              />
              <TextBox
                style={{
                  marginBottom: "10px",
                  width: "500px",
                  height: "80%",
                  marginLeft: "10px",
                }}
                className="textBoxcustom"
                placeholder="Search by Contract Number / Booking ID / Space(s) Booked "
                value={searchQuery}
                onChange={handleSearchChangess}
                suffix={() => (
                  <>
                    <span className="input-icon">
                      {searchQuery ? (
                        <Button
                          icon="close"
                          onClick={() => setSearchQuery("")}
                        />
                      ) : (
                        <Button icon="search" />
                      )}
                    </span>
                  </>
                )}
                // onChange={(e) => setSearchQuery(e.value)}
              />
            </div>
          </div>

          <Grid
            data={filtered.slice(page.skip, page.take + page.skip)} // Use filtered data
            skip={page.skip}
            take={page.take}
            total={filteredData.length} // Use filtered data length for total
            pageable={{ buttonCount: 5, pageSizes: [5, 10, 15, 20] }}
            onPageChange={handlePageChange}
          >
            <GridColumn
              field="Id"
              title="S.No"
              width={10}
              headerCell={RightAlignedHeaderCell}
              cell={(props) => (
                <td
                  style={{
                    height: "75px",
                    textAlign: "right",
                    paddingRight: "40px",
                  }}
                >
                  {props.dataIndex + 1}
                </td>
              )}
            />
            <GridColumn field="ContractNumber" title="Contract Number" />
            <GridColumn field="StatusId" title="Status" cell={statusCell} />
            <GridColumn field="BookingId" title="Booking ID" />
            <GridColumn
              field="SpaceBookedId"
              title="Space(s) Booked"
              // cell={spacecellCell}
            />
            <GridColumn
              field="Period"
              title="Period"
              headerCell={RightAlignedHeaderCell}
              cell={(props) => (
                <td
                  style={{
                    height: "75px",
                    textAlign: "right",
                    paddingRight: "35px",
                  }}
                >
                  <div>
                    {formatDateDMY(props.dataItem.PeriodStart)}
                    {} - {formatDateDMY(props.dataItem.PeriodEnd)}
                  </div>
                </td>
              )}
            />
            <GridColumn title="Action" width={10} cell={actionCell} />
          </Grid>
        </div>
      </div>
    </div>
  );
};
