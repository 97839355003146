import React, { useEffect, useRef, useState } from "react";
import { Label } from "@progress/kendo-react-labels";
import { Button } from "@progress/kendo-react-buttons";
import { Input, TextArea } from "@progress/kendo-react-inputs";
import { useLocation, useNavigate } from "react-router-dom";
import { Loader } from "@progress/kendo-react-indicators";
import Breadcrumbs from "../components/Breadcrumbs";
import {
  commonBaseUrl,
  encryptedBase64Password,
  decryptBase64Password,
} from "../../services/CommonUtil";
import "../css/Member.scss";
import axios from "axios";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import {
  chevronDownIcon,
  eyeIcon,
  eyeSlashIcon,
} from "@progress/kendo-svg-icons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { SvgIcon } from "@progress/kendo-react-common";

interface RegisterFormErrors {
  customerNameErr?: string;
  customerRoleErr?: string;
  customerMobileErrr?: string;
  customerEmailErrr?: string;
  customerAddresEerrr?: string;
  customerKYCDocumentErrr?: string;
  customerKYCFileErrr?: string;
  customerPasswordErrr?: string;
  customerConfirmPasswordErrr?: string;
  customerPincodeErrr?: string;
  customerCountryErrr?: string;
  customerStateErrr?: string;
  customerCityErrr?: string;
}

interface ErrorRegisterRequired {
  customerNameErr?: boolean;
  customerRoleErr?: boolean;
  customerMobileErrr?: boolean;
  customerEmailErrr?: boolean;
  customerAddresEerrr?: boolean;
  customerKYCDocumentErrr?: boolean;
  customerPassowordErrr?: boolean;
  customerConfirmPasswordErrr?: boolean;
  customerPincodeErrr?: boolean;
  customerCountryErrr?: boolean;
  customerStateErrr?: boolean;
  customerCityErrr?: boolean;
}

export const MemberEdit = () => {
  const baseURL = commonBaseUrl();

  const navigate = useNavigate();
  const hasFetched = useRef(false);
  const { state: { infoMember, view } = {} } = useLocation();
  const defaultFormError = {
    customerNameErr: "",
    customerRoleErr: "",
    customerMobileErrr: "",
    customerEmailErrr: "",
    customerAddresEerrr: "",
    customerKYCDocumentErrr: "",
    customerKYCFileErrr: "",
    customerPasswordErrr: "",
    customerConfirmPasswordErrr: "",
    customerPincodeErrr: "",
    customerCountryErrr: "",
    customerStateErrr: "",
    customerCityErrr: "",
  };

  const [loading, setLoading] = React.useState(false);
  const [dialogCheck, setDialogCheck] = React.useState(false);
  const [isEdit, setIsEdit] = useState(infoMember === undefined ? false : true);
  const [visible, setVisible] = React.useState<boolean>(false);
  const [alertContent, setAlertContent] = useState<String>("");
  const [memberRoleList, setMemberRoleList] = useState([]);
  const [selectMemberRoleId, setSelectMemberRoleId] = useState(0);
  const [selectMemberRoleName, setSelectMemberRoleName] = useState({
    Id: 0,
    Name: "Select Role",
  });

  const mobileRegex = /^[6-9][0-9]{9}$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const [profilePassword, setProfilePassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordValidationError, setPasswordValidationError] = useState("");

  // Toggle between password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Toggle between password visibility
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const storeUserDetails = {
    firstName: infoMember === undefined ? "" : infoMember.Name,
    lastName:
      infoMember === undefined
        ? ""
        : infoMember.LastName === null
        ? ""
        : infoMember.LastName,
    role: infoMember === undefined ? "" : infoMember.RoleId,
    mobileNumber: infoMember === undefined ? "" : infoMember.Mobile,
    emailAddress: infoMember === undefined ? "" : infoMember.Email,
    addressLine1:
      infoMember === undefined
        ? ""
        : infoMember.Address === null
        ? ""
        : infoMember.Address,
    addressLine2:
      infoMember === undefined
        ? ""
        : infoMember.Address2 === null
        ? ""
        : infoMember.Address2,
    country:
      infoMember === undefined
        ? ""
        : infoMember.Country === null
        ? ""
        : infoMember.Country,
    state:
      infoMember === undefined
        ? ""
        : infoMember.State === null
        ? ""
        : infoMember.State,
    city:
      infoMember === undefined
        ? ""
        : infoMember.City === null
        ? ""
        : infoMember.City,
    pincode:
      infoMember === undefined
        ? ""
        : infoMember.PinCode === null
        ? ""
        : infoMember.PinCode,
    password: infoMember === undefined ? "" : infoMember.Password,
  };

  const [formData, setFormData] = useState(storeUserDetails);
  const [registerRoomBookErrorFormData, setRegisterRoomBookErrorFormData] =
    useState<RegisterFormErrors>(defaultFormError);

  // BreadCrumbs
  const breadCrumbsData = [
    {
      id: "1",
      text: "Member Register",
      route: "",
    },
    {
      id: "2",
      text: "Members",
      route: "/member/register",
    },
    {
      id: "3",
      text: "Create Member",
      route: "",
    },
  ];

  const breadCrumbsDataEdit = [
    {
      id: "1",
      text: "Member Register",
      route: "",
    },
    {
      id: "2",
      text: "Members",
      route: "/member/register",
    },
    {
      id: "3",
      text: "Edit Member",
      route: "",
    },
  ];

  useEffect(() => {
    // Prevent second call
    if (hasFetched.current) return;
    hasFetched.current = true;
    MemberRoleListAPI();
  });

  // Initial API Call
  const MemberRoleListAPI = async () => {
    if (infoMember !== undefined) {
      setProfilePassword(decryptBase64Password(infoMember.Password));
      setConfirmPassword(decryptBase64Password(infoMember.Password));
    }
    setLoading(true);
    try {
      const response = await axios.get(
        `${baseURL}odata/UserRoles?$filter=Id ne 2`
        // `${baseURL}odata/Users?$filter=RoleId ne 1 and RoleId ne 2`
      );
      if (infoMember !== undefined) {
        for (let r = 0; r < response.data.value.length; r++) {
          if (infoMember.RoleId === response.data.value[r].Id) {
            setSelectMemberRoleName(response.data.value[r]);
            setSelectMemberRoleId(response.data.value[r].Id);
            break;
          }
        }
      }
      setMemberRoleList(response.data.value);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      alert(err);
    }
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    if (name === "mobileNumber") {
      if (/^\d*$/.test(value)) {
        console.log("name", value);
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    } else if (name === "emailAddress") {
      setFormData({
        ...formData,
        [name]: value,
      });
    } else if (
      name === "firstName" ||
      name === "lastName" ||
      name === "country" ||
      name === "state" ||
      name === "city"
    ) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value.replace(/[^A-Za-z ]/, ""),
      }));
    } else if (name === "pincode") {
      if (/^\d*$/.test(value)) {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  let header = {
    "Content-Type":
      "application/json;odata.metadata=minimal;odata.streaming=true",
    accept: "*/*",
  };

  const profileValidation = () => {
    const errors: RegisterFormErrors = {};
    const isCheckErrors: ErrorRegisterRequired = {};
    // Name validation
    if (!formData.firstName!.trim()) {
      errors.customerNameErr = "Name is required";
      isCheckErrors.customerNameErr = true;
    }

    if (infoMember === undefined) {
      // Mobile number validation (10 digits)
      if (!formData.mobileNumber.trim()) {
        errors.customerMobileErrr = "Mobile number is required";
        isCheckErrors.customerMobileErrr = true;
      } else if (!mobileRegex.test(formData.mobileNumber)) {
        errors.customerMobileErrr =
          "Invalid mobile number and must be 10 digits";
        isCheckErrors.customerMobileErrr = true;
      }
    }

    // Role validation
    if (selectMemberRoleId === 0) {
      errors.customerRoleErr = "Role is required";
      isCheckErrors.customerRoleErr = true;
    }

    if (infoMember === undefined) {
      // Email validation
      if (!formData.emailAddress.trim()) {
        errors.customerEmailErrr = "Email address is required";
        isCheckErrors.customerEmailErrr = true;
      } else if (!emailRegex.test(formData.emailAddress)) {
        errors.customerEmailErrr = "Invalid email address";
        isCheckErrors.customerEmailErrr = true;
      }
    }

    // Address validation
    if (!formData.addressLine1!.trim()) {
      errors.customerAddresEerrr = "Address is required";
      isCheckErrors.customerAddresEerrr = true;
    }
    if (!formData.country!.trim()) {
      errors.customerCountryErrr = "Country is required";
      isCheckErrors.customerCountryErrr = true;
    }
    if (!formData.state!.trim()) {
      errors.customerStateErrr = "State is required";
      isCheckErrors.customerStateErrr = true;
    }
    if (!formData.city!.trim()) {
      errors.customerCityErrr = "City is required";
      isCheckErrors.customerCityErrr = true;
    }

    // Pincode validation
    if (!formData.pincode!.toString().trim()) {
      errors.customerPincodeErrr = "Pincode is required";
      isCheckErrors.customerPincodeErrr = true;
    }

    // Password validation
    if (!profilePassword.trim()) {
      errors.customerPasswordErrr = "Password is required";
      isCheckErrors.customerPassowordErrr = true;
    } else if (passwordValidationError !== "") {
      errors.customerPasswordErrr =
        "Password must be 8 digits and contain Uppercase, Lowercase, Special Characters, and numbers";
      isCheckErrors.customerPassowordErrr = true;
    }

    if (!confirmPassword.trim()) {
      errors.customerConfirmPasswordErrr = "Confirm Password is required";
      isCheckErrors.customerConfirmPasswordErrr = true;
    } else if (profilePassword !== confirmPassword.trim()) {
      errors.customerConfirmPasswordErrr =
        "Confirm Password must be equal to password";
      isCheckErrors.customerConfirmPasswordErrr = true;
    }

    setRegisterRoomBookErrorFormData(errors);
    return Object.keys(errors).length === 0;
  };

  const registerUserAPI = async (e: any) => {
    e.preventDefault();

    const currentDate = new Date();
    const isoString = currentDate.toISOString();

    if (profileValidation()) {
      setLoading(true);
      // Creating the register data object
      let registerData = {
        name: formData.firstName,
        password: encryptedBase64Password(profilePassword),
        mobile: Number(formData.mobileNumber),
        email: formData.emailAddress,
        companyId: 1,
        PointOfContact: null,
        roleId: selectMemberRoleId,
        isActive: true,
        createdBy: 1,
        createdOn: isoString,
        address: formData.addressLine1,
        country: formData.country,
        state: formData.state,
        city: formData.city,
        pincode: Number(formData.pincode),
        companyName: "",
        kycDocumentId: 1,
        gstNumber: "",
        notes: "",
        kycDocument: "Yes",
        userType: true,
        customerType: 1,
        address2: formData.addressLine2,
        lastName: formData.lastName,
        isVerified: true,
      };
      console.log("success", registerData);
      try {
        // API call using axios
        const response = await axios.post(
          `${baseURL}odata/Users`,
          registerData,
          {
            headers: header,
          }
        );

        // Check if response exists and has status 200
        if (response && response.status === 201) {
          setVisible(true);
          setDialogCheck(true);
          setAlertContent("Member has been created successfully");
        } else {
          // Show error message or alert
          setVisible(true);
          setDialogCheck(false);
          setAlertContent("Server not reachable. Please try again later!");
        }
        setLoading(false);
      } catch (err: any) {
        // Handling the error in catch block
        if (err.response) {
          // Check if err.response and err.response.status exist
          if (err.response.status === 500) {
            // Show error message or alert
            setVisible(true);
            setDialogCheck(false);
            setAlertContent(`${err.response.data.detail}`);
          }
        } else {
          // Show generic error message or alert
          setVisible(true);
          setDialogCheck(false);
          setAlertContent("An error occurred. Please try again.");
        }
        setLoading(false);
      }
    }
  };

  const updateRegisterUserAPI = async (e: any) => {
    e.preventDefault();

    const currentDate = new Date();
    const isoString = currentDate.toISOString();

    if (profileValidation()) {
      setLoading(true);
      // Creating the register data object
      let registerData = {
        Id: Number(infoMember.Id),
        Name: formData.firstName,
        Password: encryptedBase64Password(profilePassword),
        Mobile: Number(formData.mobileNumber),
        Email: formData.emailAddress,
        CompanyId: 1,
        PointOfContact: null,
        KYCDocument: "Yes",
        UserType: true,
        RoleId: selectMemberRoleId,
        IsActive: true,
        IsDelete: false,
        CreatedBy: 1,
        CreatedOn: infoMember.CreatedOn,
        ModifiedBy: 1,
        ModifiedOn: isoString,
        Address: formData.addressLine1,
        CompanyName: "",
        KycDocumentId: 1,
        GstNumber: "",
        Notes: "",
        CustomerType: 1,
        address2: formData.addressLine2,
        country: formData.country,
        state: formData.state,
        city: formData.city,
        PinCode: formData.pincode,
        CustomerId: infoMember.CustomerId,
        LastName: formData.lastName,
      };
      console.log("update", registerData);
      try {
        // API call using axios
        const response = await axios.post(
          `${baseURL}odata/UpdateUser?key=${Number(infoMember.Id)}`,
          registerData,
          {
            headers: header,
          }
        );

        // Check if response exists and has status 200
        if (response && response.status === 200) {
          setVisible(true);
          setDialogCheck(true);
          setAlertContent("Member has been updated successfully");
        } else {
          // Show error message or alert
          setVisible(true);
          setDialogCheck(false);
          setAlertContent("Server not reachable. Please try again later!");
        }
        setLoading(false);
      } catch (err: any) {
        // Handling the error in catch block
        if (err.response) {
          // Check if err.response and err.response.status exist
          if (err.response.status === 500) {
            // Show error message or alert
            setVisible(true);
            setDialogCheck(false);
            setAlertContent(`${err.response.data.detail}`);
            setLoading(false);
          }
        } else {
          // Show generic error message or alert
          setVisible(true);
          setDialogCheck(false);
          setAlertContent("An error occurred. Please try again.");
          setLoading(false);
        }
      }
    }
  };

  const toggleDialog = () => {
    setVisible(!visible);
    if (dialogCheck === true) {
      navigate(-1);
    }
  };

  // validate password
  const MIN_LENGTH = 8;
  const hasUppercase = /[A-Z]/;
  const hasLowercase = /[a-z]/;
  const hasNumber = /\d/;
  const hasSymbol = /[!@#$%^&*_]/;
  const hasSpace = /\s/;

  // Validate password
  const validatePassword = (value: string) => {
    if (hasSpace.test(value)) {
      return "Password should not contain spaces.";
    }
    if (value.length < MIN_LENGTH) {
      return `Password must be at least ${MIN_LENGTH} characters long.`;
    }
    if (!hasUppercase.test(value)) {
      return "Password must contain at least one uppercase letter.";
    }
    if (!hasLowercase.test(value)) {
      return "Password must contain at least one lowercase letter.";
    }
    if (!hasSymbol.test(value)) {
      return "Password must contain at least one special character.";
    }
    if (!hasNumber.test(value)) {
      return "Password must contain at least one number.";
    }

    return ""; // Password is valid
  };

  const handlePasswordChanges = (e: any) => {
    const { name, value } = e.target;
    if (name === "password") {
      setProfilePassword(value);
      const error = validatePassword(value);
      setPasswordValidationError(error);
    } else if (name === "confirmPassword") {
      setConfirmPassword(value);
    }
  };

  const handleDropdown = (e: any) => {
    console.log("e", e.value);
    setSelectMemberRoleName(e.value);
    setSelectMemberRoleId(e.value.Id);
  };

  const handleBack = (e: any) => {
    setRegisterRoomBookErrorFormData(defaultFormError);
    window.scrollTo(0, 0);
    navigate(-1);
  };

  return (
    <div className="dashboard-bg" style={{ padding: "10px" }}>
      {loading ? (
        <Loader
          size={"large"}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
          type={"converging-spinner"}
        />
      ) : (
        <div>
          <div className="overall-view">
            <div>
              <div className="dashboard-button">
                <Breadcrumbs
                  breadCrumbData={
                    infoMember === undefined
                      ? breadCrumbsData
                      : breadCrumbsDataEdit
                  }
                />
                {view === false && (
                  <Button
                    style={{
                      height: "20%",
                      padding: "12px",
                      backgroundColor: "#42B677",
                      color: "white",
                    }}
                    onClick={() => setIsEdit(false)}
                    // navigate("/member/create");
                  >
                    EDIT
                  </Button>
                )}
              </div>
              <hr className="hr-border" />
            </div>
          </div>
          <div className="overall-view">
            <div>
              <div className="dashboard">
                {/* First & Last Name */}
                <div className="member-form-group">
                  <Label className="member-field-flow">
                    Member Name
                    <Label className="manditory-fields">*</Label>
                  </Label>

                  <div className="member-form-group-row">
                    <Input
                      style={{
                        backgroundColor: isEdit ? "#EEEEEE" : "#FFFFFF",
                        height: "45px",
                      }}
                      className="personal-fields"
                      type="text"
                      name="firstName"
                      value={formData.firstName!}
                      maxLength={50}
                      onChange={handleInputChange}
                      placeholder="Enter First Name"
                      disabled={isEdit}
                      required={
                        formData?.firstName === "" &&
                        true &&
                        registerRoomBookErrorFormData?.customerNameErr !== ""
                      }
                    />
                    <Input
                      style={{
                        backgroundColor: isEdit ? "#EEEEEE" : "#FFFFFF",
                        height: "45px",
                      }}
                      className="personal-fields"
                      type="text"
                      name="lastName"
                      value={formData.lastName!}
                      maxLength={50}
                      onChange={handleInputChange}
                      placeholder="Enter Last Name"
                      disabled={isEdit}
                    />
                  </div>
                  <div>
                    {formData?.firstName!.trim() === "" &&
                      registerRoomBookErrorFormData?.customerNameErr && (
                        <Label className="error-field">
                          {registerRoomBookErrorFormData?.customerNameErr}
                        </Label>
                      )}
                  </div>
                </div>
                {/* Role, Mobile, Mail */}
                <div
                  className="member-form-group-row"
                  style={{ marginTop: "14px" }}
                >
                  <div className="profile-mobile-field">
                    <Label className="member-field-flow">
                      User Role
                      <Label className="manditory-fields">*</Label>
                    </Label>
                    <DropDownList
                      style={{
                        backgroundColor:
                          infoMember !== undefined ? "#EEEEEE" : "#FFFFFF",
                        height: "45px",
                      }}
                      svgIcon={chevronDownIcon}
                      name="role"
                      className="member-dropdown-role"
                      data={memberRoleList}
                      dataItemKey="Id"
                      textField="Name"
                      value={selectMemberRoleName}
                      onChange={(e) => handleDropdown(e)}
                      disabled={isEdit}
                    />
                    <div>
                      {selectMemberRoleId === 0 &&
                        registerRoomBookErrorFormData?.customerRoleErr && (
                          <Label className="error-field">
                            {registerRoomBookErrorFormData?.customerRoleErr}
                          </Label>
                        )}
                    </div>
                  </div>
                  <div className="profile-mobile-field">
                    <Label className="member-field-flow">
                      Mobile Number
                      <Label className="manditory-fields">*</Label>
                    </Label>
                    <Input
                      style={{
                        backgroundColor:
                          infoMember !== undefined ? "#EEEEEE" : "#FFFFFF",
                        height: "45px",
                      }}
                      className="field-top personal-fields"
                      name="mobileNumber"
                      value={formData.mobileNumber!}
                      maxLength={10}
                      onChange={handleInputChange}
                      placeholder="Enter Mobile Number"
                      disabled={infoMember === undefined ? false : true}
                      required={
                        formData?.mobileNumber === "" &&
                        true &&
                        registerRoomBookErrorFormData?.customerMobileErrr !== ""
                      }
                    />
                    <div>
                      {!formData?.mobileNumber &&
                        registerRoomBookErrorFormData?.customerMobileErrr && (
                          <Label className="error-field">
                            {registerRoomBookErrorFormData?.customerMobileErrr}
                          </Label>
                        )}
                      {formData?.mobileNumber !== "" &&
                        (formData?.mobileNumber.length < 10 ||
                          !mobileRegex.test(formData.mobileNumber)) &&
                        registerRoomBookErrorFormData?.customerMobileErrr && (
                          <Label className="error-field">
                            {registerRoomBookErrorFormData?.customerMobileErrr}
                          </Label>
                        )}
                    </div>
                  </div>
                  <div className="profile-email-field">
                    <Label className="member-field-flow">
                      Email Address
                      <Label className="manditory-fields">*</Label>
                    </Label>
                    {/* <Input
                      style={{
                        backgroundColor:
                          infoMember !== undefined ? "#EEEEEE" : "#FFFFFF",
                        height: "45px",
                        wordWrap: "break-word",
                        whiteSpace: "pre-wrap",
                      }}
                      className="field-top personal-fields"
                      name="emailAddress"
                      value={formData.emailAddress!}
                      placeholder="Enter Email Address"
                      maxLength={50}
                      onChange={handleInputChange}
                      disabled={infoMember === undefined ? false : true}
                      required={
                        formData?.emailAddress === "" &&
                        true &&
                        registerRoomBookErrorFormData?.customerEmailErrr !== ""
                      }
                    /> */}
                    <TextArea
                      style={{
                        backgroundColor:
                          infoMember !== undefined ? "#EEEEEE" : "#FFFFFF",
                        height: "45px",
                        resize: "none",
                        wordWrap: "break-word",
                        whiteSpace: "pre-wrap",
                        overflow: "auto",
                      }}
                      className="field-top personal-fields"
                      name="emailAddress"
                      value={formData.emailAddress!}
                      placeholder="Enter Email Address"
                      maxLength={50}
                      onChange={handleInputChange}
                      disabled={infoMember !== undefined}
                      required={
                        formData?.emailAddress === "" &&
                        !!registerRoomBookErrorFormData?.customerEmailErrr
                      }
                    />
                    <div>
                      {formData?.emailAddress.trim() === "" &&
                        registerRoomBookErrorFormData?.customerEmailErrr && (
                          <Label className="error-field">
                            {registerRoomBookErrorFormData?.customerEmailErrr}
                          </Label>
                        )}
                      {formData?.emailAddress.trim() !== "" &&
                        !emailRegex.test(formData.emailAddress) &&
                        registerRoomBookErrorFormData?.customerEmailErrr && (
                          <Label className="error-field">
                            {registerRoomBookErrorFormData?.customerEmailErrr}
                          </Label>
                        )}
                    </div>
                  </div>
                </div>
                {/* Address */}
                <div
                  className="member-form-group"
                  style={{ marginTop: "14px" }}
                >
                  <Label className="member-field-another-flow ">
                    Address<Label className="manditory-fields">*</Label>
                  </Label>
                  <div className="member-form-group-row">
                    <Input
                      style={{
                        backgroundColor: isEdit ? "#EEEEEE" : "#FFFFFF",
                        height: "45px",
                      }}
                      className="personal-fields"
                      type="text"
                      name="addressLine1"
                      value={formData.addressLine1!}
                      maxLength={100}
                      onChange={handleInputChange}
                      placeholder="Enter Address Line 1"
                      disabled={isEdit}
                      required={
                        formData?.addressLine1 === "" &&
                        true &&
                        registerRoomBookErrorFormData?.customerAddresEerrr !==
                          ""
                      }
                    />
                    <Input
                      style={{
                        backgroundColor: isEdit ? "#EEEEEE" : "#FFFFFF",
                        height: "45px",
                      }}
                      className="personal-fields"
                      type="text"
                      name="addressLine2"
                      value={formData.addressLine2!}
                      maxLength={100}
                      onChange={handleInputChange}
                      placeholder="Enter Address Line 2"
                      disabled={isEdit}
                    />
                  </div>
                  <div>
                    {formData?.addressLine1!.trim() === "" &&
                      registerRoomBookErrorFormData?.customerAddresEerrr && (
                        <Label className="error-field">
                          {registerRoomBookErrorFormData?.customerAddresEerrr}
                        </Label>
                      )}
                  </div>
                </div>
                {/* Country, State, City & Pin */}
                <div
                  className="member-form-group-row"
                  style={{ marginTop: "14px" }}
                >
                  <div className="member-form-group">
                    <Label className="member-field-another-flow ">
                      Country<Label className="manditory-fields">*</Label>
                    </Label>
                    <Input
                      style={{
                        backgroundColor: isEdit ? "#EEEEEE" : "#FFFFFF",
                        height: "45px",
                      }}
                      className="personal-fields"
                      type="text"
                      name="country"
                      value={formData.country!}
                      maxLength={50}
                      onChange={handleInputChange}
                      placeholder="Enter Country"
                      disabled={isEdit}
                      required={
                        formData?.country === "" &&
                        true &&
                        registerRoomBookErrorFormData?.customerCountryErrr !==
                          ""
                      }
                    />
                    <div>
                      {formData?.country!.trim() === "" &&
                        registerRoomBookErrorFormData?.customerCountryErrr && (
                          <Label className="error-field">
                            {registerRoomBookErrorFormData?.customerCountryErrr}
                          </Label>
                        )}
                    </div>
                  </div>
                  <div className="member-form-group">
                    <Label className="member-field-another-flow ">
                      State<Label className="manditory-fields">*</Label>
                    </Label>
                    <Input
                      style={{
                        backgroundColor: isEdit ? "#EEEEEE" : "#FFFFFF",
                        height: "45px",
                      }}
                      className="personal-fields"
                      type="text"
                      name="state"
                      value={formData.state!}
                      maxLength={50}
                      onChange={handleInputChange}
                      placeholder="Enter State"
                      disabled={isEdit}
                      required={
                        formData?.state === "" &&
                        true &&
                        registerRoomBookErrorFormData?.customerStateErrr !== ""
                      }
                    />
                    <div>
                      {formData?.state!.trim() === "" &&
                        registerRoomBookErrorFormData?.customerStateErrr && (
                          <Label className="error-field">
                            {registerRoomBookErrorFormData?.customerStateErrr}
                          </Label>
                        )}
                    </div>
                  </div>
                  <div className="member-form-group">
                    <Label className="member-field-another-flow ">
                      City<Label className="manditory-fields">*</Label>
                    </Label>
                    <Input
                      style={{
                        backgroundColor: isEdit ? "#EEEEEE" : "#FFFFFF",
                        height: "45px",
                      }}
                      className="personal-fields"
                      type="text"
                      name="city"
                      value={formData.city!}
                      maxLength={50}
                      onChange={handleInputChange}
                      placeholder="Enter City"
                      disabled={isEdit}
                      required={
                        formData?.city === "" &&
                        true &&
                        registerRoomBookErrorFormData?.customerCityErrr !== ""
                      }
                    />
                    <div>
                      {formData?.city!.trim() === "" &&
                        registerRoomBookErrorFormData?.customerCityErrr && (
                          <Label className="error-field">
                            {registerRoomBookErrorFormData?.customerCityErrr}
                          </Label>
                        )}
                    </div>
                  </div>
                  <div className="member-form-group">
                    <Label className="member-field-another-flow ">
                      Pincode<Label className="manditory-fields">*</Label>
                    </Label>
                    <Input
                      style={{
                        backgroundColor: isEdit ? "#EEEEEE" : "#FFFFFF",
                        height: "45px",
                      }}
                      className="personal-fields"
                      name="pincode"
                      value={formData.pincode!}
                      onChange={handleInputChange}
                      placeholder="Enter Pincode"
                      maxLength={6}
                      disabled={isEdit}
                      required={
                        formData?.pincode === "" &&
                        true &&
                        registerRoomBookErrorFormData?.customerPincodeErrr !==
                          ""
                      }
                    />
                    <div>
                      {formData?.pincode! === "" &&
                        registerRoomBookErrorFormData?.customerPincodeErrr && (
                          <Label className="error-field">
                            {registerRoomBookErrorFormData?.customerPincodeErrr}
                          </Label>
                        )}
                    </div>
                  </div>
                </div>
                {/* Password */}
                <div
                  className="member-form-group-row"
                  style={{ marginTop: "14px" }}
                >
                  <div className="profile-mobile-field">
                    <Label className="member-field-flow">
                      Password
                      <Label className="manditory-fields">*</Label>
                    </Label>
                    <Input
                      style={{
                        backgroundColor: isEdit ? "#EEEEEE" : "#FFFFFF",
                        height: "45px",
                      }}
                      className="field-top personal-fields"
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter Password"
                      name="password"
                      autoComplete="off"
                      value={profilePassword}
                      onChange={handlePasswordChanges}
                      disabled={isEdit}
                      required={
                        profilePassword === "" &&
                        true &&
                        registerRoomBookErrorFormData?.customerPasswordErrr !==
                          ""
                      }
                      minLength={8}
                      maxLength={18}
                    />
                    <span
                      onClick={togglePasswordVisibility}
                      style={{
                        display: "flex",
                        position: "relative",
                        marginTop: "-28px",
                        marginLeft: "96%",
                        cursor: "pointer",
                      }}
                    >
                      {showPassword ? (
                        <SvgIcon icon={eyeIcon} />
                      ) : (
                        <SvgIcon icon={eyeSlashIcon} />
                      )}
                    </span>
                    <div style={{ marginTop: "12px" }}>
                      {(profilePassword === "" ||
                        registerRoomBookErrorFormData?.customerPasswordErrr) && (
                        <Label
                          className="error-field"
                          style={{ fontSize: "medium" }}
                        >
                          {registerRoomBookErrorFormData?.customerPasswordErrr}
                        </Label>
                      )}
                    </div>
                  </div>

                  <div className="profile-email-field">
                    <Label className="member-field-flow">
                      Confirm Password
                      <Label className="manditory-fields">*</Label>
                    </Label>
                    <Input
                      style={{
                        backgroundColor: isEdit ? "#EEEEEE" : "#FFFFFF",
                        height: "45px",
                      }}
                      className="field-top personal-fields"
                      type={showConfirmPassword ? "text" : "password"}
                      autoComplete="off"
                      value={confirmPassword}
                      onChange={handlePasswordChanges}
                      name="confirmPassword"
                      placeholder="Enter Confirm Password Again"
                      disabled={isEdit}
                      valid={profilePassword === confirmPassword}
                      required={
                        profilePassword !== confirmPassword &&
                        true &&
                        registerRoomBookErrorFormData?.customerConfirmPasswordErrr !==
                          ""
                      }
                      minLength={8}
                      maxLength={18}
                    />
                    <span
                      onClick={toggleConfirmPasswordVisibility}
                      style={{
                        display: "flex",
                        position: "absolute",
                        right: "60px",
                        marginTop: "-28px",
                        cursor: "pointer",
                      }}
                    >
                      {showConfirmPassword ? (
                        <SvgIcon icon={eyeIcon} />
                      ) : (
                        <SvgIcon icon={eyeSlashIcon} />
                      )}
                    </span>
                    <div>
                      {(!confirmPassword.trim() ||
                        profilePassword.trim() !== confirmPassword.trim()) && (
                        <Label
                          className="error-field"
                          style={{ fontSize: "medium" }}
                        >
                          {
                            registerRoomBookErrorFormData?.customerConfirmPasswordErrr
                          }
                        </Label>
                      )}
                    </div>
                  </div>
                </div>
                {/* Buttons */}
                <div
                  style={{
                    marginTop: "20px",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  {infoMember === undefined ? (
                    <Button
                      type="submit"
                      style={{
                        marginRight: "10px",
                        padding: "10px 20px",
                        border: "none",
                        backgroundColor: "#42B677",
                        color: "white",
                      }}
                      onClick={(e) => {
                        registerUserAPI(e);
                      }}
                    >
                      Save
                    </Button>
                  ) : (
                    <div></div>
                  )}
                  {infoMember !== undefined && !isEdit && (
                    <Button
                      type="submit"
                      style={{
                        marginRight: "10px",
                        padding: "10px 20px",
                        border: "none",
                        backgroundColor: "#42B677",
                        color: "white",
                      }}
                      disabled={isEdit}
                      onClick={(e) => updateRegisterUserAPI(e)}
                    >
                      Update
                    </Button>
                  )}
                  <Button
                    onClick={(e) => handleBack(e)}
                    style={{
                      backgroundColor: "orange",
                      color: "white",
                      padding: "10px 20px",
                      border: "none",
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {visible && (
            <Dialog
              title={"Message"}
              onClose={toggleDialog}
              style={{ alignItems: "center" }}
            >
              <Label
                style={{
                  height: "80px",
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {alertContent}
                <div
                  style={{
                    padding: "20px",
                  }}
                />
                <Button
                  className="alert-button"
                  type="button"
                  onClick={toggleDialog}
                >
                  Ok
                </Button>
              </Label>
            </Dialog>
          )}
        </div>
      )}
    </div>
  );
};
