import React, { useEffect, useState } from "react";
import { Grid, GridColumn, GridCellProps } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import {
  Input,
  InputPrefix,
  InputSuffix,
  RadioButton,
  RadioButtonChangeEvent,
  TextArea,
  TextBox,
} from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { process, State } from "@progress/kendo-data-query";
import "../../css/Common.scss";
import "../../css/Duration.scss";
import "../../css/Dialog.scss";
import "../../css/TaxMaster.scss";
import {
  eyeIcon,
  pencilIcon,
  plusIcon,
  trashIcon,
} from "@progress/kendo-svg-icons";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import axios from "axios";
import {
  commonBaseUrl,
  RightAlignedHeaderCell,
} from "../../../services/CommonUtil";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Error } from "@progress/kendo-react-labels";

const initialDataState: State = {
  take: 5,
  skip: 0,
  group: [],
};

export const TaxTemplate = () => {
  const baseURL = commonBaseUrl();
  const [originalData, setOriginalData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [dataState, setDataState] = useState<State>(initialDataState);
  const [active, setActive] = useState(1);
  const [taxName, setTaxName] = useState("");
  const [description, setTaxDescription] = useState("");
  const [taxPercentage, setTaxPercentage] = useState("");
  const [isActive, setTaxStatus] = useState("");
  const [errors, setErrors] = useState({
    taxName: "",
    taxDescription: "",
    taxPercentage: "",
    // taxStatus: "",
  });
  const [statusDialog, statusDialogSet] = useState(false);
  const [alert, alertSet] = useState("");
  const [selectedValue, setSelectedValue] = useState("1");
  const [dialogVisible, setDialogVisible] = useState(false);

  const handleChange = React.useCallback(
    (e: RadioButtonChangeEvent) => {
      setSelectedValue(e.value);
    },
    [setSelectedValue]
  );

  const validateForm = () => {
    const newErrors = {
      taxName: taxName ? "" : "Tax Name cannot be empty",
      taxDescription: description ? "" : "Tax Description cannot be empty",
      taxPercentage: taxPercentage ? "" : "Tax Percentage cannot be empty",
      taxStatus: isActive ? "" : "Status cannot be empty",
    };
    setErrors(newErrors);
    return !Object.values(newErrors).some((error) => error);
  };
  const handleCreate = async () => {
    if (!validateForm()) return;

    try {
      await axios.post(`${baseURL}odata/Taxes`, {
        taxName,
        description,
        percentage: parseFloat(taxPercentage),
        isActive: active === 1 ? true : false,
      });
      // Clear the form fields and errors after successful API call
      setTaxName("");
      setTaxDescription("");
      setTaxPercentage("");
      setTaxStatus("");
      setErrors({
        taxName: "",
        taxDescription: "",
        taxPercentage: "",
        // taxStatus: "",
      });
    } catch (error) {
      console.error("Failed to create tax:", error);
      // Handle error here if needed
    }
  };

  const handleClear = () => {
    setTaxName("");
    setTaxDescription("");
    setTaxPercentage("");
    setTaxStatus("");
    setErrors({
      taxName: "",
      taxDescription: "",
      taxPercentage: "",
      // taxStatus: "",
    });
  };

  const breadCrumbsData = [
    {
      id: "1",
      text: "Masters",
      route: "",
    },
    {
      id: "2",
      text: "Tax Template",
      route: "",
    },
  ];

  const [searchData, setSearchData] = useState([]);
  const navigate = useNavigate();

  // Pagination with settings
  const handlePageChange = (pageState: any) => {
    setDataState({ ...dataState, ...pageState });
  };

  const getPageableSettings = () => {
    const length = filteredData.length;
    if (length === 0) {
      return false;
    } else if (length <= 5) {
      return { pageSizes: false, buttonCount: 5 };
    } else if (length <= 10) {
      return { pageSizes: [5, 10], buttonCount: 5 };
    } else if (length <= 20) {
      return { pageSizes: [5, 10, 20], buttonCount: 5 };
    } else {
      return { pageSizes: [5, 10, 20, 50], buttonCount: 5 };
    }
  };

  const [editItems, setEditItem] = React.useState({});
  const [taxList, setTaxList] = useState<any>([]);

  // Edit, View & Delete column booking list
  // const MyEditCommandCell = (props: EditCommandCellProps) => (
  //   <EditCommandCell {...props} enterEdit={props.enterEdit} />
  // );

  // const EditCommandCell = (props: EditCommandCellProps) => {
  //   return (
  //     <td>
  //       <Button
  //         className="edit-colored"
  //         type="button"
  //         svgIcon={pencilIcon}
  //         onClick={() => props.enterEdit(props.dataItem)}
  //       ></Button>
  //     </td>
  //   );
  // };
  // Command Cells
  const MyEditCommandCell = (props: any) => {
    const { IsPredefined } = props.dataItem;

    return (
      <td>
        <Button
          className="edit-colored"
          type="button"
          svgIcon={pencilIcon}
          onClick={() => props.enterEdit(props.dataItem)}
          disabled={IsPredefined} // Disable when IsPredefined is true
        ></Button>
      </td>
    );
  };

  const MyDeleteCommandCell = (props: any) => {
    const { IsPredefined } = props.dataItem;

    return (
      <td>
        <Button
          className="delete-color"
          type="button"
          svgIcon={trashIcon}
          onClick={() => props.enterDelete(props.dataItem)}
          disabled={IsPredefined} // Disable when IsPredefined is true
        ></Button>
      </td>
    );
  };
  const handleUpdate = async (updatedTax: {
    id: number; // Assuming 'id' is required to identify the tax record
    taxName: string;
    description: string;
    percentage: number;
    isActive: boolean;
  }) => {
    try {
      // Replace 'id' with the specific record's ID
      const response = await axios.post(
        `${baseURL}odata/UpdateTaxes?key=${updatedTax.id}`,
        {
          taxName: updatedTax.taxName,
          description: updatedTax.description,
          percentage: updatedTax.percentage,
          isActive: updatedTax.isActive,
        }
      );

      console.log("Tax updated successfully:", response.data);
      // Handle success (e.g., refresh grid data or show a success message)
    } catch (error) {
      console.error("Failed to update tax:", error);
      // Handle error (e.g., show an error message)
    }
  };

  const enterEdit = (item: any) => {
    const updatedTax = {
      id: item.id, // Use the correct field for the tax ID
      taxName: item.Name,
      description: item.Description,
      percentage: item.Percentage,
      isActive: item.IsActive,
    };
    setDialogVisible(true);
    handleUpdate(updatedTax);
  };

  // View active booking
  const MyViewCommandCell = (props: ViewCommandCellProps) => (
    <ViewCommandCell
      {...props}
      checkTaxTemplateStatus={props.checkTaxTemplateStatus}
    />
  );

  const ViewCommandCell = (props: any) => {
    const { isPredefinedTax } = props.dataItem;
    return (
      <td style={{ paddingLeft: "0px" }}>
        <Button
          className="edit-colored"
          type="button"
          svgIcon={pencilIcon}
          onClick={() => props.checkTaxTemplateStatus(props.dataItem)}
          disabled={isPredefinedTax}
        ></Button>
      </td>
    );
  };

  const checkTaxTemplateStatus = async (data: any) => {
    console.log("The id was were -->", data.id);
    try {
      const response = await axios.post(
        `${baseURL}odata/DeactivateTaxTemplate?taxTemplateId=${data.id}`
      ); // Replace with your API endpoint
      const { code, type, message } = response.data;

      if (
        code === 200 &&
        type === "Success" &&
        message === "The tax template is not linked to space details."
      ) {
        // Call the enterView function
        enterView(data.id);
      } else if (
        code === 400 &&
        type === "Failure" &&
        message ===
          "Cannot deactivate the tax template as it is linked to space details."
      ) {
        alertSet(response.data.message);
        statusDialogSet(true);
      }
    } catch (error: any) {
      alertSet(error.response.data.message);
      statusDialogSet(true);
    }
  };

  const enterView = (item: any) => {
    console.log("The item was -->", item);
    for (let d = 0; d < filteredData.length; d++) {
      if (item === filteredData[d]["id"]) {
        navigate("/masters/tax/createtaxtemplate", {
          state: { infoId: filteredData[d]["id"] },
        });
        break;
      }
    }
  };

  const enterEdit0 = (item: any) => {
    for (let d = 0; d < originalData.length; d++) {
      if (item.Id === originalData[d]["Id"]) {
        getBookingData(originalData[d]["Id"], undefined);
      }
    }
  };

  const getBookingData = async (id: any, view: any) => {
    try {
      const response = await axios.get(`${baseURL}odata/Taxes`);
      setTaxList(response.data.value);
    } catch (err) {}
  };

  // const enterView = async (item: any) => {
  //   console.log(item.Id, "<<");

  //   try {
  //     const response = await axios.get(`${baseURL}odata/Taxes`);
  //     if (response.status === 200 || response.status === 201) {
  //       console.log(response.status, "<");
  //       const datas = response.data || [];
  //     }
  //   } catch (err) {}

  //   setEditItem(item);
  // };

  // const MyDeleteCommandCell = (props: DeleteCommandCellProps) => (
  //   <DeleteCommandCell {...props} enterDelete={props.enterDelete} />
  // );

  // const DeleteCommandCell = (props: DeleteCommandCellProps) => {
  //   return (
  //     <td>
  //       <Button
  //         className="delete-color"
  //         type="button"
  //         svgIcon={trashIcon}
  //       ></Button>
  //     </td>
  //   );
  // };

  const enterDelete = (item: any) => {
    console.log("Pressed the delete button");
    console.log(item.Id);
  };
  useEffect(() => {
    const getTaxesapi = async () => {
      try {
        const response = await axios.get(`${baseURL}odata/TaxTemplates`);
        const taxes = response.data || [];
        setOriginalData(taxes);
        setFilteredData(taxes); // Also initialize searchData with original data
      } catch (err) {
        console.error(err);
      }
    };
    getTaxesapi();
  }, []);

  const onFilterChange = (ev: any) => {
    const value = ev.target.value; // Update to use `target.value` from event
    setFilterValue(value);

    if (value === "") {
      setFilteredData(originalData); // Reset to original data when search is empty
    } else {
      const newData = originalData.filter((item: any) => {
        return (
          item.taxName?.toLowerCase().includes(value.toLowerCase()) ||
          item.description?.toLowerCase().includes(value.toLowerCase())
          // item.Percentage?.toLowerCase().includes(value.toLowerCase())
        );
      });
      setDataState(initialDataState);
      setFilteredData(newData);
    }
  };

  // Reset filter values
  const resetSearch = () => {
    setFilterValue("");

    setFilteredData(originalData); // Reset to original data
  };
  const SerialNumberCell = (props: any) => {
    const serialNumber = dataState.skip + props.dataIndex + 1;
    return <td>{serialNumber}</td>;
  };

  const formatDate = (dateString: any) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const CustomDateCell = (props: any) => {
    const { field, dataItem } = props;
    return <td>{formatDate(dataItem[field])}</td>;
  };

  const MultilineTextCell = (props: any) => {
    const { dataItem, field } = props;
    return (
      <td style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
        {dataItem[field]}
      </td>
    );
  };

  const handleNavigate = () => {
    navigate("/masters/tax/createtaxtemplate", {
      state: { infoId: undefined },
    });
  };

  return (
    <div className="overall-view ">
      <div className="space-burger">
        <Breadcrumbs breadCrumbData={breadCrumbsData} />
        <Button
          className="large-button-view"
          onClick={() => handleNavigate()}
          svgIcon={plusIcon}
        >
          Create Tax Template
        </Button>
      </div>
      <hr className="hr-border" />
      <div>
        <div className="filter-container"></div>
        <div className="common-margin view-margin">
          <div className="table-title-view">
            <div className="large-custom-input-container">
              <Label className="list-heading">Active Taxes List</Label>
              <TextBox
                className="textBoxcustom"
                placeholder="Search using Tax Name & Tax Description"
                name="roomcost"
                value={filterValue}
                onChange={onFilterChange}
                suffix={() => (
                  <>
                    <span className="input-icon">
                      {filterValue ? (
                        <Button icon="close" onClick={resetSearch} />
                      ) : (
                        <Button icon="search" />
                      )}
                    </span>
                  </>
                )}
              />
            </div>
          </div>
          <div>
            <Grid
              data={process(filteredData, dataState)}
              pageable={getPageableSettings()}
              skip={dataState.skip}
              take={dataState.take}
              total={filteredData.length}
              onPageChange={(e) => setDataState(e.page)}
              onDataStateChange={handlePageChange}
            >
              <GridColumn
                field="SNo"
                title="S.No"
                width={10}
                headerCell={RightAlignedHeaderCell}
                cell={(props) => (
                  <td
                    style={{
                      height: "75px",
                      textAlign: "right",
                      paddingRight: "40px",
                    }}
                  >
                    {props.dataIndex + 1}
                  </td>
                )}
              />
              <GridColumn
                field="taxName"
                title="Tax Name"
                filter="numeric"
                // width={200}
                cell={(props) => (
                  <td
                    style={{
                      // height: "75px",
                      // textAlign: "left",
                      whiteSpace: "pre-wrap",
                      wordWrap: "break-word",
                    }}
                  >
                    {props.dataItem.taxName}
                  </td>
                )}
              />
              <GridColumn
                field="description"
                title="Tax Description"
                filter="text"
                cell={MultilineTextCell}
              />
              <GridColumn
                field="percentage"
                title="Tax Percentage"
                className="customer-name-styles"
                headerCell={RightAlignedHeaderCell}
                cell={(props) => (
                  <td
                    style={{
                      height: "75px",
                      textAlign: "right",
                      paddingRight: "35px",
                    }}
                  >
                    {props.dataItem.percentage
                      ? `${props.dataItem.percentage}%`
                      : ""}
                  </td>
                )}
              />
              <GridColumn
                field="IsActive"
                title="Status"
                cell={(props) => (
                  <td>
                    {props.dataItem.isActive === true ? "Active" : "InActive"}
                  </td>
                )}
              />
              <GridColumn
                title="Action"
                className="Data"
                width={"240px"}
                cell={(props) => (
                  <td>
                    <Button
                      className="edit-color"
                      disabled={props.dataItem.isPredefinedTax}
                      onClick={() => {
                        checkTaxTemplateStatus(props.dataItem);
                        console.log("Selected Data", props.dataItem);
                      }}
                      svgIcon={pencilIcon}
                    />
                  </td>
                )}
              />
            </Grid>
          </div>
        </div>
      </div>
      {dialogVisible && (
        <Dialog
          height={"60%"}
          // onClose={closeDialog}
          className="dialog-containers"
        >
          <div
            className="dilog-content-size"
            style={{
              width: "350px",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              fontSize: "15px",
            }}
          >
            <div className="dialog-content">
              <h1>Create Tax</h1>
              <div style={{ width: "100%" }}>
                <div className="dialog-item">
                  <div>
                    Tax Name <span style={{ color: "red" }}>*</span>
                  </div>
                </div>
                <div className="dialog-item">
                  <Input
                    value={taxName}
                    onChange={(e) => {
                      setTaxName(e.value);
                      setErrors((prev) => ({ ...prev, taxName: "" })); // Clear validation on change
                    }}
                    placeholder="Enter Tax Name"
                    maxLength={25}
                  />
                  {errors.taxName && (
                    <p style={{ color: "red" }}>{errors.taxName}</p>
                  )}
                </div>
              </div>
              <div style={{ width: "100%" }}>
                <div className="dialog-item">
                  <div>
                    Tax Description <span style={{ color: "red" }}>*</span>
                  </div>
                </div>
                <div className="dialog-item">
                  <Input
                    value={description}
                    onChange={(e) => {
                      setTaxDescription(e.value);
                      setErrors((prev) => ({ ...prev, description: "" })); // Clear validation on change
                    }}
                    placeholder="Enter Tax Description"
                    maxLength={100}
                  />
                  {errors.taxDescription && (
                    <p style={{ color: "red" }}>{errors.taxDescription}</p>
                  )}
                </div>
              </div>
              <div style={{ width: "100%" }}>
                <div className="dialog-item">
                  <div>
                    Tax Percentage <span style={{ color: "red" }}>*</span>
                  </div>
                </div>
                <div className="dialog-item">
                  <Input
                    value={taxPercentage}
                    onChange={(e) => {
                      const value = e.value;
                      if (
                        value === "" ||
                        /^(\d{1,2}(\.\d{0,2})?|100)$/.test(value) // Validate format
                      ) {
                        setTaxPercentage(value);
                        setErrors((prev) => ({ ...prev, percentage: "" })); // Clear validation on change
                      }
                    }}
                    placeholder="Enter Percentage"
                    type="text"
                  />
                  {errors.taxPercentage && (
                    <p style={{ color: "red" }}>{errors.taxPercentage}</p>
                  )}
                </div>
              </div>
              <div style={{ width: "100%" }}>
                <div className="dialog-item">
                  <div>
                    Tax Status <span style={{ color: "red" }}>*</span>
                  </div>
                </div>
                <div className="dialog-item">
                  <RadioButton
                    name="status"
                    value={1}
                    checked={active === 1}
                    label="Active"
                    onChange={(e) => {
                      setTaxStatus(e.value);
                      setErrors((prev) => ({ ...prev, status: "" })); // Clear validation on change
                    }}
                  />
                  <RadioButton
                    name="status"
                    value={2}
                    checked={active === 2}
                    label="Inactive"
                    onChange={(e) => {
                      setTaxStatus(e.value);
                      setErrors((prev) => ({ ...prev, status: "" })); // Clear validation on change
                    }}
                  />
                </div>
                {/* {errors.status && <p style={{ color: "red" }}>{errors.status}</p>} */}
              </div>

              <div
                style={{
                  width: "100%",
                }}
              >
                <div
                  style={{
                    paddingTop: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className="content-button-padding">
                    <Button
                      // onClick={handleCreateOrUpdate}
                      disabled={!taxName || !taxPercentage}
                      style={{
                        // marginRight: "20px",
                        backgroundColor: "#42B677",
                        padding: "10px",
                        color: "#fff",
                        paddingRight: "20px",
                        paddingLeft: "20px",
                      }}
                    >
                      {/* <Button primary={true} onClick={handleView} onClickCapture={handleView}>  */}
                      Create
                    </Button>
                    <div style={{ padding: "10px" }} />
                  </div>

                  <div className="content-button-padding">
                    <Button
                      // onClick={closeDialog}
                      style={{
                        // marginRight: "20px",
                        backgroundColor: "#ED823A",
                        padding: "10px",
                        paddingRight: "20px",
                        paddingLeft: "20px",
                        color: "#fff",
                      }}
                    >
                      CANCEL
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      )}
      {statusDialog && (
        <Dialog>
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
            }}
          >
            <h3>{alert}</h3>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "30px",
              }}
            >
              <Button
                className="button-view"
                onClick={() => window.location.reload()}
              >
                Ok
              </Button>
            </div>
          </div>
        </Dialog>
      )}
    </div>
  );
};

interface ViewCommandCellProps extends GridCellProps {
  checkTaxTemplateStatus: (id: any) => void;
}
