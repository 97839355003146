import React from 'react';
import * as PropTypes from 'prop-types';
 
import {
    Scheduler as KendoScheduler,
    DayView,
    WeekView,
    WorkWeekView,
    MonthView,
} from '@progress/kendo-react-scheduler';
 
export const Scheduler = (props) => {
    const {
        data, modelFields, resources, onDataChange, ontap, handlechangeDate
    } = props;
 
    const defaultDate = new Date();
    // const getBookingData = async () => {
    //     console.log("wertyuisdfghjk")
    //     // try {
    //       const response = await axios.get(
    //         `${baseURL}odata/Bookings(6)`
    //       );
    //       console.log(response.data)
    //       navigate("/management/editbooking", {
    //         state: { infoId: response.data },
    //       });
    //     // } catch (err) {}
    //   };
    const handlechange = React.useCallback(
        (e) => {
            console.log(e.value, "<this Data");

        }, []
    )

    return (
        <KendoScheduler
            data={data}
            modelFields={modelFields}
            resources={resources}
            
            onDateChange={handlechangeDate}
            // timezone={'Etc/UTC'}
            defaultDate={defaultDate}
            form={ontap}

           

            defaultView={window.innerWidth < 768 ? "day" : "work-week"}
            onDataChange={onDataChange}
            // onDataChange={onDataChange}
            editable={{
                add: true,
                remove: false,
                drag: true,
                resize: true,
                edit: true
            }}
            // editable={true}
            // ontap = {ontap}
            // editable={{
            //     // add: true,
            //     // remove: true,
            //     // drag: true,
            //     // resize: true,
            //     edit: true
            // }}
        >
            {/* <DayView />
            <WorkWeekView numberOfDays={7} />
            <WeekView /> */}
            <MonthView />
        </KendoScheduler>
    );
};
 

 