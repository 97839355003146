import React, { useEffect, useState } from "react";
import { Card, CardHeader, CardBody } from "@progress/kendo-react-layout";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import {
  Chart,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesLabels,
} from "@progress/kendo-react-charts";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import "../cafeteria/overview.scss";
import {
  alignItemsBaselineAltIcon,
  listLatinBigIcon,
} from "@progress/kendo-svg-icons";
import { Button } from "@progress/kendo-react-buttons";
import { Label } from "@progress/kendo-react-labels";
import Breadcrumbs from "../components/Breadcrumbs";
import { useSchedulerActiveViewContext } from "@progress/kendo-react-scheduler";
import { commonBaseUrl, commonChartColor, RightAlignedHeaderCell } from "../../services/CommonUtil";
import axios from "axios";
import { today } from "@progress/kendo-react-dateinputs";
import { log } from "console";

const CafeteriaOverview = () => {
  const ordersData = [
    // { orderNo: '006', items: '2 Tea, 1 Water Bottle', customer: 'ABC Corps', status: 'Deleted' },
    // { orderNo: '005', items: '2 Tea, 1 Water Bottle', customer: 'ABC Corps', status: 'Pending' },
    // { orderNo: '004', items: '2 Tea, 1 Water Bottle', customer: 'Zee Zaw Co', status: 'In-Progress' },
    // { orderNo: '003', items: '2 Tea, 1 Water Bottle', customer: 'ABC Corps', status: 'Deleted' },
    {
      orderNo: "002",
      items: "2 Tea, 1 Water Bottle",
      customer: "ABC Corps",
      status: "Pending",
    },
    {
      orderNo: "001",
      items: "2 Tea, 1 Water Bottle",
      customer: "Zee Zaw Co",
      status: "In-Progress",
    },
  ];

  const deliveriesData = [
    {
      foodItems: "Meals",
      customerName: "ABC Corps",
      space: "Private Cabin",
      deliveryTime: "Today, 01:00 PM",
    },
    {
      foodItems: "Tea",
      customerName: "Zee Zaw Co",
      space: "Conference Room",
      deliveryTime: "Today, 04:00 PM",
    },
  ];

  const pieData = [
    { category: "Beverages", value: 75 },
    { category: "Meals", value: 25 },
  ];

  const pieMostPopularData = [
    { category: "Tea", value: 17 },
    { category: "Coffee", value: 17 },
    { category: "Meals", value: 17 },
    { category: "Water Bottle", value: 17 },
    { category: "Fruit Juice", value: 16 },
    { category: "Coke", value: 16 },
  ];
  const [pieMostData, pieMostDataSet] = useState([]);
  const breadCrumbsData = [
    {
      id: "1",
      text: "Cafeteria Management",
      route: "/cafeteria/overview",
    },
    {
      id: "2",
      text: "Overview",
      //   route: "",
    },
  ];
    // YYYY-MM-DD format conversion
    const formatDate = (date: any) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };
  
  const [count, countSet] = useState("");
  const [startDate, startDateSet] = useState();
  const [endDate, endDateSet] = useState();
  const baseURL = commonBaseUrl();
  const [newList, newListSet] = useState([]);
  const [upcomingList, upcomingListSet] = useState([]);
  const handleDateChange = (count: any) => {
    let start, end;
    if (count === "This Week") {
      var curr: Date = new Date(); // get current date
      var first: number = curr.getDate() - curr.getDay();
      first = first;
      var firstdayOb: Date = new Date(curr.setDate(first + 1));
      var firstday: string = firstdayOb.toUTCString();
      var firstdayTemp: Date = firstdayOb;
      var lastdayTemp: Date = new Date(
        firstdayTemp.setDate(firstdayTemp.getDate() + 6)
      );
      var lastday: string = lastdayTemp.toUTCString();
      console.log(firstdayOb.toUTCString());
      console.log(firstday);
      console.log(lastday);
      var startDate = new Date(firstday);
      start = `${startDate.getFullYear()}-${
        startDate.getMonth() + 1
      }-${startDate.getDate()}`;
      end = `${lastdayTemp.getFullYear()}-${
        lastdayTemp.getMonth() + 1
      }-${lastdayTemp.getDate()}`;
      console.log(count);
      console.log("Start ==>", start, "End ==>", end);
      // start = firstday;
      // end = lastday;
    } else if (count === "Today") {
      console.log(new Date().toUTCString());
      console.log(count);
      date = new Date();
      start = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
      // start = new Date().toUTCString();
      end = start;
    } else if (count === "This Month") {
      const currentDate = new Date();
      const startOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      const endOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      );
      start = formatDate(startOfMonth);
      end = formatDate(endOfMonth)

      var date = new Date(),
        y = date.getFullYear(),
        m = date.getMonth() + 1;
      var firstDay = new Date(y, m+1, 2);
      var lastDay = new Date(y, m + 2, 0);
      console.log(firstDay.toUTCString());
      console.log(lastDay.toUTCString());
      console.log(count);
      // start = `${y}-${m}-${firstDay.getDate() - 1}`;
      // end = `${y}-${m}-${lastDay.getDate()-1}`;
      // start = firstDay.toUTCString();
      // end = lastDay.toUTCString();
      console.log("Due day field", firstDay.getDate());
      console.log("Due month field", firstDay.getMonth() + 1);

    } else {
      let currentDate = new Date();
      let currentYear = currentDate.getFullYear();
      console.log(currentDate);
      console.log(currentYear);
      start = `${currentYear}-01-01`;
      end = `${currentYear}-12-31`;
      console.log("Due day field", currentDate.getDate());
      console.log("Due month field", currentDate.getMonth() + 1);
    }
    let info = { start: start, end: end };
    console.log(start, end);
    return info;
  };
  const [active, activeSet] = useState(0);
  const [newOrders, newOrdersSet] = useState(0);
  const [amount, amountSet] = useState(0);
  const GetUpcomingOrderApi = async (time: any) => {
    let date = time;
    let fromDate = date.start;
    let toDate = date.end;
    console.log("The updated time is ==>", time);
    console.log(
      `${baseURL}odata/GetUpComingFoodOrdersDetails?fromDate=${fromDate}&toDate=${toDate}`
    );
    try {
      const response = await axios.get(
        `${baseURL}odata/GetUpComingFoodOrdersDetails?fromDate=${fromDate}&toDate=${toDate}`
      );
      console.log("Fetched Data Upcoming", response.data);
      let list = response.data;
      for (let i = 0; i < list.length; i++) {
        list[i].sno = i + 1;
      }
      upcomingListSet(list);
    } catch (err: any) {
      console.log("error", err.response.data.detail);
    }
  };
  const GetNewOrderApi = async (time: any) => {
    let date = time;
    let fromDate = date.start;
    let toDate = date.end;
    console.log("The updated time is ==>", time);
    console.log(
      `${baseURL}odata/GetNewFoodOrdersDetails?fromDate=${fromDate}&toDate=${toDate}`
    );
    try {
      const response = await axios.get(
        `${baseURL}odata/GetNewFoodOrdersDetails?fromDate=${fromDate}&toDate=${toDate}`
      );
      console.log("Fetched Data New", response.data);
      let list = response.data;
      for (let i = 0; i < list.length; i++) {
        list[i].sno = i + 1;
      }
      newListSet(list);
      GetUpcomingOrderApi(date);
    } catch (err: any) {
      console.log("error", err.response.data.detail);
    }
  };
  const GetFoodOrderStatisticsApi = async (time: any) => {
    let date = handleDateChange(time);
    let fromDate = date.start;
    let toDate = date.end;
    GetCatDistribution(time);
    GetMostPopularApi(time);
    console.log("The updated time is ==>", time);
    // console.log("The updated time is ==>", fromDate , "==>",
    console.log(
      `${baseURL}odata/GetFoodOrderStatistics?fromDate=${fromDate}&toDate=${toDate}`
    );
    try {
      const response = await axios.get(
        `${baseURL}odata/GetFoodOrderStatistics?fromDate=${fromDate}&toDate=${toDate}`
      );
      console.log("Fetched Data", response.data);
      activeSet(response.data.totalActiveOrders);
      newOrdersSet(response.data.totalNewOrders);
      amountSet(response.data.totalAmount);
      GetNewOrderApi(date);
      // pieMostDataSet(response.data);
    } catch (err: any) {
      console.log("error", err.response.data.detail);
    }
  };

  const GetMostPopularApi = async (time: any) => {
    let date = handleDateChange(time);
    let fromDate = date.start;
    let toDate = date.end;
    console.log("The updated time is ==>", time);
    // console.log("The updated time is ==>", fromDate , "==>", toDate);
    console.log(
      `${baseURL}odata/GetMostPopularItemDashboard?fromDate=${fromDate}&toDate=${toDate}`
    );
    try {
      const response = await axios.get(
        `${baseURL}odata/GetMostPopularItemDashboard?fromDate=${fromDate}&toDate=${toDate}`
      );
      console.log("Fetched Data", response.data);
      pieMostDataSet(response.data);
    } catch (err: any) {
      console.log("error", err.response.data.detail);
    }
  };
  const [donutCatData, donutCatDataSet] = useState([]);
  const GetCatDistribution = async (time: any) => {
    let date = handleDateChange(time);
    let fromDate = date.start;
    let toDate = date.end;
    console.log("The updated time is ==>", time);
    // console.log("The updated time is ==>", fromDate , "==>", toDate);
    console.log(
      `${baseURL}odata/GetCategoryPercentage?fromDate=${fromDate}&toDate=${toDate}`
    );
    try {
      const response = await axios.get(
        `${baseURL}odata/GetCategoryPercentage?fromDate=${fromDate}&toDate=${toDate}`
      );
      console.log("Fetched Data from cat", response.data);
      donutCatDataSet(response.data);
    } catch (err: any) {
      console.log("error", err.response.data.detail);
    }
  };
  const CustomItemsOrderedCell = (props: any) => {
    const { itemsOrdered } = props.dataItem;

    // Concatenating the itemsOrdered into a single string
    const formattedItems = itemsOrdered
      .map((item: any) => `${item.quantity} * ${item.itemName}`)
      .join(", ");

    return <td>{formattedItems}</td>;
  };
  const [firstTime, firstTimeSet] = useState(true);
  let date = "Today";
  useEffect(() => {
    if (firstTime) {
      GetFoodOrderStatisticsApi(date);
      GetCatDistribution(date);
      GetMostPopularApi(date);
      firstTimeSet(false);
    }
  });
  const labelContent = (e: any) => `${e.dataItem.percentage} %`;

  return (
    <div className="dashboard-container">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Breadcrumbs breadCrumbData={breadCrumbsData} />
      </div>
      <div className="overview-section">
        <Card>
          <CardHeader>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <h4>Overview</h4>
              <div className="dropdown-section" style={{ marginRight: "10px" }}>
                <DropDownList
                  data={["Today", "This Week", "This Month", "This Year"]}
                  defaultValue={date}
                  onChange={(e) => GetFoodOrderStatisticsApi(e.value)}
                />
              </div>
            </div>{" "}
          </CardHeader>
          <CardBody>
            <div className="dashboard-grid">
              <div className="dashboard-item">
                <Card>
                  <CardBody>
                    <h3>Total Active Orders</h3>
                    <p>{active}</p>
                  </CardBody>
                </Card>
              </div>
              <div className="dashboard-item">
                <Card>
                  <CardBody>
                    <h3>New Orders</h3>
                    <p>{newOrders}</p>
                  </CardBody>
                </Card>
              </div>
              <div className="dashboard-item">
                <Card>
                  <CardBody>
                    <h3>Total Sales</h3>
                    <p>₹ {amount}</p>
                  </CardBody>
                </Card>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
      <div className="dashboard">
        <div className="grid-container">
          <div className="grid-section">
            <div className="header-row">
              <Label className="dashboard-header">New Orders</Label>
              {/* <Button fillMode="flat" style={{ color: "#42B677", }}>
                View All
              </Button> */}
            </div>
            <Grid data={newList}>
              <GridColumn field="sno" title="Order No"
               headerCell={RightAlignedHeaderCell}
                  cell={(props) => (
                    <td
                      style={{
                        height: "75px",
                        textAlign: "right",
                        paddingRight: "35px",
                      }}
                    >
                      {props.dataIndex + 1}
                    </td>
                  )} />
              <GridColumn
                field="itemsOrdered"
                title="Items Ordered"
                cell={CustomItemsOrderedCell}
              />
              <GridColumn
                field="userName"
                title="Customer Name"
                width="250px"
                cell={(props) => <td>{props.dataItem.userName}</td>}
              />
              <GridColumn field="orderStatus" title="Status" />
            </Grid>
          </div>
          <div className="grid-section">
            <div className="header-row">
              <Label className="dashboard-header">Upcoming Deliveries</Label>
            </div>
            <Grid data={upcomingList}>
              <GridColumn field="sno" title="Order No"
              headerCell={RightAlignedHeaderCell}
              cell={(props) => (
                <td
                  style={{
                    height: "75px",
                    textAlign: "right",
                    paddingRight: "35px",
                  }}
                >
                  {props.dataIndex + 1}
                </td>
              )} />
              <GridColumn
                field="itemsOrdered"
                title="Items Ordered"
                cell={CustomItemsOrderedCell}
              />
              <GridColumn
                field="userName"
                title="Customer Name"
                width="300px"
                cell={(props) => <td>{props.dataItem.userName}</td>}
              />
              <GridColumn field="orderStatus" title="Status" />
            </Grid>
          </div>
        </div>
      </div>
      <div className="dashboard-chart">
        <div className="chart-container">
          {/* Donut chart */}
          {/* <div className="chart-section">
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <Label className="dashboard-header">Category Distribution</Label>
            </div>
            <Card>
              {donutCatData.length !== 0 ?
                (<Chart>
                  <ChartSeries>
                    <ChartSeriesItem type="donut" data={donutCatData} field="percentage" categoryField="categoryName" labels={{visible:true}} >
                    <ChartSeriesLabels
                          color="#fff"
                          background="none"
                          content={labelContent}
                        />
                    </ChartSeriesItem>
                  </ChartSeries>
                </Chart>) :
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>No Data Found</div>
              }
            </Card>
          </div> */}
          {/* Pie chart */}
          <div className="cafeteria-pie-section ">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              {/* <h4>Overview</h4> */}
              <Label className="dashboard-header">Most Popular (%)</Label>
              {/* <div className="dropdown-section" style={{ marginRight: "10px", marginBottom: "10px" }}>
                <DropDownList data={['Today', 'This Week', 'This Month', 'This Year']} defaultValue={date}
                  onChange={(e) => GetMostPopularApi(e.value)}
                />
              </div> */}
            </div>
            <Card>
              {pieMostData.length !== 0 ? (
                <Chart>
                  <ChartSeries>
                    <ChartSeriesItem
                      type="pie"
                      color={commonChartColor}
                      data={pieMostData}
                      field="percentage"
                      categoryField="itemName"
                      labels={{ visible: true }}
                    >
                      <ChartSeriesLabels
                        color="#000"
                        background="none"
                        content={labelContent}
                      />
                    </ChartSeriesItem>
                  </ChartSeries>
                </Chart>
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  No Data Found
                </div>
              )}
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CafeteriaOverview;
