import React, { useEffect, useState } from "react";
import { Grid, GridColumn, GridCellProps } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import {
  Input,
  InputPrefix,
  InputSuffix,
  RadioButton,
  RadioButtonChangeEvent,
  TextArea,
  TextBox,
} from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { process, State } from "@progress/kendo-data-query";
import "../../css/Common.scss";
import "../../css/Duration.scss";
import "../../css/Dialog.scss";
import "../../css/TaxMaster.scss";
import {
  eyeIcon,
  pencilIcon,
  plusIcon,
  trashIcon,
} from "@progress/kendo-svg-icons";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumbs";
import axios from "axios";
import {
  commonBaseUrl,
  RightAlignedHeaderCell,
} from "../../../services/CommonUtil";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Error } from "@progress/kendo-react-labels";

const initialDataState: State = {
  take: 5,
  skip: 0,
  group: [],
};
interface Tax {
  id: number;
  name: string;
  description: string;
  percentage: number;
  isActive: boolean;
}
interface currentData {
  id: number;
  taxName: string;
  description: string;
  percentage: number;
  isActive: boolean;
}
export const TaxMaster = () => {
  const baseURL = commonBaseUrl();
  const [taxId, setTaxId] = useState(0);
  const [originalData, setOriginalData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filterValue, setFilterValue] = useState("");
  const [dataState, setDataState] = useState<State>(initialDataState);
  const [active, setActive] = useState(0);
  const [newTax, newTaxSet] = useState("");
  const [newDescription, newDescriptionSet] = useState("");
  const [newPercentage, newPercentageSet] = useState("");
  const [newActive, newActiveSet] = useState(0);
  const [taxName, setTaxName] = useState("");
  const [description, setTaxDescription] = useState("");
  const [taxPercentage, setTaxPercentage] = useState("");
  const [isActive, setTaxStatus] = useState("");
  const [errors, setErrors] = useState({
    newTax: "",
    newDescription: "",
    newPercentage: "",
    newActive: "",
  });
  const [updateErrors, setUpdateErrors] = useState({
    taxName: "",
    taxDescription: "",
    taxPercentage: "",
  });
  const [statusDialog, statusDialogSet] = useState(false);
  const [alert, alertSet] = useState("");
  const [selectedValue, setSelectedValue] = useState("1");
  const [dialogVisible, setDialogVisible] = useState(false);

  const handleChange = React.useCallback(
    (e: RadioButtonChangeEvent) => {
      setSelectedValue(e.value);
    },
    [setSelectedValue]
  );

  const validateForm = () => {
    console.log("Tax Name->", newTax);
    const Errors = {
      newTax: newTax ? "" : "Tax Name cannot be empty",
      newDescription: newDescription ? "" : "Tax Description cannot be empty",
      newPercentage: newPercentage ? "" : "Tax Percentage cannot be empty",
      newActive: newActive ? "" : "Status cannot be empty",
    };
    setErrors(Errors);
    return !Object.values(Errors).some((error) => error);
  };
  const validateUpdateForm = () => {
    const updateErrors = {
      taxName: taxName ? "" : "Tax Name cannot be empty",
      taxDescription: description ? "" : "Tax Description cannot be empty",
      taxPercentage: taxPercentage ? "" : "Tax Percentage cannot be empty",
    };
    setUpdateErrors(updateErrors);
    return !Object.values(updateErrors).some((error) => error);
  };
  const handleCreate = async () => {
    if (!validateForm()) return;
    console.log(
      "the variables where ",
      newTax + " " + newPercentage,
      newDescription,
      newPercentage,
      newActive
    );
    // let update = {
    //   Name: newTax,
    //   Description: newDescription,
    //   Percentage: parseFloat(newPercentage),
    //   newActive: newActive == 1 ? true : false,
    // };
    try {
      await axios.post(`${baseURL}odata/Taxes`, {
        taxName: newTax + " " + newPercentage,
        description: newDescription,
        percentage: parseFloat(newPercentage),
        isActive: newActive == 1 ? true : false,
      });
      // Clear the form fields and errors after successful API call
      newTaxSet("");
      newDescriptionSet("");
      newPercentageSet("");
      setTaxStatus("");
      setErrors({
        newTax: "",
        newDescription: "",
        newPercentage: "",
        newActive: "",
      });
      alertSet("Tax created successfully");
      statusDialogSet(true);
    } catch (error: any) {
      console.error("Failed to create tax:", error);
      alertSet(error.response.data.detail);
      statusDialogSet(true);
      // Handle error here if needed
    }
  };

  const handleClear = () => {
    newTaxSet("");
    newDescriptionSet("");
    newPercentageSet("");
    setTaxStatus("");
    newActiveSet(0);
    setErrors({
      newTax: "",
      newDescription: "",
      newPercentage: "",
      newActive: "",
    });
  };
  const closeDialog = () => {
    updatehandleClear();
    handleClear();
    setDialogVisible(false);
  };

  const breadCrumbsData = [
    {
      id: "1",
      text: "Masters",
      route: "",
    },
    {
      id: "2",
      text: "Tax Master",
      route: "",
    },
  ];

  const [searchData, setSearchData] = useState([]);
  const navigate = useNavigate();

  // Pagination with settings
  const handlePageChange = (pageState: any) => {
    setDataState({ ...dataState, ...pageState });
  };

  const getPageableSettings = () => {
    const length = filteredData.length;
    if (length === 0) {
      return false;
    } else if (length <= 5) {
      return { pageSizes: false, buttonCount: 5 };
    } else if (length <= 10) {
      return { pageSizes: [5, 10], buttonCount: 5 };
    } else if (length <= 20) {
      return { pageSizes: [5, 10, 20], buttonCount: 5 };
    } else {
      return { pageSizes: [5, 10, 20, 50], buttonCount: 5 };
    }
  };

  const [editItems, setEditItem] = React.useState({});
  const [taxList, setTaxList] = useState<any>([]);

  // Edit, View & Delete column booking list
  // const MyEditCommandCell = (props: EditCommandCellProps) => (
  //   <EditCommandCell {...props} enterEdit={props.enterEdit} />
  // );

  // const EditCommandCell = (props: EditCommandCellProps) => {
  //   return (
  //     <td>
  //       <Button
  //         className="edit-colored"
  //         type="button"
  //         svgIcon={pencilIcon}
  //         onClick={() => props.enterEdit(props.dataItem)}
  //       ></Button>
  //     </td>
  //   );
  // };
  // Command Cells
  const MyEditCommandCell = (props: any) => {
    const { IsPredefined } = props.dataItem;

    return (
      <td>
        <Button
          className="edit-colored"
          type="button"
          svgIcon={pencilIcon}
          onClick={() => props.enterEdit(props.dataItem)}
          disabled={IsPredefined} // Disable when IsPredefined is true
        ></Button>
      </td>
    );
  };

  const MyDeleteCommandCell = (props: any) => {
    const { IsPredefined } = props.dataItem;

    return (
      <td>
        <Button
          className="delete-color"
          type="button"
          svgIcon={trashIcon}
          onClick={() => props.enterDelete(props.dataItem)}
          disabled={IsPredefined} // Disable when IsPredefined is true
        ></Button>
      </td>
    );
  };
  const updateTax = async () => {
    if (!validateUpdateForm()) return;

    let update = {
      Id: taxId,
      taxName: taxName + " " + taxPercentage,
      description: description,
      percentage: parseFloat(taxPercentage),
      isActive: active == 1 ? true : false,
    };
    console.log("the ", update);
    try {
      const response = await axios.post(
        `${baseURL}odata/UpdateTaxes?key=${taxId}`,
        update
      );
      alertSet("Tax updated successfully");
      statusDialogSet(true);
      setUpdateErrors({
        taxName: "",
        taxDescription: "",
        taxPercentage: "",
      });
    } catch (error: any) {
      console.log("error", error.response.data.detail);
      alertSet(error.response.data.detail);
      statusDialogSet(true);
    }
  };
  const updatehandleClear = () => {
    setTaxName("");
    setTaxDescription("");
    setTaxPercentage("");
    setTaxStatus("");
    setUpdateErrors({
      taxName: "",
      taxDescription: "",
      taxPercentage: "",
    });
  };
  const enableField = (id: any) => {
    // setEdit(true);
    // setEditID(id);
    console.log("data", id);
    var active = id.IsActive === true ? 1 : 2;
    setTaxId(id.Id);
    const name = id.Name;
    const names = name.split(" ");
    setTaxName(names[0]);
    setTaxDescription(id.Description);
    setTaxPercentage(id.Percentage + "%");
    setActive(active);
    setDialogVisible(true);
  };

  const enterEdit = (item: any) => {
    const updatedTax = {
      id: item.id, // Use the correct field for the tax ID
      taxName: item.Name,
      description: item.Description,
      percentage: item.Percentage,
      isActive: item.IsActive,
    };
    setDialogVisible(true);
    // handleUpdate(updatedTax);
  };
  const enterEdit0 = (item: any) => {
    for (let d = 0; d < originalData.length; d++) {
      if (item.Id === originalData[d]["Id"]) {
        getBookingData(originalData[d]["Id"], undefined);
      }
    }
  };

  const getBookingData = async (id: any, view: any) => {
    try {
      const response = await axios.get(`${baseURL}odata/Taxes`);
      setTaxList(response.data.value);
    } catch (err) {}
  };

  const enterView = async (item: any) => {
    console.log(item.Id, "<<");

    try {
      const response = await axios.get(`${baseURL}odata/Taxes`);
      if (response.status === 200 || response.status === 201) {
        console.log(response.status, "<");
        const datas = response.data || [];
      }
    } catch (err) {}

    setEditItem(item);
  };

  // const MyDeleteCommandCell = (props: DeleteCommandCellProps) => (
  //   <DeleteCommandCell {...props} enterDelete={props.enterDelete} />
  // );

  // const DeleteCommandCell = (props: DeleteCommandCellProps) => {
  //   return (
  //     <td>
  //       <Button
  //         className="delete-color"
  //         type="button"
  //         svgIcon={trashIcon}
  //       ></Button>
  //     </td>
  //   );
  // };

  const enterDelete = (item: any) => {
    console.log("Pressed the delete button");
    console.log(item.Id);
  };
  useEffect(() => {
    const getTaxesapi = async () => {
      try {
        const response = await axios.get(`${baseURL}odata/Taxes`);
        const taxes = response.data.value;
        // for (let i = 0; i < taxes.length; i++) {
        //   taxes[i]["IsActive"] =
        //     taxes[i]["IsActive"] === true ? "Active" : "InActive";
        // }
        setOriginalData(taxes);
        setFilteredData(taxes); // Also initialize searchData with original data
      } catch (err) {
        console.error(err);
      }
    };
    getTaxesapi();
  }, []);

  const onFilterChange = (ev: any) => {
    const value = ev.target.value; // Update to use `target.value` from event
    setFilterValue(value);

    if (value === "") {
      setFilteredData(originalData); // Reset to original data when search is empty
    } else {
      console.log("originalData", originalData);
      const newData = originalData.filter((item: any) => {
        return (
          item.Name?.toLowerCase().includes(value.toLowerCase()) ||
          item.Description?.toLowerCase().includes(value.toLowerCase()) ||
          item.Percentage?.toString().includes(value.toLowerCase())
          // item.IsActive?.toLowerCase().includes(value.toLowerCase())
        );
      });
      setDataState(initialDataState);
      setFilteredData(newData);
    }
  };

  // Reset filter values
  const resetSearch = () => {
    setFilterValue("");

    setFilteredData(originalData); // Reset to original data
  };
  const SerialNumberCell = (props: any) => {
    const serialNumber = dataState.skip + props.dataIndex + 1;
    return <td>{serialNumber}</td>;
  };

  const formatDate = (dateString: any) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const CustomDateCell = (props: any) => {
    const { field, dataItem } = props;
    return <td>{formatDate(dataItem[field])}</td>;
  };

  const MultilineTextCell = (props: any) => {
    const { dataItem, field } = props;
    return (
      <td style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
        {dataItem[field]}
      </td>
    );
  };

  // const handleAddTaxClick = () => {
  //   setDialogVisible(true);
  //   setIsEditing(false);
  //   clearFields();
  // };

  // const handlePercentageChange = (value: number | null) => {
  //   setTaxPercentage(value);

  //   if (value === null || isNaN(value)) {
  //     // Reset CGST, SGST, and IGST to 0 if input is cleared
  //     setCgst(0.0);
  //     setSgst(0.0);
  //     setIgst(0.0);
  //   } else {
  //     // Split the tax percentage into CGST and SGST with 2 decimal places
  //     const half = parseFloat((value / 2).toFixed(1));
  //     setCgst(half);
  //     setSgst(half);
  //     setIgst(value);
  //   }
  // };

  // const handleCreateOrUpdate = async () => {
  //   const taxData = {
  //     name: taxName,
  //     percentage: taxPercentage,
  //     cgst,
  //     sgst,
  //     igst,
  //   };

  //   if (isEditing && editingTaxId !== null) {
  //     await axios.post(`/api/taxes/${editingTaxId}`, taxData);
  //   } else {
  //     await axios.post("/api/taxes", taxData);
  //   }

  //   fetchTaxes();
  //   closeDialog();
  // };

  // const fetchTaxes = async () => {
  //   const response = await axios.get<Tax[]>("/api/taxes");
  //   setTaxes(response.data);
  // };

  // const handleUpdateClick = (tax: Tax) => {
  //   setDialogVisible(true);
  //   setIsEditing(true);
  //   setEditingTaxId(tax.id);
  //   setTaxName(tax.name);
  //   setTaxPercentage(tax.percentage);
  //   setCgst(tax.cgst);
  //   setSgst(tax.sgst);
  //   setIgst(tax.igst);
  // };
  // const handleDeleteClick = async (taxId: number) => {
  //   await axios.post(`/api/taxes/${taxId}/delete`);
  //   fetchTaxes();
  // };

  // const clearFields = () => {
  //   setTaxName("");
  //   setTaxPercentage(null);
  //   setCgst(0.0);
  //   setSgst(0.0);
  //   setIgst(0.0);
  // };

  // const closeDialog = () => {
  //   setDialogVisible(false);
  //   clearFields();
  // };
  return (
    <div className="overall-view ">
      <div className="space-burger">
        <Breadcrumbs breadCrumbData={breadCrumbsData} />
        {/* <Button
          className="large-button-view"
          onClick={handleAddTaxClick}
          svgIcon={plusIcon}
        >
          Create a new Tax
        </Button> */}
      </div>
      <hr className="hr-border" />
      <div>
        <div className="custom-inputs-container">
          <div className="custom-inputs-view">
            <Label className="common-label-size">
              Tax Name <span style={{ color: "red" }}>*</span>
            </Label>

            <select
              className="book-dropdown-new"
              name="taxname"
              value={newTax}
              onChange={(e) => {
                const value = e.target.value;
                console.log("Value->", value);
                newTaxSet(value); // Set selected value
                if (value === "Select Name") {
                  newTaxSet("");
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    newTax: "Tax Name is required",
                  }));
                } else {
                  setErrors((prevErrors) => ({ ...prevErrors, newTax: "" }));
                }
              }}
              onBlur={() => {
                if (!newTax || newTax === "Select Name") {
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    newTax: "Tax Name is required",
                  }));
                }
              }}
              style={{ fontSize: "15px" }}
            >
              <option value="Select Name">Select Name</option>
              <option value="CGST">CGST</option>
              <option value="SGST">SGST</option>
              <option value="IGST">IGST</option>
            </select>
            <br />

            {errors.newTax && <p style={{ color: "red" }}>{errors.newTax}</p>}
          </div>

          <div style={{ width: "40px" }}> </div>
          <div className="custom-inputs-view">
            <Label className="common-label-size">
              Tax Description <span style={{ color: "red" }}>*</span>
            </Label>
            <TextArea
              className="book-input-view"
              name="description"
              rows={3}
              maxLength={100}
              placeholder="Enter description"
              value={newDescription}
              onChange={(e) => {
                const value = e.value.trimStart(); // Prevent leading spaces
                if (/^[a-zA-Z0-9.%\s]*$/.test(value)) {
                  // Allow alphanumeric, spaces, and periods
                  newDescriptionSet(value); // Only set value if valid
                }
              }}
              //   const value = e.value.trimStart(); // Prevent leading spaces
              //   if (/^[a-zA-Z0-9\s]*$/.test(value)) {
              //     newDescriptionSet(value); // Only set value if it contains valid characters
              //   }
              // }}
              onBlur={() => {
                if (!newDescription.trim()) {
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    newDescription: "Description is required",
                  }));
                } else {
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    newDescription: "",
                  }));
                }
              }}
            />
            {errors.newDescription && (
              <p style={{ color: "red" }}>{errors.newDescription}</p>
            )}
          </div>

          <div style={{ width: "40px" }}> </div>
          <div className="custom-inputs-view">
            <Label className="common-label-size">
              Tax Percentage (%) <span style={{ color: "red" }}>*</span>
            </Label>

            <Input
              className="custom-dropdowns"
              type="text"
              value={newPercentage}
              // onChange={(e) => {
              //   const inputValue = e.value;

              //   // Allow valid percentages (1% to 100% with up to two decimal places)
              //   if (
              //     inputValue === "" || // Allow empty for clearing/backspacing
              //     /^(?!0)((\d{1,2}(\.\d{0,2})?)|100)(%?)$/.test(inputValue) // Ensure no leading zero or zero percentage
              //   ) {
              //     newPercentageSet(inputValue);
              //   }
              // }}
              // onBlur={() => {
              //   // Validation: Ensure value ends with '%' and is within range
              //   if (newPercentage && !newPercentage.trim().endsWith("%")) {
              //     setErrors((prevErrors) => ({
              //       ...prevErrors,
              //       newPercentage:
              //         "Please provide the percentage (%) symbol at the end.",
              //     }));
              //   } else if (
              //     newPercentage &&
              //     (parseFloat(newPercentage) <= 0 || !newPercentage.trim())
              //   ) {
              //     setErrors((prevErrors) => ({
              //       ...prevErrors,
              //       newPercentage:
              //         "Percentage must be greater than 0 and include the (%) symbol.",
              //     }));
              //   } else {
              //     setErrors((prevErrors) => ({
              //       ...prevErrors,
              //       newPercentage: "",
              //     }));
              //   }
              // }}
              // onWheelCapture={(e) => e.preventDefault()}
              // onWheel={(e) => e.currentTarget.blur()} // Prevent scroll changes
              // onKeyDown={(e) => {
              //   if (
              //     e.key === "ArrowUp" ||
              //     e.key === "ArrowDown" ||
              //     (newPercentage === "100%" && e.key === ".")
              //   ) {
              //     e.preventDefault(); // Prevent arrow key changes
              //   }
              // }}
              onChange={(e) => {
                const inputValue = e.value || ""; // Ensure value is always a string

                // Allow valid percentages (1% to 100% with up to two decimal places) or clearing
                if (
                  inputValue === "" || // Allow clearing the input
                  /^(?!0)((\d{1,2}(\.\d{0,2})?)|100)%?$/.test(inputValue) // Validate format
                ) {
                  newPercentageSet(inputValue); // Update state with the raw value
                }
              }}
              onBlur={() => {
                let trimmedValue = (newPercentage || "").trim(); // Ensure value is a string

                if (trimmedValue === "") {
                  // Handle empty input
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    newPercentage: "Percentage cannot be empty.",
                  }));
                } else if (!trimmedValue.endsWith("%")) {
                  // Append '%' if missing
                  if (/^(?!0)((\d{1,2}(\.\d{0,2})?)|100)$/.test(trimmedValue)) {
                    trimmedValue += "%";
                    newPercentageSet(trimmedValue); // Update state with appended '%'
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      newPercentage: "",
                    }));
                  } else {
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      newPercentage:
                        "Please provide a valid percentage between 1% and 100%.",
                    }));
                  }
                } else if (parseFloat(trimmedValue) <= 0) {
                  // Ensure value is greater than 0
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    newPercentage:
                      "Percentage must be greater than 0 and include the (%) symbol.",
                  }));
                } else {
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    taxPercentage: "",
                  })); // Clear errors if valid
                }
              }}
              onWheelCapture={(e) => e.preventDefault()} // Prevent scroll changes
              onKeyDown={(e) => {
                if (
                  e.key === "ArrowUp" ||
                  e.key === "ArrowDown" ||
                  (newPercentage === "100%" && e.key === ".")
                ) {
                  e.preventDefault(); // Prevent invalid key actions
                }
              }}
              placeholder="Enter percentage"
            />
            {errors.newPercentage && (
              <p style={{ color: "red" }}>{errors.newPercentage}</p>
            )}
          </div>

          <div style={{ width: "40px" }}> </div>
          <div className="custom-inputs-view">
            <Label className="common-label-size">
              Tax Status <span style={{ color: "red" }}>*</span>
            </Label>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                height: "20px",
              }}
            >
              {/* <div style={{ height: "40px" }}> </div> */}
              <RadioButton
                name="group2"
                value={1}
                checked={newActive === 1}
                label="Active"
                onChange={(e) => {
                  newActiveSet(e.value);
                }}
              />
              <div style={{ width: "30px" }}></div>
              <RadioButton
                name="group2"
                value={2}
                checked={newActive === 2}
                label="InActive"
                onChange={(e) => {
                  newActiveSet(e.value);
                }}
              />
            </div>

            {errors.newActive && (
              <p style={{ color: "red" }}>{errors.newActive}</p>
            )}
            {/* <Label>package applicable</Label>
                      <DropDownList
                    svgIcon={chevronDownIcon}
                    className="book-dropdown-new "
                    data={packages}
                    // name="package"
                    // value={""}
                    // required={check}
                    dataItemKey="Id"
                    textField="PackageName"
                    defaultItem={{ Id: null, PackageName: "Select Package Type" }}
                    // validationMessage={packageValidationMessage}
                    // onChange={handleChangedropdownpackgae}
                  /> */}
          </div>
          {/* <div
            className="custom-inputs-view common-label-padding"
            style={{ marginTop: "2%" }}
          >
            <Button className="button-view " onClick={handleCreate}>
              Create
            </Button>
            <Button className="common-button-view" onClick={handleClear}>
              Clear
            </Button>
          </div> */}
        </div>

        <div className="custom-inputs-container">
          <div className="custom-inputs-view"></div>
          <div style={{ width: "40px" }}> </div>
          <div className="custom-inputs-view"></div>
          <div style={{ width: "40px" }}> </div>
          <div className="custom-inputs-view"></div>
          <div
            className="custom-inputs-view common-label-padding"
            style={{ marginTop: "2%" }}
          >
            <Button className="button-view " onClick={handleCreate}>
              Create
            </Button>
            <Button className="common-button-view" onClick={handleClear}>
              Clear
            </Button>
          </div>
        </div>

        <div className="filter-container"></div>
        <div className="common-margin view-margin">
          <div className="table-title-view">
            <div className="large-custom-input-container">
              <Label className="list-heading">List of Taxes</Label>
              <TextBox
                className="textBoxcustom"
                placeholder="Search using Tax Name, Tax Description & Tax Percentage"
                name="roomcost"
                value={filterValue}
                onChange={onFilterChange}
                suffix={() => (
                  <>
                    <span className="input-icon">
                      {filterValue ? (
                        <Button icon="close" onClick={resetSearch} />
                      ) : (
                        <Button icon="search" />
                      )}
                    </span>
                  </>
                )}
              />
            </div>
          </div>
          <div>
            <Grid
              data={process(filteredData, dataState)}
              pageable={getPageableSettings()}
              skip={dataState.skip}
              take={dataState.take}
              total={filteredData.length}
              onPageChange={(e) => setDataState(e.page)}
              onDataStateChange={handlePageChange}
            >
              <GridColumn
                field="SNo"
                title="S.No"
                width={10}
                headerCell={RightAlignedHeaderCell}
                cell={(props) => (
                  <td
                    style={{
                      height: "75px",
                      textAlign: "right",
                      paddingRight: "40px",
                    }}
                  >
                    {props.dataIndex + 1}
                  </td>
                )}
              />
              <GridColumn
                field="Name"
                title="Tax Name"
                cell={(props) => (
                  <td
                    style={{
                      whiteSpace: "pre-wrap",
                      wordWrap: "break-word",
                    }}
                  >
                    {props.dataItem.Name}
                  </td>
                )}
              />
              <GridColumn
                field="Description"
                title="Tax Description"
                filter="text"
                cell={MultilineTextCell}
              />
              <GridColumn
                field="Percentage"
                title="Tax Percentage"
                className="customer-name-styles"
                headerCell={RightAlignedHeaderCell}
                cell={(props) => (
                  <td
                    style={{
                      height: "75px",
                      textAlign: "right",
                      paddingRight: "35px",
                    }}
                  >
                    {props.dataItem.Percentage
                      ? `${props.dataItem.Percentage}%`
                      : ""}
                  </td>
                )}
              />

              <GridColumn
                field="IsActive"
                title="Status"
                cell={(props) => (
                  <td>
                    {props.dataItem.IsActive === true ? "Active" : "InActive"}
                  </td>
                )}
              />
              {/* <GridColumn
                field="sno"
                title="Action"
                className="Data"
                width={10}
                cell={(props) => (
                  <div>
                    <MyEditCommandCell {...props} enterEdit={enableField} />
                  </div>
                )}
              /> */}
            </Grid>
          </div>
        </div>
      </div>
      {dialogVisible && (
        <Dialog
          height={"90%"}
          onClose={closeDialog}
          className="dialog-containers"
        >
          <div
            className="dilog-content-size"
            style={{
              width: "350px",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              fontSize: "15px",
            }}
          >
            <div className="dialog-content">
              <h1>Update Tax</h1>
              <div style={{ width: "100%" }}>
                <div className="dialog-item">
                  <div>
                    Tax Name <span style={{ color: "red" }}>*</span>
                  </div>
                </div>
                <div className="dialog-item">
                  <select
                    className="custom-dropdowns"
                    name="taxname"
                    value={taxName}
                    disabled={true}
                    onChange={(e) => {
                      const value = e.target.value;
                      setTaxName(value); // Set selected value
                      if (value === "Select Name") {
                        setTaxName("");
                        setUpdateErrors((prevErrors) => ({
                          ...prevErrors,
                          taxName: "Tax Name is required",
                        }));
                      } else {
                        setUpdateErrors((prevErrors) => ({
                          ...prevErrors,
                          taxName: "",
                        }));
                      }
                    }}
                    onBlur={() => {
                      if (!taxName || taxName === "Select Name") {
                        setUpdateErrors((prevErrors) => ({
                          ...prevErrors,
                          taxName: "Tax Name is required",
                        }));
                      }
                    }}
                    style={{ fontSize: "14px" }}
                  >
                    <option value="Select Name">Select Name</option>
                    <option value="CGST">CGST</option>
                    <option value="SGST">SGST</option>
                    <option value="IGST">IGST</option>
                  </select>
                  <br />
                  {updateErrors.taxName && (
                    <p style={{ color: "red" }}>{updateErrors.taxName}</p>
                  )}
                </div>
              </div>

              <div style={{ width: "100%" }}>
                <div className="dialog-item">
                  <div>
                    Tax Description <span style={{ color: "red" }}>*</span>
                  </div>
                </div>
                <div className="dialog-item">
                  <Input
                    value={description}
                    disabled={true}
                    onChange={(e) => {
                      const value = e.value.trimStart(); // Prevent leading spaces
                      if (/^[a-zA-Z0-9.%\s]*$/.test(value)) {
                        setTaxDescription(value);
                        setUpdateErrors((prev) => ({
                          ...prev,
                          description: "",
                        }));
                      } // Clear validation on change
                    }}
                    placeholder="Enter Tax Description"
                    maxLength={100}
                  />
                  {updateErrors.taxDescription && (
                    <p style={{ color: "red" }}>
                      {updateErrors.taxDescription}
                    </p>
                  )}
                </div>
              </div>
              <div style={{ width: "100%" }}>
                <div className="dialog-item">
                  <div>
                    Tax Percentage <span style={{ color: "red" }}>*</span>
                  </div>
                </div>
                <div className="dialog-item">
                  <Input
                    className="custom-dropdowns"
                    type="text"
                    value={taxPercentage}
                    disabled={true}
                    onChange={(e) => {
                      const inputValue = e.value || ""; // Ensure value is always a string

                      // Allow valid percentages (1% to 100% with up to two decimal places) or clearing
                      if (
                        inputValue === "" || // Allow clearing the input
                        /^(?!0)((\d{1,2}(\.\d{0,2})?)|100)%?$/.test(inputValue) // Validate format
                      ) {
                        setTaxPercentage(inputValue); // Update state with the raw value
                      }
                    }}
                    onBlur={() => {
                      let trimmedValue = (taxPercentage || "").trim(); // Ensure value is a string

                      if (trimmedValue === "") {
                        // Handle empty input
                        setUpdateErrors((prevErrors) => ({
                          ...prevErrors,
                          taxPercentage: "Percentage cannot be empty.",
                        }));
                      } else if (!trimmedValue.endsWith("%")) {
                        // Append '%' if missing
                        if (
                          /^(?!0)((\d{1,2}(\.\d{0,2})?)|100)$/.test(
                            trimmedValue
                          )
                        ) {
                          trimmedValue += "%";
                          setTaxPercentage(trimmedValue); // Update state with appended '%'
                          setUpdateErrors((prevErrors) => ({
                            ...prevErrors,
                            taxPercentage: "",
                          }));
                        } else {
                          setUpdateErrors((prevErrors) => ({
                            ...prevErrors,
                            taxPercentage:
                              "Please provide a valid percentage between 1% and 100%.",
                          }));
                        }
                      } else if (parseFloat(trimmedValue) <= 0) {
                        // Ensure value is greater than 0
                        setUpdateErrors((prevErrors) => ({
                          ...prevErrors,
                          taxPercentage:
                            "Percentage must be greater than 0 and include the (%) symbol.",
                        }));
                      } else {
                        setUpdateErrors((prevErrors) => ({
                          ...prevErrors,
                          taxPercentage: "",
                        })); // Clear errors if valid
                      }
                    }}
                    onWheelCapture={(e) => e.preventDefault()} // Prevent scroll changes
                    onKeyDown={(e) => {
                      if (
                        e.key === "ArrowUp" ||
                        e.key === "ArrowDown" ||
                        (taxPercentage === "100%" && e.key === ".")
                      ) {
                        e.preventDefault(); // Prevent invalid key actions
                      }
                    }}
                    placeholder="Enter percentage"
                  />
                </div>
                <div>
                  {updateErrors.taxPercentage && (
                    <p style={{ color: "red" }}>{updateErrors.taxPercentage}</p>
                  )}
                </div>
              </div>
              <div style={{ width: "100%" }}>
                <div className="dialog-item">
                  <div>
                    Tax Status <span style={{ color: "red" }}>*</span>
                  </div>
                </div>
                <div className="dialog-item" style={{ gap: "5px" }}>
                  <RadioButton
                    name="group2"
                    value={1}
                    disabled={true}
                    checked={active === 1}
                    label="Active"
                    onChange={(e) => {
                      console.log(e.value, "value");
                      setActive(e.value);
                    }}
                  />
                  <RadioButton
                    name="group2"
                    value={2}
                    disabled={true}
                    checked={active === 2}
                    label="Inactive"
                    onChange={(e) => {
                      setActive(e.value);
                    }}
                  />
                </div>
              </div>
              {/* {updateErrors.taxStatus && (
                <p style={{ color: "red" }}>{updateErrors.taxStatus}</p>
              )} */}
              <div
                style={{
                  width: "100%",
                }}
              >
                <div
                  style={{
                    paddingTop: "30px",
                    display: "flex",
                    justifyContent: "center",
                    // alignItems: "center",
                  }}
                >
                  <div className="content-button-padding">
                    <Button
                      onClick={updateTax}
                      disabled={!taxName || !description || !active}
                      style={{
                        // marginRight: "20px",
                        backgroundColor: "#42B677",
                        padding: "10px",
                        color: "#fff",
                        paddingRight: "20px",
                        paddingLeft: "20px",
                      }}
                    >
                      UPDATE
                    </Button>
                    <div style={{ padding: "10px" }} />
                  </div>

                  <div className="content-button-padding">
                    <Button
                      onClick={closeDialog}
                      style={{
                        // marginRight: "20px",
                        backgroundColor: "#ED823A",
                        padding: "10px",
                        paddingRight: "20px",
                        paddingLeft: "20px",
                        color: "#fff",
                      }}
                    >
                      CANCEL
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      )}
      {statusDialog && (
        <Dialog>
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
            }}
          >
            <h3>{alert}</h3>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "30px",
              }}
            >
              <Button
                className="button-view"
                onClick={() => window.location.reload()}
              >
                Ok
              </Button>
            </div>
          </div>
        </Dialog>
      )}
    </div>
  );
};

interface EditCommandCellProps extends GridCellProps {
  enterEdit: (item: any) => void;
}
